import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Link,
} from "@material-ui/core";
import ThemeButton from "../../Component/common/button";
import Ask from "../../assets/Contact/Ask_Cogito.png";
import Talk from "../../assets/Contact/Talk_to_Us.png";
import Work from "../../assets/Contact/Work_with_Us.png";
import { getWithExpiry, setWithExpiry } from "../../assets/utility";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  Twitter,
} from "@material-ui/icons";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/contactimages/contactHeader.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  regssmargin: {
    margin: "19rem 0 19rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontWeight: "bold",
    fontSize: 22,
    textAlign: "center",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },

  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // marginTop: "3rem",
    [theme.breakpoints.down(700)]: {
      // marginTop: "3rem",
    },
  },
  mobileBox: {
    color: "#359DB6",
    fontSize: 18,
    fontWeight: 700,
    fontStyle: "normal",
    marginBottom: "2rem",
  },
  webBox: {
    color: "#359DB6",
    fontSize: 18,
    fontWeight: 700,
    fontStyle: "normal",
    marginBottom: "2rem",
  },

  imgbox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down(856)]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  rowbox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: " 0 6rem 0 6rem",
  },
  boximgtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  boxmidfirsttypo: {
    color: "#FF7300",
    fontSize: "1.8rem",
    fontWeight: 500,
    fontStyle: "normal",
    textAlign: "center",
    // marginTop: "3rem",
    padding: "0 12rem -15rem 12rem",
    [theme.breakpoints.down(700)]: {
      padding: "0 2rem 2rem 0rem",
    },
  },

  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontWeight: 200,
    fontStyle: "normal",
    textAlign: "center",
    margin: "0rem 10rem 2rem 10rem",
    [theme.breakpoints.down(2000)]: {
      margin: "0rem 2rem 2rem 2rem",
    },
  },
  boxend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3rem",
    backgroundColor: "#231f20",
  },

  boxend1typo: {
    color: "#FFFFFF",
    fontSize: "1.2rem",
    fontWeight: "400",
    padding: "4rem 12rem 3rem 12rem",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },

  boxend2typo: {
    fontSize: "1.2rem",
    fontWeight: "400",
    padding: "0 12rem 5rem 12rem",
    color: "#359db6",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },
  btnbox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mobboxDialoge: {
    height: "9.5rem",
    width: "16rem",
    borderRadius: 30,
    color: "#fff",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    // "&:hover": {
    //   height: "13rem",
    //   width: "20rem",
    // },
  },
  boxDialoge: {
    height: "10rem",
    width: "19rem",
    borderRadius: 30,
    // fontFamily: "Open Sans ",
    // fontSize: "0.9rem",
    // fontWeight: 900,
    color: "#fff",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    transitionDuration: "1s",
    transitionProperty: "height,width",
    marginBottom: "auto",
    marginTop: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      height: "14rem",
      width: "20rem",
    },
  },
  boxDialogetypohead: {
    fontStyle: "Open Sans",
    fontWeight: 900,
    fontSize: "1.5rem",
    color: "#359DB6",
    // textAlign: 'center',
    padding: "0 1rem 0rem 1rem",
    "&:hover": {
      fontSize: "2rem",
      fontSize: "1.2rem",
    },
  },
  boxDialogetypo: {
    fontWeight: "Lighter",
    fontStyle: "Open Sans",
    fontSize: "0.8rem",
    // textAlign: 'center',
    padding: "1rem 1rem 1rem 1rem",
    "&:hover": {
      fontSize: "1.2rem",
    },
  },
  sectionTitle: {
    color: "#FF7300",
    fontFamily: "Open-sans",
    fontSize: "30px",
    fontWeight: "600",
    // marginTop: "3rem",
    // textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      marginBottom: "2%",
    },
  },

  SubContent: {
    fontSize: "24px",
    fontWeight: "300",
    // textAlign: "center",
    // lineHeight: "160%",
    padding: "2% 0%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      // lineHeight: "130%",
    },
  },
  Icons: {
    display: "flex",
    width: "30%",
    padding: "2% 0%",
    // alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  IconBox: {
    backgroundColor: "#359DB6",
    padding: "1%",
    borderRadius: "11px",
    display: "flex ",
  },
  lastText: {
    fontSize: "24px",
    fontWeight: "300",
    textAlign: "center",
    // lineHeight: "160%",
    padding: "2% 0%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight: "200",
      margin: "0% 2%",
      // lineHeight: "130%",
    },
  },
  outerBox: {
    height: "14rem",
    width: "20rem",
  },
}));

const Contacts = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };
  const handleLogin = () => {
    const storedRef = getWithExpiry("ref") || null;

    if (storedRef) {
      let loginLink = `${process.env.REACT_APP_URL_LOGIN}/${storedRef}`;
      window.open(loginLink, "_blank");
    } else {
      let loginLink = `${process.env.REACT_APP_URL_LOGIN}`;
      window.open(loginLink, "_blank");
    }
  };
  const redirectToPressKit = () => {
    window.location.pathname = "/presskit";
  };

  const contactData = [
    {
      title: "General Inquiries::",
      description:
        "For general inquiries, feedback, or questions about the Cogito Metaverse, please feel free to reach out to us at ",
      mail: "support@principalityofcogito.com",
    },
    {
      title: "Support:",
      description:
        "If you require technical assistance, have account-related concerns, or need help navigating the Cogito Metaverse platform, reach out to our support team at ",
      mail: "support@principalityofcogito.com",
    },
    {
      title: "Partnerships and Collaborations:",
      description:
        "We're always interested in exploring partnerships and collaborations that align with the vision of the Cogito Metaverse. If you have ideas or proposals, please send them to",
      mail: "support@principalityofcogito.com",
    },
    {
      title: "Media and Press Inquiries::",
      description:
        "For media-related inquiries, interviews, or press releases, please get in touch with our media relations team at",
      mail: "content@principalityofcogito.com",
    },
    {
      title: "Privacy Concerns:",
      description:
        "Your privacy is important to us. If you have questions about our privacy practices or wish to report a privacy-related issue, contact us at",
      mail: "privacy@principalityofcogito.com",
    },
    {
      title: "Career Opportunities:",
      description:
        "Join our team and help shape the future of the Cogito Metaverse. If you would like to work with us please send your details and CV to",
      mail: "content@principalityofcogito.com",
    },
    {
      title: "Our Social Media Handles:",
      description:
        "Stay connected with us on social media for the latest updates and insightful posts!",
      mail: "",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  return (
    <div className="classes.root">
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Get in touch with the Cogito Metaverse team for virtual reality and metaverse finance, investment, and services support. Contact us for any inquiries."
        />
        <link rel="canonical" href="https://cogitometaverse.com/contact" />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              {/* 1st part */}
              {matches ? (
                <Grid container direction="column" alignItems="center">
                  <Grid item className={classes.regssmargin}>
                    <Typography className={classes.regBoxmob}>
                      CONTACT
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction="column">
                  <Grid item className={classes.regssmargin}>
                    <Box className={classes.regBox}>CONTACT</Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box style={{ marginTop: "6%", marginBottom: "6%" }}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box className={classes.boxmid}>
                <Typography
                  style={{
                    fontSize: "3rem",
                    fontWeight: 500,
                    fontStyle: "Open Sans",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#359DB6",
                  }}
                >
                  Contact Us
                </Typography>
              </Box>
              <Box
                style={{
                  padding: "2rem 2rem 0rem 4rem",
                  justifyContent: "center",
                }}
              >
                {contactData.map((e, index) => (
                  <Grid style={{ padding: "2% 1%" }}>
                    <Typography className={classes.sectionTitle}>
                      {e.title}
                    </Typography>
                    <Typography className={classes.SubContent}>
                      {e.description}{" "}
                      <a target="_blank" href={`mailto:${e.mail}`}>
                        {e.mail}
                      </a>
                      .
                    </Typography>
                  </Grid>
                ))}
              </Box>
              <Box className={classes.boxmid}>
                <Box className={classes.Icons}>
                  <Link
                    target="_blank"
                    href="https://www.facebook.com/cogitometaverse/"
                  >
                    <Box className={classes.IconBox}>
                      <Facebook style={{ fill: "#fff", fontSize: "3rem" }} />
                    </Box>
                  </Link>
                  <Link
                    target="_blank"
                    href=" https://www.instagram.com/cogito/"
                  >
                    <Box className={classes.IconBox}>
                      <Instagram style={{ fill: "#fff", fontSize: "3rem" }} />
                    </Box>
                  </Link>
                  <Link
                    target="_blank"
                    href="https://twitter.com/CogitoMetaverse"
                  >
                    <Box className={classes.IconBox}>
                      <Twitter style={{ fill: "#fff", fontSize: "3rem" }} />
                    </Box>
                  </Link>
                  <Link
                    target="_blank"
                    href="https://sg.linkedin.com/company/principality-cogito"
                  >
                    <Box className={classes.IconBox}>
                      <LinkedIn
                        style={{
                          fill: "#fff",
                          fontSize: "3rem",
                          padding: "2%",
                          display: "flex",
                        }}
                      />
                    </Box>
                  </Link>
                  <Link
                    target="_blank"
                    href="https://www.pinterest.nz/CogitoMetaverse/ "
                  >
                    <Box className={classes.IconBox}>
                      <Pinterest
                        style={{
                          fill: "#fff",
                          fontSize: "3rem",
                          padding: "2%",
                          display: "flex",
                        }}
                      />
                    </Box>
                  </Link>
                </Box>
              </Box>
              <Box
                style={{
                  margin: "2% 2%",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Typography className={classes.lastText}>
                  Thank you for your interest in the Cogito Metaverse. We look
                  forward to hearing from you and engaging in meaningful
                  conversations.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default Contacts;
