import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import one from "../../assets/number-images/1.png";
import two from "../../assets/number-images/2.png";
import three from "../../assets/number-images/3.png";
import four from "../../assets/number-images/4.png";
import five from "../../assets/number-images/5.png";
import six from "../../assets/number-images/6.png";
import seven from "../../assets/number-images/7.png";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/hero.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  regssmargin: {
    margin: "19rem 0 19rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontWeight: "bold",
    fontSize: 22,
    textAlign: "center",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },
  grid_second: {
    display: "flex",
    flexDirection: "column",
    height: "8rem",
    width: "100%",
    backgroundColor: "#231f20",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    marginTop: "9rem",
  },

  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "3rem",
    // marginBottom: "3rem",
    [theme.breakpoints.down(700)]: {
      marginTop: "3rem",
    },
  },

  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: "left",
    margin: "0rem 10rem 2rem 10rem",
    [theme.breakpoints.down(2000)]: {
      margin: "0rem 2rem 2rem 2rem",
    },

    // [theme.breakpoints.down("md")]: {
    //   margin: "0rem 4rem 2rem 2rem",
    // },
  },

  title: {
    fontFamily: "arial",
    color: "#359DB6",
    fontSize: "3rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "2%",
  },

  mobileBox: {
    color: "#359DB6",
    fontFamily: "Open Sans",
    fontSize: "1.8rem",
    fontWeight: "bold",
    // fontStyle: "normal",
    marginBottom: "1.9rem",
    [theme.breakpoints.down(850)]: {
      fontSize: 18,
      marginRight: "1.2rem",
    },
  },

  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    textAlign: "left",
  },
}));

const TrustsPage = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  return (
    <div className="classes.root">
      <Helmet>
        <title>Trusts</title>
        <meta
          name="description"
          content="Protect your virtual reality and metaverse assets with Cogito Metaverse trusts. Learn about trust services for VR businesses and individuals."
        />
        <link rel="canonical" href="https://cogitometaverse.com/trusts" />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              {/* 1st part */}
              <Grid item xs={12} sm={12}>
                {matches ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBoxmob}>TRUSTS</Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBox}>TRUSTS</Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {/* 2ndpart */}
        <Box style={{ margin: "6% 4% 6% 4%" }}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box className={classes.boxmid}>
                <Box>
                  <Typography
                    // id={item.title}
                    style={{ fontSize: matches ? "1.5rem" : "3rem" }}
                    className={classes.title}
                  >
                    How to set up a trust in the <br />
                    Cogito Metaverse
                  </Typography>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/shadow.jpg`}
                    alt="shadow"
                    width={"100%"}
                    paddingTop="-30%"
                  />
                </Box>

                <Box direction="column" className={classes.pointtext}>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={one}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                        marginTop: matches ? "0rem" : "0.3rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Check if setting up a trust is right for you
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={two}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                        marginTop: matches ? "0rem" : "0.3rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Apply for a trust name
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={three}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                        marginTop: matches ? "0rem" : "0.3rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      You will receive a draft trust deed
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={four}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                        marginTop: matches ? "0rem" : "0.3rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Decide who the beneficiaries are going to be
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={five}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                        marginTop: matches ? "0rem" : "0.3rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Prepare documents agreeing
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={six}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                        marginTop: "0.3rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Check what records you'll need to keep
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={seven}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                        marginTop: "0.3rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Register your trust
                    </Typography>
                    <Typography style={{ marginBottom: "7rem" }}></Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default TrustsPage;
