import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { webData } from "../../RightContainer/webData";
const useStyles = makeStyles((theme) => ({
  Steps: {
    fontSize: "1.2rem",
    fontWeight: "bolder",
    color: "#359DB6",
    wordSpacing: 3,
    paddingBottom: "2%",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
}));
const WebContent = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { section } = props;
  return (
    <Grid
      container
      spacing={2}
      style={{
        textAlign: "center",
        padding: "2%",
      }}
    >
      {webData[section.section][section.key]?.map((data, index) => {
        return (
          <Grid item xs={12} key={index} style={{ textAlign: "center" }}>
            {data?.title ? (
              <Typography className={classes.Steps}>{data?.title}</Typography>
            ) : null}
            {data?.media ? <img src={data?.media} width="60%" /> : null}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default WebContent;
