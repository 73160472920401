import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import data from "./legaldata";
import ThemeButton from "../../Component/common/button";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/hero.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  regssmargin: {
    margin: "19rem 0 19rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontSize: 22,
    textAlign: "center",
    fontWeight: "bolder",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontFamily: "Arial",
    fontWeight: "bolder",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },

  pointtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
  },
  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    textAlign: "left",
  },
  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "arial !important",
    padding: "0rem 8rem 2rem 8rem",
    [theme.breakpoints.down(600)]: {
      padding: "2rem 2rem 2rem 2rem",
    },
    [theme.breakpoints.down(600)]: {
      padding: "1rem 2rem 1rem 2rem",
      alignItems: "center",
    },
  },
  boxmid2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    fontFamily: "arial",
    padding: "0rem 8rem 2rem 8rem",
    [theme.breakpoints.down(600)]: {
      padding: "2rem 2rem 2rem 2rem",
    },
  },
  boxmidfirsttypo: {
    color: "#359DB6",
    fontSize: "1.8rem",
    fontWeight: 500,
    fontStyle: "normal",
    textAlign: "center",
    // marginTop: "3rem",
    padding: "0 12rem -15rem 12rem",
    // [theme.breakpoints.down(700)]: {
    //   padding: "0 0rem 2rem 0rem",
    // },
  },
  boxmidfirsttypo2: {
    color: "#FF7300",
    fontSize: "1.3rem",
    fontWeight: 600,
    fontStyle: "normal",
    textAlign: "center",
    // marginTop: "3rem",
    padding: "0 12rem 2rem 12rem",
    [theme.breakpoints.down(700)]: {
      padding: "0 0rem 2rem 0rem",
    },
  },
  boxmidfirsttypo3: {
    // color: "#FF7300",
    fontSize: "1.3rem",
    fontWeight: 600,
    fontStyle: "normal",
    textAlign: "center",
    // marginTop: "3rem",
    padding: "0 12rem 2rem 12rem",
    [theme.breakpoints.down(700)]: {
      padding: "0 0rem 2rem 0rem",
    },
  },
  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontWeight: 300,
    fontStyle: "normal",
    textAlign: "center",
    margin: "0rem 6rem 2rem 6rem",
    // [theme.breakpoints.down(2000)]: {
    //   margin: "0rem 8rem 2rem 2rem",
    // },
  },

  paragraph: {
    fontFamily: "Open Sans",
    textAlign: "center",
    fontSize: "1.2rem",
    fontWeight: "lighter",
    marginTop: "2%",
  },

  mobileBox: {
    color: "#359DB6",
    fontSize: 16,
    fontWeight: 700,
    fontStyle: "normal",
    marginBottom: "2rem",
  },
  webBox: {
    color: "#359DB6",
    fontSize: 18,
    fontWeight: 700,
    fontStyle: "normal",
    marginBottom: "2rem",
  },

  boximgtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  boxDialoge: {
    height: "4.5rem",
    width: "12.5rem",
    borderRadius: 8,
    color: "#fff",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    transitionProperty: "height,width",
    transitionDuration: "0.5s",
    "&:hover": {
      width: "16rem",
      height: "7rem",
    },
  },
  boxDialogetypo: {
    fontFamily: "Arial ",
    fontSize: "0.9rem",
    fontWeight: 900,
    textAlign: "center",
    paddingLeft: 8,
  },
  title: {
    fontFamily: "Arial",
    color: "#359DB6",
    marginTop: "10%",
    fontWeight: "bolder",
    textAlign: "left",
    fontSize: "2.7rem",
    [theme.breakpoints.down(1000)]: {
      fontSize: "1.2rem",
      fontWeight: "bolder",
      marginTop: "2%",
    },
  },
}));

const LegalPage = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  const redirectToFaq = () => {
    window.location.pathname = "/faq";
  };
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        marginLeft: "-100%",
        marginRight: "-23.1%",
        // height: "0px",
        // borderColor: "#F5F5F5",
        borderColor: "rgb(207 206 206 / 15%)",
      }}
    />
  );
  return (
    <div className="classes.root">
      <Helmet>
        <title>Legal</title>
        <meta
          name="description"
          content="Get updates on Cogito Metaverse's legal policies & terms of use. Understand the conditions for our virtual reality & metaverse products & services."
        />
        <link rel="canonical" href="https://cogitometaverse.com/legal" />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              <Grid item xs={12} sm={12}>
                {matches ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBoxmob}>LEGAL</Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBox}>LEGAL</Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box style={{ margin: matches ? "3% 2% 2% 2%" : "6%  4%  6%  4%" }}>
          <Grid item xs={12} sm={12}>
            <Box className={classes.boxmid}>
              <Box
                style={{
                  padding: matches ? "0 0rem 0 0rem" : " 0 2rem 0 2rem",
                }}
              >
                <ColoredLine />
                <Typography
                  style={{
                    marginBottom: "2%",
                    fontFamily: "Open Sans",
                    textAlign: "center",
                    fontSize: matches ? "1.2rem" : "1.5rem",
                    fontWeight: 800,
                    paddingBottom: "1.3rem",
                    paddingTop: "3rem",
                  }}
                >
                  Definition of principality
                </Typography>

                <Typography
                  className={classes.paragraph}
                  style={{
                    paddingBottom: matches ? "2rem" : "3rem",
                    textAlign: matches ? "justify" : "center",
                  }}
                >
                  The state, office, or authority of a prince; the territory or
                  jurisdiction of a prince the country that gives title to a
                  prince.
                </Typography>
                <ColoredLine />
              </Box>
              <Box style={{ padding: "2rem 0 0 0" }}>
                <Typography
                  className={classes.paragraph}
                  style={{
                    textAlign: "justify",
                    fontSize: matches ? "1rem" : "1.2rem",
                  }}
                >
                  The Cogito Metaverse was created by the Principality of
                  Cogito. The Principality of Cogito was established as a
                  decentralized libertarian state; and it now stands as one of
                  the last safe haven that has not been controlled via large
                  government sanctions. The independent status of the
                  Principality of Cogito entitles the citizens of the
                  Principality (and thus members of the Cogito Metaverse) to the
                  ultimate security. The Principality of Cogito gives the
                  opportunity to buy and sell goods and services, save and
                  invest in a financial future without the highly regulated,
                  self-interest based controls and obligations placed by
                  governments desperate to hang on to their diminishing taxable
                  base. Everyone in the Principality of Cogito has the
                  opportunity to build the future they wish to have, there is no
                  discrimination based on their location, status or education.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              {matches ? (
                <Box className={classes.boxmid}>
                  {data.map((item, index) => {
                    return (
                      <Box
                        style={{
                          marginTop: "2rem",
                          marginBottom: "0rem",
                          textAlign: "justify",
                        }}
                      >
                        <Typography id={item.title} className={classes.title}>
                          <span
                            style={{
                              paddingRight: "2%",
                              paddingBottom: "0.5%",
                            }}
                          >
                            {"\u2022"}{" "}
                          </span>
                          {"   "}
                          {item.title}
                        </Typography>
                        {/* <img
                          src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/shadow.jpg`}
                          alt="shadow"
                          width={"100%"}
                          marginTop="-30%"
                        /> */}
                        <Typography
                          style={{
                            marginTop: "6%",
                            fontFamily: "Open Sans",
                            fontWeight: "lighter",
                            textAlign: "justify ",
                            fontSize: "1rem",
                          }}
                        >
                          {item.content}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Box className={classes.boxmid2}>
                  {data.map((item, index) => {
                    return (
                      <>
                        <Box>
                          <ul id={item.title} className={classes.title}>
                            <span style={{ paddingRight: "2%" }}>
                              {"\u2022"}{" "}
                            </span>
                            {"   "}
                            {item.title}
                          </ul>
                          {/* <img
                            src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/shadow.jpg`}
                            alt="shadow"
                            width={"100%"}
                            marginTop="-30%"
                          /> */}
                        </Box>
                        <Typography
                          style={{
                            marginTop: "5%",
                            fontFamily: "Open Sans",
                            fontWeight: "lighter",
                            textAlign: "left",
                            fontSize: matches ? "1rem" : "1.2rem",
                          }}
                        >
                          {item.content}
                        </Typography>
                      </>
                    );
                  })}
                </Box>
              )}
              <Box
                className={matches ? "" : classes.boxmid}
                style={{
                  paddingTop: matches ? "3rem" : "2rem",
                  paddingBottom: matches ? "3rem" : "0rem",
                  paddingLeft: matches ? "4rem" : "0rem",
                }}
              >
                <ThemeButton
                  title="Find some more frequently asked questions"
                  size={15}
                  height={matches ? 60 : null}
                  onClick={redirectToFaq}
                  width={matches ? 250 : 500}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default LegalPage;
