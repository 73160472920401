import {
  Box,
  Button,
  ButtonBase,
  Container,
  Grid,
  Icon,
  IconButton,
  Link,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import AppStore from "../../assets/pocketMoney/AppStore.svg";
import playstore from "../../assets/pocketMoney/PlayStore.svg";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  Header: {
    height: "55vh",
    backgroundColor: "#231F20",
    borderBottom: "5px solid #359DB6",
    [theme.breakpoints.down("sm")]: {
      height: "40vh",
    },
  },
  TitleContainer: {
    color: "#FF7300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Title: {
    fontSize: "80px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px",
    },
  },
  content: {
    // padding: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sectionTitle: {
    color: "#FF7300",
    fontFamily: "Open-sans",
    fontSize: "40px",
    fontWeight: "800",
    marginTop: "3rem",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
      marginBottom: "2%",
    },
  },
  SubContent: {
    fontSize: "1.2rem",
    fontWeight: "300",
    textAlign: "center",
    lineHeight: "160%",
    padding: "1% 6%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      // lineHeight: "130%",
    },
  },
  AppInfo: {
    fontSize: "30px",
    fontWeight: "500",
    lineHeight: "160%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "130%",
    },
  },
  Icons: {
    display: "flex",
    width: "30%",
    padding: "2% 0%",
    // alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  IconBox: {
    backgroundColor: "#359DB6",
    padding: "1%",
    borderRadius: "11px",
    display: "flex ",
  },
}));
const PocketMoney = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid Container>
      <Helmet>
        <title>Pocket Money</title>
        <meta
          name="description"
          content="Join the team at Cogito Metaverse and explore career opportunities in virtual reality, metaverse finance, investment, and services. Start working with us today."
        />
        <link
          rel="canonical"
          href=" https://cogitometaverse.com/work_with_us "
        />
      </Helmet>
      <Grid item container className={classes.Header}>
        <Grid item xs={12} className={classes.TitleContainer}>
          <Typography className={classes.Title}>Pocket Money</Typography>
        </Grid>
      </Grid>
      <Container>
        <Grid item container className={classes.content}>
          {/* <Grid item xs={12}>
            <Typography className={classes.sectionTitle}>
              Rewarding Responsibilities
            </Typography>
          </Grid> */}
          <Grid item xs={12}>
            <Box>
              <Typography className={classes.SubContent}>
                The Pocket Money App is an ed-tech invention designed and
                developed by the creators of Cogito Metaverse to help kids learn
                about money. We know that the future is digital, so it's
                important for kids to learn about personal finance and be
                self-dependent.
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.SubContent}>
                With Pocket Money, kids can learn practical skills that will
                help them in the future. They can also see how hard work pays
                off and feel good about being responsible. It is a great
                platform for kids to learn about the value of their hard work
                and become more confident in the modern world.
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.SubContent}>
                The Pocket Money platform has been designed to help parents
                teach their children about responsibility and financial
                management in a fun and engaging way. It offers age-appropriate
                tasks that teach children valuable life skills, such as cleaning
                up their play area, making their bed, and packing their school
                bag.
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.SubContent}>
                Through doing chores and being rewarded, children are able to
                begin to learn the value of money, understand the importance of
                saving, and begin to understand budgeting and money management.
                By earning for their work and growing to be self-dependent,
                children develop positive money habits that benefit them
                throughout their lives.
                <br />
                <br />
                <br />
                So, what are you waiting for?{" "}
                <a
                  href="https://app.principalityofcogito.com/signup?isPocketMoneySignUp=true "
                  target="_blank"
                >
                  {"  "}
                  Sign up
                </a>
                {"  "}
                to Pocket Money today!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.sectionTitle}>
              The Pocket Money App
            </Typography>
          </Grid>
          <Grid>
            <Typography className={classes.SubContent}>
              The Pocket Money app is simple and easy to use, and it's
              customizable to your family's needs. It's a great tool for
              parents, caregivers, and guardians to use to teach children about
              responsibility and financial management. Our app is a fun and
              interactive way for children to learn valuable life skills and
              become more confident in the modern world.
            </Typography>
          </Grid>

          <Grid
            item
            style={{
              color: "#359DB6",
              padding: "4% 0%",
              textAlign: "center",
            }}
          >
            <Typography className={classes.AppInfo}>
              “Our mobile app is now available!
            </Typography>
            <Typography className={classes.AppInfo}>
              Download it today and enjoy all its features on-the-go.”
            </Typography>
            <Box
              style={{
                textAlign: "center",
                padding: "4% 0%",
              }}
            >
              <Link
                target="_blank"
                href="https://apps.apple.com/in/app/pocketmoney-cogito/id6448836756"
              >
                <img
                  // src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/footerimages/appstore.png`}
                  src={AppStore}
                  style={{
                    width: "30%",
                    paddingRight: "2%",
                  }}
                />
              </Link>
              <Link
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.pocketmoneycogito&pli=1"
              >
                <img
                  // src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/footerimages/playstore.png`}
                  src={playstore}
                  style={{
                    width: "30%",
                    paddingLeft: "2%",
                  }}
                />
              </Link>
            </Box>
          </Grid>
          <Grid>
            <Typography className={classes.SubContent}>
              You can read more about Pocket Money{" "}
              <a
                href="https://pocketmoney.principalityofcogito.com/"
                target="_blank"
              >
                here.{" "}
              </a>
            </Typography>
          </Grid>
          {/* <Grid item xs={12}> */}
          <Box>
            <Typography className={classes.SubContent}>
              Follow us on social media to get the latest updates!
            </Typography>
          </Box>
          <Box className={classes.Icons}>
            <Link
              target="_blank"
              href="https://www.facebook.com/cogpocketmoney/"
            >
              <Box className={classes.IconBox}>
                <Facebook style={{ fill: "#fff", fontSize: "3rem" }} />
              </Box>
            </Link>
            <Link
              target="_blank"
              href="https://www.instagram.com/cogpocketmoney/"
            >
              <Box className={classes.IconBox}>
                <Instagram style={{ fill: "#fff", fontSize: "3rem" }} />
              </Box>
            </Link>
            <Link target="_blank" href="https://twitter.com/cogpocketmoney">
              <Box className={classes.IconBox}>
                <Twitter style={{ fill: "#fff", fontSize: "3rem" }} />
              </Box>
            </Link>
            {/* <Link
              target="_blank"
              href="https://www.linkedin.com/company/cogpocketmoney"
            >
              <Box className={classes.IconBox}>
                <LinkedIn
                  style={{
                    fill: "#fff",
                    fontSize: "3rem",
                    padding: "2%",
                    display: "flex",
                  }}
                />
              </Box>
            </Link> */}
          </Box>
          {/* </Grid> */}
        </Grid>
      </Container>
    </Grid>
  );
};

export default PocketMoney;
