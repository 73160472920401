import React from "react";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  makeStyles,
  useMediaQuery,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import personalData from "./personalData";
import ThemeButton from "../../Component/common/button";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    // paddingTop: "2.5rem",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  pointtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    overflow: "hidden",
    // padding: '0 0rem 0 10rem'
  },
  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    textAlign: "left",
  },
  mobileBox: {
    color: "#359DB6",
    fontSize: 18,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    marginBottom: "2rem",
  },
  mobheaderName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 7,
    fontSize: 22,
    textAlign: "center",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    textAlign: "center",
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },

  content: { paddingTop: "3%", paddingBottom: "3%", textAlign: "center" },
  bodyContent: {
    fontFamily: "Open Sans",
    fontSize: "1.3rem",
    paddingTop: "4%",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      textAlign: "justify",
      padding: "0 1.2rem 0 1.2rem",
    },
  },

  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2rem 3rem 2rem 3rem",
    // marginBottom: "5rem",
    [theme.breakpoints.down("md")]: {
      // marginTop: "3rem",
      margin: "2rem 3rem 2rem 3rem",
    },
  },

  points: {
    color: "#359DB6",
    fontSize: 25,
    fontWeight: 700,
    fontStyle: "normal",
  },
}));

const PersonalBanking = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  return (
    <Grid container>
      <Helmet>
        <title>Personal-banking</title>
        <meta
          name="description"
          content="Experience virtual reality and metaverse personal banking at Cogito Metaverse. Discover financial services and investment opportunities for individuals in VR."
        />
        <link
          rel="canonical"
          href="https://cogitometaverse.com/personal-banking"
        />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.mobheaderName}>
                    {" "}
                    PERSONAL
                    <br />
                    BANKING
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    {" "}
                    PERSONAL BANKING
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Container className={classes.content}>
        <Grid
          container
          spacing={5}
          justifyContent="center"
          style={{ paddingTop: matches ? null : "120px" }}
        >
          {matches ? (
            <Box className={classes.boxmid}>
              <Box
                direction="column"
                className={classes.pointtext}
                style={{ paddingTop: "30px" }}
              >
                {personalData.map((item) => {
                  return (
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={28}
                        style={{
                          paddingRight: "1rem",
                        }}
                      />
                      <Typography className={classes.mobileBox}>
                        {item}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : (
            <>
              {personalData.map((item) => {
                return (
                  <>
                    <Grid item sm={2}></Grid>
                    <Grid item xs={3} sm={2} style={{ textAlign: "right" }}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        height={35}
                        alt="tick"
                        style={{ paddingRight: "0rem" }}
                      />
                    </Grid>
                    <Grid item xs={8} sm={6} style={{ textAlign: "left" }}>
                      <Typography className={classes.points}>{item}</Typography>
                    </Grid>
                    <Grid item sm={2}></Grid>
                  </>
                );
              })}
            </>
          )}

          {/* <Box className={classes.boxmid}> */}
          {matches ? (
            <>
              <Grid item xs={12}>
                <Typography className={classes.bodyContent}>
                  Members of the Cogito Metaverse will be able to use the app to
                  pay for goods using digital currency anywhere in the world.
                  This enables members to be able to move Cog around the world
                  without the barriers of different countries. In a similar way
                  to the standard banking system, you will be able to carry out
                  all your financial transactions, including saving and
                  investing. The Cogito Metaverse Financial Literacy program
                  will help to demystify things such as tax, interest and the
                  stock exchange. This will give ordinary people in any part of
                  the world, the ability to invest in their future and the
                  future of the next generation.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.bodyContent}>
                  Secure, convenient banking with our mobile app!
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Link to="/app-pos" style={{ textDecoration: "none" }}>
                  <ThemeButton title="FIND OUT MORE" />
                </Link>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography className={classes.bodyContent}>
                  Members of the Cogito Metaverse will be able to use the app to
                  pay for goods using digital currency anywhere in the world.
                  This enables members to be able to move Cog around the world
                  without the barriers of different countries. In a similar way
                  to the standard banking system, you will be able to carry out
                  all your financial transactions, including saving and
                  investing. The Cogito Metaverse Financial Literacy program
                  will help to demystify things such as tax, interest and the
                  stock exchange. This will give ordinary people in any part of
                  the world, the ability to invest in their future and the
                  future of the next generation.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.bodyContent}>
                  Secure, convenient banking with our mobile app!
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Link to="/app-pos" style={{ textDecoration: "none" }}>
                  <ThemeButton title="FIND OUT MORE" />
                </Link>
              </Grid>
            </>
          )}

          {/* </Box> */}
        </Grid>
      </Container>
    </Grid>
  );
};

export default PersonalBanking;
