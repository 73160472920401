import { Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { dateToWords } from "../../utility";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  resultBox: {
    border: "1px solid #359DB6",
    borderRadius: "10px",
    margin: "2%",
    height: "150px",
    padding: "0px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const SearchResults = (results, loading) => {
  const classes = useStyles();
  const maxStringLength = (str, limit) => {
    return `${str.slice(0, limit)}${str.length > limit ? "..." : "..."}`;
  };

  return (
    <Grid container>
      <>
        {results?.results?.map((result, i) => {
          return (
            <Grid item container className={classes.resultBox}>
              <Grid item xs={2}>
                <img
                  className="results-image"
                  src={`${
                    process.env.REACT_APP_ENV === "prod"
                      ? process.env.REACT_APP_SPACE_KEY_PROD
                      : process.env.REACT_APP_SPACE_KEY
                  }/${result.pic}`}
                  alt="cover"
                  width="100%"
                />
              </Grid>
              <Grid item xs={10} style={{ padding: "1%" }}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      color: "#4D4D4D",
                      fontSize: "1.2rem",
                      fonrWeight: "600",
                    }}
                  >
                    {result.title}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "lighter",
                      color: "#4D4D4D",
                    }}
                  >
                    {" "}
                    {dateToWords(result?.created_at)}
                  </Typography>
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    style={{
                      fontSize: "0.8rem",
                      color: "#666666",
                      marginTop: "2%",
                    }}
                  >
                    {parse(maxStringLength(result?.body, 250))}
                    <Link
                      className="blogItem-link"
                      id={result?.id}
                      to={`/insight/${result?.id}`}
                      style={{
                        justifyContent: "end",
                        fontSize: "0.8rem",
                        textDecoration: "none",
                        color: "#359DB6",
                      }}
                    >
                      {`more`}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </>
    </Grid>
  );
};
export default SearchResults;
