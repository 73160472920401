import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { isMobile } from "react-device-detect";
import { setWithExpiry, getWithExpiry } from "../../assets/utility";
import ThemeButton from "../../Component/common/button";
import aboutMenu1 from "../../assets/about/aboutMenu1.png";
import aboutMenu3 from "../../assets/about/aboutMenu3.png";
import aboutMenu4 from "../../assets/about/aboutMenu4.png";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/aboutusimages/aboutHeader.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  regssmargin: {
    margin: "19rem 0 19rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontWeight: "bolder",
    fontSize: 22,
    textAlign: "center",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontWeight: "bolder",
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },

  typo2: {
    fontSize: "1.3em",
    color: "#ff7300",
    [theme.breakpoints.down(440)]: {
      fontSize: "1em",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "0.8em",
    },
  },

  vector: {
    margin: "1rem 0 1rem 0",
  },

  img1: {
    height: "80px",
    [theme.breakpoints.down(440)]: {
      height: "60px",
    },
    [theme.breakpoints.down(350)]: {
      height: "40px",
    },
  },

  vector1: {
    margin: "0 0 1rem 0",
  },
  vector2: {
    margin: "3rem 0 0 0",
  },

  img2: {
    height: "30px",
    [theme.breakpoints.down(440)]: {
      height: "20px",
    },
    [theme.breakpoints.down(350)]: {
      height: "15px",
    },
  },
  img3: {
    height: "30px",
    marginBottom: 12,
    [theme.breakpoints.down(440)]: {
      height: "25px",
    },
    [theme.breakpoints.down(350)]: {
      height: "20px",
    },
    [theme.breakpoints.down(290)]: {
      height: "20px",
    },
  },

  mobile: {
    marginTop: "5rem",
    position: "absolute",
    height: "630px",
    [theme.breakpoints.down(440)]: {
      height: "400px",
    },
    [theme.breakpoints.down(350)]: {
      height: "300px",
    },
    [theme.breakpoints.down(290)]: {
      height: "200px",
    },
  },
  midBoximg: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem  0rem 1rem 7rem",
  },
  midBoxtypohead: {
    text: "left",
    fontSize: "1.3rem",
    fontWeight: 800,
    fontFamily: "Open Sans",
    padding: " 1rem 0 2rem 0rem",
  },
  midBoxtyposub: {
    text: "left",
    fontSize: "1.2rem",
    fontWeight: "lighter",
    fontFamily: "Open Sans",
    padding: " 0rem 0 3rem 0rem",
  },
  last_banner: {
    height: "15rem",
    width: "100%",
    backgroundColor: "#FF7300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    marginTop: "1rem",
  },

  grid_second: {
    height: "15rem",
    width: "100%",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
  },

  mobile1: {
    height: "450px",
    marginTop: "5rem",
    [theme.breakpoints.down(440)]: {
      height: "350px",
    },
    [theme.breakpoints.down(350)]: {
      height: "250px",
    },
    [theme.breakpoints.down(290)]: {
      height: "150px",
    },
  },

  grid_second1: {
    position: "absolute",
    right: "0rem",
    top: "63rem",
    height: "15rem",
    width: "100vw",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    [theme.breakpoints.down(440)]: {
      top: "54rem",
    },
    [theme.breakpoints.down(350)]: {
      top: "45.5rem",
    },
    [theme.breakpoints.down(290)]: {
      top: "39rem",
    },
  },

  grid_second1_typo: {
    fontSize: "1.8rem",
    fontWeight: 500,
    textAlign: "center",
    fontStyle: "normal",
    [theme.breakpoints.down(440)]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.down(290)]: {
      fontSize: "1rem",
    },
  },

  mainBx: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "5rem",
  },
  pointtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
  },
  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    textAlign: "left",
  },
  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "3rem",
    [theme.breakpoints.down(700)]: {
      marginTop: "3rem",
      margin: "1rem 1rem 1rem 1rem",
    },
  },
  mobileBox: {
    color: "#359DB6",
    fontSize: 16,
    fontWeight: 700,
    fontStyle: "normal",
    marginBottom: "2rem",
  },
  webBox: {
    color: "#359DB6",
    fontSize: 18,
    fontWeight: 700,
    fontStyle: "normal",
    marginBottom: "2rem",
  },

  imgbox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down(856)]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  rowbox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: " 0 6rem 0 6rem",
  },
  boximgtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  boxmidfirsttypo: {
    color: "#FF7300",
    fontSize: "1.8rem",
    fontWeight: 200,
    fontWeight: "lighter",
    fontFamily: "Open Sans",
    textAlign: "center",
    // marginTop: "3rem",
    padding: "0 12rem -15rem 12rem",
    [theme.breakpoints.down(700)]: {
      padding: "0 2rem 2rem 0rem",
      letterSpacing: 0,
    },
  },

  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontWeight: "lighter",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    textAlign: "center",
    margin: "0rem 10rem 2rem 10rem",
    [theme.breakpoints.down(2000)]: {
      margin: "1rem 0.5rem 1rem 0.5rem",
      // letterSpacing: 1
      fontSize: "1rem",
      textAlign: "justify",
    },
  },
  boxend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3rem",
    backgroundColor: "#231f20",
  },

  boxend1typo: {
    color: "#FFFFFF",
    fontSize: "1.2rem",
    fontWeight: 200,
    padding: "4rem 12rem 3rem 12rem",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
      // letterSpacing: 1
      fontSize: "1rem",
    },
  },

  boxend2typo: {
    fontSize: "1.2rem",
    fontWeight: 200,
    padding: "0 12rem 5rem 12rem",
    color: "#359db6",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
      // letterSpacing: 1
      fontSize: "1rem",
    },
  },
  bannerText: {
    fontSize: "4rem",
    fontFamily: "Arial Black",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "center",
    [theme.breakpoints.down(856)]: {
      fontSize: "2rem",
      fontFamily: "Arial Black",
      fontWeight: "bold",
    },
  },
  btnbox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Aboutus = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");

  const theme = useTheme();
  const paramRef = useParams().ref || null;
  // const matches = useMediaQuery(theme.breakpoints.down("md"));

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  useEffect(() => {
    if (paramRef) {
      setWithExpiry("ref", paramRef, 24 * 7);
    }
  }, [paramRef]);

  const handleClickSingUp = () => {
    const storedRef = getWithExpiry("ref") || null;

    if (storedRef) {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      window.open(signupLink, "_blank");
    } else {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      window.open(signupLink, "_blank");
    }
  };

  const redirectToAbout = () => {
    window.location.pathname = "/faq";
  };

  return (
    <div className="classes.root">
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="Discover the world of virtual reality and metaverse at Cogito Metaverse. Learn more about the future of technology and immersion."
        />
        <link rel="canonical" href="https://cogitometaverse.com/about" />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              <Grid item xs={12} sm={12}>
                {matches ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBoxmob}>ABOUT METAVERSE</Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBox}>ABOUT METAVERSE</Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box
          style={{ marginTop: "6%", marginBottom: "6%", overflow: "hidden" }}
        >
          {/* 2ndpart */}
          <Container>
            <Grid container xs={12} sm={12}>
              {matches ? (
                <Grid container>
                  <Grid item className={classes.boxmid}>
                    <Box>
                      <Typography
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "bold",
                          fontStyle: "normal",
                          fontFamily: "Open Sans",
                          textAlign: "center",
                          color: "#359db6",
                          paddingBottom: "8%",
                          letterSpacing: matches ? 0 : "null",
                        }}
                      >
                        Earn more, spend, save and invest in digital currency
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        margin: " 0.5rem 0 2rem 0 ",
                      }}
                    >
                      <ThemeButton
                        title="REGISTER"
                        size={12}
                        onClick={handleClickSingUp}
                        width={130}
                      />

                      <ThemeButton
                        title="LEARN MORE"
                        size={12}
                        margin=" 0% 0 0% 7% "
                        width={130}
                        onClick={redirectToAbout}
                      />
                    </Box>
                    <Box>
                      <Typography className={classes.boxmidsecondtypo}>
                        The Cogito Metaverse is a revolutionary way of managing
                        finance in a digital space. By using technology, the
                        Cogito Metaverse breaks traditional borders and is open
                        to citizens from every country in the world. All human
                        beings can apply, no age restrictions and no country
                        restrictions
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.boxmidsecondtypo}>
                        In the Cogito Metaverse citizens gain the ability to
                        support each other, to provide freedom of trade and the
                        ability to start investing. In the Cogito Metaverse, you
                        will have the ability to:
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid container justifyContent="space-around">
                    <Grid item xs={7} sm={2}>
                      <Box className={classes.mobimgbox}>
                        <Box direction="column" className={classes.boximgtext}>
                          <img
                            src={aboutMenu1}
                            alt="equality"
                            height={110}
                            style={{ marginTop: "3rem", marginBottom: "2rem" }}
                          />
                          <Typography
                            style={{
                              marginBottom: "2rem",
                              textAlign: "center",
                            }}
                          >
                            Financial structures
                          </Typography>
                        </Box>
                        <Box direction="column" className={classes.boximgtext}>
                          <img
                            src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/aboutusimages/cogeducation.svg`}
                            alt="finance"
                            height={100}
                            style={{ marginTop: "1rem", marginBottom: "1rem" }}
                          />
                          <Typography
                            style={{
                              marginBottom: "1rem",
                              textAlign: "center",
                            }}
                          >
                            Educational Empowerment
                          </Typography>
                        </Box>

                        <Box direction="column" className={classes.boximgtext}>
                          <img
                            src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/aboutusimages/InterestFree.svg`}
                            alt="lowtax"
                            height={160}
                            // style={{ marginBottom: "2rem" }}
                            // style={{ marginTop: "2rem", marginBottom: "2rem" }}
                          />
                          <Typography
                            style={{
                              marginBottom: "2rem",
                              textAlign: "center",
                            }}
                          >
                            Economic Inclusivity
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.boxmid}>
                    <Box style={{ marginBottom: "8%" }}>
                      <Typography className={classes.boxmidsecondtypo}>
                        The Cogito Metaverse brings our global market together
                        in one place by breaking down boundaries and eliminating
                        government control of people’s finances. You will
                        eventually have access to a growing market of fractional
                        investments, enabling small to large investments with a
                        higher level of security and transparency.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ) : (
                <Grid container direction="column">
                  <Box className={classes.boxmid}>
                    <Box>
                      <Typography
                        style={{
                          fontSize: "2rem",
                          fontWeight: 500,
                          fontStyle: "normal",
                          textAlign: "center",
                          color: "#359db6",
                          paddingBottom: "8%",
                        }}
                      >
                        Earn more, spend, save and invest in digital currency
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        // justifyContent: "flex-end",
                        padding: "0rem 0 4rem 0",
                      }}
                    >
                      <ThemeButton
                        title="REGISTER"
                        size={16}
                        onClick={handleClickSingUp}
                        width={200}
                        // height={60}
                      />

                      <ThemeButton
                        title="LEARN MORE"
                        size={15}
                        margin="0 0 0 6%"
                        width={200}
                        onClick={redirectToAbout}
                      />
                    </Box>
                    <Box>
                      <Typography className={classes.boxmidsecondtypo}>
                        The Cogito Metaverse is a revolutionary way of managing
                        finance in a digital space. By using technology, the
                        Cogito Metaverse breaks traditional borders and is open
                        to citizens from every country in the world. All human
                        beings can apply, no age restrictions and no country
                        restrictions
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.boxmidsecondtypo}>
                        In the Cogito Metaverse citizens gain the ability to
                        support each other, to provide freedom of trade and the
                        ability to start investing. In the Cogito Metaverse, you
                        will have the ability to:
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.imgbox}>
                    <Box direction="column" className={classes.boximgtext}>
                      <img
                        src={aboutMenu1}
                        alt="equality"
                        height={110}
                        style={{ marginTop: "1rem", marginBottom: "2rem" }}
                      />
                      <Typography style={{ marginBottom: "2rem" }}>
                        Financial structures
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boximgtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/aboutusimages/cogeducation.svg`}
                        alt="interestfree"
                        height={140}
                        style={{ marginBottom: "1rem" }}
                      />
                      <Typography
                        style={{ marginBottom: "2rem", textAlign: "center" }}
                      >
                        Educational Empowerment
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boximgtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/aboutusimages/InterestFree.svg`}
                        alt="interestfree"
                        height={160}
                        // style={{ marginBottom: "2rem" }}
                      />
                      <Typography
                        style={{ marginBottom: "2rem", textAlign: "center" }}
                      >
                        Economic Inclusivity
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Container>
          <Grid
            container
            direction="column"
            style={{
              background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/aboutusimages/aboutPageBuilding.jpg)`,
              height: matches ? "40vh" : "80vh",
              backgroundSize: "100%",
              // backgroundPosition: "",
              backgroundRepeat: "no-repeat",
              marginBottom: matches ? "" : "5%",
            }}
          >
            <Grid
              item
              xs={7}
              style={{
                paddingLeft: matches ? "9%" : "3%",
                paddingRight: matches ? "6%" : "null",
                paddingTop: matches ? "10%" : "",
                marginTop: matches ? "12%" : "19%",
              }}
            >
              <Typography
                variant={matches ? "null" : "h3"}
                style={{ fontFamily: "Arial", color: "white" }}
              >
                The Cogito Metaverse will give you the ability to{" "}
                <span style={{ fontWeight: "bold" }}>build wealth</span> in a
                <span style={{ fontWeight: "bold" }}> new way</span>.
              </Typography>
            </Grid>
          </Grid>
          {/* 3rd part */}
          <Container>
            {matches ? (
              <Grid container direction="column">
                <Box direction="column" className={classes.boximgtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/aboutusimages/growth.svg`}
                    alt="Unique Digital Currency"
                    height={100}
                    style={{ marginBottom: "2rem" }}
                  />
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                    }}
                  >
                    Unique Digital Currency
                  </Typography>
                </Box>
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography className={classes.boxmidsecondtypo}>
                      At the heart of the Cogito Metaverse lies our digital
                      currencies, known as the Cog and also the Cash Backed Cog.
                      As a member of the Cogito Metaverse, you will have the
                      opportunity to buy, sell, and earn everyday goods using
                      Cog. Our vision extends beyond the digital realm, aiming
                      for Cog to become a widely accepted form of payment in
                      ordinary shops across the globe. While the Metaverse is
                      still in its development stage, we are witnessing a
                      growing number of members joining our community, and
                      businesses embracing Cog as an everyday currency alongside
                      traditional fiat.
                    </Typography>
                  </Box>
                </Box>
                <Box direction="column" className={classes.boximgtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/aboutusimages/anyltics.svg `}
                    alt="Financial Flexibility"
                    height={100}
                    style={{ marginTop: "2rem", marginBottom: "2rem" }}
                  />
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                    }}
                  >
                    Financial Flexibility
                  </Typography>
                </Box>
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography className={classes.boxmidsecondtypo}>
                      Cogito is a natural evolution in the world of global
                      digital currency, where everyone is treated as equals. By
                      embracing Cogito, you can transcend the financial
                      limitations imposed by governments, as our currency
                      remains unaffected by exchange rates and provides fair
                      taxation. Moreover, our open and transparent global
                      marketplace ensures a level playing field for all
                      participants.
                    </Typography>
                  </Box>
                </Box>
                <Box direction="column" className={classes.boximgtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/contents/engagement-rewards.png `}
                    alt="Engagement and Rewards"
                    height={100}
                    style={{ marginTop: "2rem", marginBottom: "2rem" }}
                  />
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                    }}
                  >
                    Engagement and Rewards
                  </Typography>
                </Box>
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography className={classes.boxmidsecondtypo}>
                      We believe in the power of engagement and incentivizing
                      hard work, not only in academics but also in cultivating a
                      strong work ethic and fostering a sense of community. In
                      the Cogito Metaverse, we offer a universal retainer income
                      for individuals actively engaged in growing the Metaverse.
                      Additionally, our pocket money app provides children and
                      young people with a platform that connects their efforts
                      and achievements with tangible rewards. By embracing this
                      holistic approach, we empower individuals of all ages to
                      thrive and contribute to the Metaverse's development.
                    </Typography>
                  </Box>
                </Box>
                <Box direction="column" className={classes.boximgtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/contents/financial-trusts.png`}
                    alt="Financial Structures and Trusts"
                    height={100}
                    style={{ marginTop: "2rem", marginBottom: "2rem" }}
                  />
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                    }}
                  >
                    Financial Structures and Trusts
                  </Typography>
                </Box>
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography className={classes.boxmidsecondtypo}>
                      Within the Cogito Metaverse, we have developed
                      cutting-edge technology that allows for the registration
                      of trusts and businesses. Our vision is to reach one
                      million businesses, at which point we will open the stock
                      exchange. By securing your business in the Cogito
                      Metaverse, you gain access to a safe and secure repository
                      for private and commercial operations, providing you with
                      peace of mind and greater control over your assets and tax
                      management.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Box direction="column">
                <Grid container direction="row">
                  <Grid item xs={5} sm={4}>
                    <Box className={classes.midBoximg}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/aboutusimages/growth.svg`}
                        alt="Unique Digital Currency"
                        height={125}
                        style={{ marginTop: "10%" }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={7}>
                    <Box>
                      <Box>
                        <Typography className={classes.midBoxtypohead}>
                          Unique Digital Currency
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.midBoxtyposub}>
                          At the heart of the Cogito Metaverse lies our digital
                          currencies, known as the Cog and also the Cash Backed
                          Cog. As a member of the Cogito Metaverse, you will
                          have the opportunity to buy, sell, and earn everyday
                          goods using Cog. Our vision extends beyond the digital
                          realm, aiming for Cog to become a widely accepted form
                          of payment in ordinary shops across the globe. While
                          the Metaverse is still in its development stage, we
                          are witnessing a growing number of members joining our
                          community, and businesses embracing Cog as an everyday
                          currency alongside traditional fiat.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container direction="row">
                  <Grid item xs={5} sm={4}>
                    <Box className={classes.midBoximg}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/aboutusimages/anyltics.svg`}
                        alt="Financial Flexibility"
                        height={125}
                        style={{ marginTop: "10%" }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={7}>
                    <Box>
                      <Box>
                        <Typography className={classes.midBoxtypohead}>
                          Financial Flexibility
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.midBoxtyposub}>
                          Cogito is a natural evolution in the world of global
                          digital currency, where everyone is treated as equals.
                          By embracing Cogito, you can transcend the financial
                          limitations imposed by governments, as our currency
                          remains unaffected by exchange rates and provides fair
                          taxation. Moreover, our open and transparent global
                          marketplace ensures a level playing field for all
                          participants.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={5} sm={4}>
                    <Box className={classes.midBoximg}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/contents/engagement-rewards.png`}
                        alt="Engagement and Rewards"
                        width="130"
                        style={{ marginLeft: "30%", marginTop: "20%" }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={7}>
                    <Box>
                      <Box>
                        <Typography className={classes.midBoxtypohead}>
                          Engagement and Rewards
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.midBoxtyposub}>
                          We believe in the power of engagement and
                          incentivizing hard work, not only in academics but
                          also in cultivating a strong work ethic and fostering
                          a sense of community. In the Cogito Metaverse, we
                          offer a universal retainer income for individuals
                          actively engaged in growing the Metaverse.
                          Additionally, our pocket money app provides children
                          and young people with a platform that connects their
                          efforts and achievements with tangible rewards. By
                          embracing this holistic approach, we empower
                          individuals of all ages to thrive and contribute to
                          the Metaverse's development.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container direction="row">
                  <Grid item xs={5} sm={4}>
                    <Box className={classes.midBoximg}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/contents/financial-trusts.png`}
                        alt="Financial Structures and Trusts"
                        width="130"
                        style={{ marginLeft: "30%", marginTop: "15%" }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={7}>
                    <Box>
                      <Box>
                        <Typography className={classes.midBoxtypohead}>
                          Financial Structures and Trusts
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.midBoxtyposub}>
                          Within the Cogito Metaverse, we have developed
                          cutting-edge technology that allows for the
                          registration of trusts and businesses. Our vision is
                          to reach one million businesses, at which point we
                          will open the stock exchange. By securing your
                          business in the Cogito Metaverse, you gain access to a
                          safe and secure repository for private and commercial
                          operations, providing you with peace of mind and
                          greater control over your assets and tax management.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Container>
          <Grid item xs={12} sm={12}>
            <Grid container direction="row">
              <Box className={classes.last_banner}>
                <Typography className={classes.bannerText}>
                  Joining the Cogito Metaverse
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Container>
            <Grid item xs={12} sm={12}>
              <Grid container direction="column">
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography className={classes.boxmidsecondtypo}>
                      Within the Cogito Metaverse, we have developed
                      cutting-edge technology that allows for the registration
                      of trusts and businesses. Our vision is to reach one
                      million businesses, at which point we will open the stock
                      exchange. By securing your business in the Cogito
                      Metaverse, you gain access to a safe and secure repository
                      for private and commercial operations, providing you with
                      peace of mind and greater control over your assets and tax
                      management.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Grid>
    </div>
  );
};

export default Aboutus;
