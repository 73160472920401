import { createTheme } from "@material-ui/core/styles";

const headBack = "#0F2C33";
const headWhite = "#ff7300";

const themes = createTheme({
  palette: {
    common: {
      headBack: `${headBack}`,
    },
    primary: {
      main: `${headBack}`,
    },
    secondary: {
      main: `${headWhite}`,
    },
  },
  typography: {
    tab: {
      font: "Arial",
      textTransform: "uppercase",
      fontWeight: 700,
      fontSize: "0.9rem",
    },
    h6: {
      fontWeight: 400,
      color: "#ffffff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 856,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default themes;
