import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Link,
} from "@material-ui/core";
import { useParams } from "react-router";
import { setWithExpiry, getWithExpiry } from "../../assets/utility";
import ThemeButton from "../../Component/common/button";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/hero.png)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },

  regssmargin: {
    margin: "5rem 0 12rem 0",

    [theme.breakpoints.down(1140)]: {
      margin: "5rem 0 0 0",
    },
    [theme.breakpoints.down(440)]: {
      margin: "5rem 0 0 0",
    },
    [theme.breakpoints.down(350)]: {
      margin: "5rem 0 0 0",
    },
    [theme.breakpoints.down(290)]: {
      margin: "5rem 0 0 0",
    },
  },

  mobile: {
    marginTop: "2rem",
    position: "absolute",
    height: "630px",
    [theme.breakpoints.down(440)]: {
      height: "400px",
    },
    [theme.breakpoints.down(350)]: {
      height: "300px",
    },
    [theme.breakpoints.down(290)]: {
      height: "200px",
    },
  },

  grid_second: {
    height: "18rem",
    width: "100%",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    marginTop: "9rem",
  },

  mobile1: {
    height: "500px",
    // position: "absolute",

    [theme.breakpoints.down(440)]: {
      height: "350px",
    },
    [theme.breakpoints.down(350)]: {
      height: "250px",
    },
    [theme.breakpoints.down(290)]: {
      height: "150px",
    },
  },

  grid_second1: {
    position: "absolute",
    right: "0rem",
    top: "63rem",
    height: "15rem",
    width: "100vw",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    [theme.breakpoints.down(440)]: {
      top: "54rem",
    },
    [theme.breakpoints.down(350)]: {
      top: "45.5rem",
    },
    [theme.breakpoints.down(290)]: {
      top: "39rem",
    },
  },

  grid_second1_typo: {
    fontSize: "1.8rem",
    fontWeight: 500,
    fontStyle: "normal",
    [theme.breakpoints.down(440)]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.down(290)]: {
      fontSize: "1rem",
    },
  },

  mainBx: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "5rem",
  },

  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3rem 6rem 0rem 6rem",
    [theme.breakpoints.down(856)]: {
      padding: "5rem 1rem 2rem 1rem",
    },
  },

  imgbox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    padding: "0 7rem 0 7rem",
    [theme.breakpoints.down(856)]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  container3: {
    display: "flex",
    justifyContent: "space-around",
    padding: "0 2rem 0 2rem",
  },

  boxmidfirsttypo: {
    color: "black",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    textAlign: "center",
    padding: "0 7rem 3rem 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },
  boxmidfirsttypo3: {
    color: "#359DB6",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: "bolder",
    textAlign: "center",
    padding: "0 7rem 3rem 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
      textAlign: "justify",
    },
  },

  boxmidfirsttypo2: {
    color: "black",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    textAlign: "center",
    padding: "0 7rem 3rem 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
      textAlign: "justify",
    },
  },

  boxmidfirsttypo1: {
    color: "#FF7300",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    textAlign: "center",
    padding: "0 7rem 3rem 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },

  boxend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "-0.1rem",
    padding: "3rem",
    backgroundColor: "#FF7300",
  },

  boxend1typo: {
    color: "#FFFFFF",
    fontSize: "1.2rem",
    fontWeight: "400",
    textAlign: "center",
    padding: "4rem 8rem 3rem 8rem",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },

  boxend2typo: {
    fontSize: "1.2rem",
    fontWeight: "400",
    textAlign: "center",
    padding: "0 9rem 5rem 9rem",
    color: "#359db6",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },
}));

const AffiliatePage = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");

  const theme = useTheme();
  const paramRef = useParams().ref || null;
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const handleLogin = () => {
    const storedRef = getWithExpiry("ref") || null;

    if (storedRef) {
      let loginLink = `${process.env.REACT_APP_URL_LOGIN}/${storedRef}`;
      window.open(loginLink, "_blank");
    } else {
      let loginLink = `${process.env.REACT_APP_URL_LOGIN}`;
      window.open(loginLink, "_blank");
    }
  };

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (paramRef) {
      setWithExpiry("ref", paramRef, 24 * 7);
    }
  }, [paramRef]);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  const handleClickSingUp = () => {
    const storedRef = getWithExpiry("ref") || null;

    if (storedRef) {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      window.open(signupLink, "_blank");
    } else {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      window.open(signupLink, "_blank");
    }
  };

  return (
    <div className="classes.root">
      <Helmet>
        <title>Affiliate Program</title>
        <meta
          name="description"
          content="Join the Cogito Metaverse affiliate program and earn commission by promoting virtual reality products and services. Start your earning journey with us today."
        />
        <link
          rel="canonical"
          href="https://cogitometaverse.com/affiliate-program"
        />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              <Grid item xs={12} sm={12}>
                <Grid item className={classes.regssmargin}>
                  <Grid container direction="row" justifyContent="space-around">
                    <Grid item>
                      <Box style={{ marginRight: matches ? "12%" : "25rem" }}>
                        {matches ? null : (
                          <img
                            src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/mobile.png`}
                            alt="mobile"
                            className={classes.mobile}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box
                        style={{
                          marginTop: "10rem",
                          display: matches ? "flex" : null,
                          flexDirection: matches ? "column" : null,
                          paddingRight: matches ? "null" : "3%",
                          justifyContent: matches ? "center" : null,
                        }}
                      >
                        <Typography
                          style={{
                            textAlign: matches ? "center" : "right",
                            fontWeight: "bold",
                            fontSize: matches ? "1.1rem" : "2rem",
                            color: "white",
                            fontFamily: "Arial",
                            letterSpacing: matches ? 3 : 8,
                            // paddingLeft: matches ? "1rem": null,
                            // paddingRight: matches ? "2rem": null
                          }}
                        >
                          WELCOME TO THE
                          <span
                            style={{
                              color: "#ff7300",
                              fontSize: matches ? "1.2rem" : "2rem",
                              fontFamily: "Arial",
                              letterSpacing: matches ? 3 : 8,
                              paddingLeft: matches ? "0.8rem" : null,
                            }}
                          >
                            EASIEST
                          </span>
                        </Typography>
                        <Typography
                          style={{
                            textAlign: matches ? "center" : "right",
                            fontWeight: "bold",
                            fontSize: matches ? "1.2rem" : "2rem",
                            color: "white",
                            fontFamily: "Arial",
                            letterSpacing: matches ? 3 : 8,
                            paddingLeft: matches ? "1rem" : null,
                            paddingRight: matches ? "1.7rem" : null,
                          }}
                        >
                          AFFILIATE PROGRAM IN
                        </Typography>
                        <Typography
                          style={{
                            textAlign: matches ? "center" : "right",
                            fontWeight: "bold",
                            fontSize: matches ? "1.1rem" : "2rem",
                            color: "white",
                            fontFamily: "Arial",
                            letterSpacing: 3,
                            paddingBottom: matches ? "60px" : null,
                            // paddingLeft: matches ? "1rem": null
                          }}
                        >
                          THE WORLD!
                        </Typography>
                      </Box>
                    </Grid>
                    {matches ? (
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/mobile1.png`}
                        alt="mobile1"
                        className={classes.mobile1}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {matches ? (
          <Box style={{ marginTop: matches ? null : "12%" }}>
            <Grid item xs={12} sm={12}>
              <Grid container direction="column">
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography className={classes.boxmidfirsttypo3}>
                      Once you have signed up to the Cogito Metaverse, you can
                      access your affiliate QR code in the{" "}
                      <span
                        style={{
                          color: "#ff7300",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Log In{" "}
                      </span>{" "}
                      page.
                    </Typography>

                    <Typography className={classes.boxmidfirsttypo2}>
                      There is also a referral link you can email if you prefer.
                      Everytime that anyone that uses your affiliate link you
                      will earning{" "}
                      <span
                        style={{
                          color: "#359DB6",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        25 Cog
                      </span>
                      .
                    </Typography>

                    <Typography className={classes.boxmidfirsttypo2}>
                      The QR code is also available on the Cogito POS app to
                      make it easier for people to scan.
                    </Typography>

                    <Typography className={classes.boxmidfirsttypo2}>
                      All of the people that use your Cogito Metaverse Affiliate
                      Link will be visible on the{" "}
                      <span
                        style={{
                          color: "#ff7300",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Log In{" "}
                      </span>{" "}
                      page.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container direction="column">
                <Box className={classes.boxend}></Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box style={{ marginTop: matches ? null : "12%" }}>
            <Grid item xs={12} sm={12}>
              <Grid container direction="column">
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography className={classes.boxmidfirsttypo}>
                      Once you have signed up to the Cogito Metaverse, you can
                      access your affiliate QR code in the logged in area. In
                      the same area, there is also a referral link you can email
                      to your contacts if that is easier for you. For every
                      registration with your affiliate link, you will receive 25
                      Cog in your account. There will be other benefits
                      associated with the affiliate link as products are being
                      developed.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container direction="column">
                <Box className={classes.boxmid}>
                  <Grid container className={classes.container3}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/mobile1.png`}
                      alt="mobile1"
                      className={classes.mobile1}
                    />

                    <Grid>
                      <Box style={{ marginTop: matches ? "2rem" : "10rem" }}>
                        <Typography
                          // variant="h6"
                          style={{
                            textAlign: "left",
                            fontSize: "1.2rem",
                            fontFamily: "Open Sans",
                            fontWeight: "lighter",
                            paddingBottom: "2rem",
                          }}
                        >
                          Any of your contacts which have signed up to Cogito{" "}
                          <br /> with your affiliate code, will be visible to
                          you in the <br /> logged in area.
                        </Typography>
                        <Box style={{ paddingBottom: "2%" }}>
                          <ThemeButton
                            title="LOG IN"
                            width={150}
                            onClick={handleLogin}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.boxend}></Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </div>
  );
};

export default AffiliatePage;
