import Mainpage from "./Pages/Mainpage/mainPage";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import themes from "./UI/theme";
import "./App.css";
import "react-alice-carousel/lib/alice-carousel.css";
// import "react-alice-carousel/lib/scss/alice-carousel.scss";

const App = () => {
  const theme = responsiveFontSizes(themes);

  return (
    <ThemeProvider theme={theme}>
      <Mainpage />
    </ThemeProvider>
  );
};

export default App;
