import React, { useState } from "react";
import {
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
  createTheme,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { Language, MobileFriendly } from "@material-ui/icons";
import WebContent from "../Pages/Web/WebContent";
import AppContent from "../Pages/Mobile/AppContent";
import { mobileData } from "../RightContainer/mobileData";
import { webData } from "../RightContainer/webData";
import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5rem",
    textAlign: "start",
    padding: "2%",
    fontWeight: 700,
    color: "#359DB6",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  tabContainer: {
    marginLeft: "3%",
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      alignItems: "normal",
    },
  },
}));

const Sections = (props) => {
  const { selectedState } = props;
  const classes = useStyles();
  const [activetab, setActiveTab] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    setActiveTab(
      mobileData?.[selectedState?.section]?.[selectedState?.key] ? 0 : 1,
    );
  }, [selectedState]);
  return (
    <>
      {selectedState && activetab !== null ? (
        <>
          <Typography className={classes.title}>
            {selectedState?.title}
          </Typography>
          <Tabs
            value={activetab}
            onChange={(e, newValue) => {
              setActiveTab(newValue);
            }}
            className={classes.tabContainer}
          >
            {mobileData?.[selectedState?.section]?.[selectedState?.key] ? (
              <Tab
                label="Mobile"
                icon={<MobileFriendly />}
                className={classes.tabs}
                value={0}
              />
            ) : null}

            {webData?.[selectedState?.section]?.[selectedState?.key] ? (
              <Tab label="Web" icon={<Language />} value={1} />
            ) : null}
          </Tabs>
          {activetab == 1 && webData?.[selectedState?.section] ? (
            <WebContent section={selectedState} />
          ) : activetab == 0 && mobileData?.[selectedState?.section] ? (
            <AppContent section={selectedState} />
          ) : null}
        </>
      ) : null}
    </>
  );
};
export default Sections;
