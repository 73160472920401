import React from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },

  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      letterSpacing: 7,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },
  content: { paddingTop: "6rem", paddingBottom: "6rem", textAlign: "center" },
  bodyContent: {
    fontFamily: "Open Sans",
    fontSize: "1.3rem",
    fontWeight: "lighter",
    textAlign: "start",
    [theme.breakpoints.down(856)]: {
      fontSize: "1rem",
      textAlign: "start",
      fontWeight: "lighter",
    },
  },
  bodyContent1: {
    fontFamily: "Open Sans",
    fontSize: "1rem",
    textAlign: "justify",
    [theme.breakpoints.down(420)]: {
      fontSize: "1rem",
      textAlign: "justify",
      // paddingLeft: "1.2rem",
      // paddingRight: "1.2rem",
    },
  },
  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.3rem",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    textAlign: "justify",
    margin: "2% 0",
    [theme.breakpoints.down(856)]: {
      margin: "2% 0",
      textAlign: "justify",
      fontSize: "1rem",
    },
  },
  imageSubs: {
    fontFamily: "Roboto",
  },
  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0rem 8rem 2rem 8rem",
    [theme.breakpoints.down(600)]: {
      padding: "2rem 2rem 2rem 2rem",
    },
  },
  title: {
    color: "#359DB6",
    fontFamily: "Open sans",
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginTop: "3rem",
  },
}));

const EscrowPage = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();
  const escrowTextData = [
    {
      title: "The Advantages of Escrow Payments",
      description: [
        "Security stands out as one of the most significant advantages of utilising escrow payments. Another word for 'Escrow' is 'Trust.' In the context of financial transactions or agreements, the term 'trust' can be used interchangeably with 'escrow' to refer to a third-party arrangement that holds and manages funds or assets on behalf of the parties involved until certain conditions are met or the transaction is completed. By entrusting a neutral third party to hold and disburse funds, both parties involved can rest assured that their money is safeguarded throughout the transaction. This heightened sense of security is particularly crucial when dealing with substantial sums of money or high-value assets, such as real estate, vehicles, or businesses. Beyond security, escrow payments instil a high level of confidence in the entire transaction process, fostering trust between the parties.",
        "Another noteworthy benefit of escrow payments is their effectiveness in protecting against fraud. Escrow services play a pivotal role in ensuring that both parties fulfil their respective obligations before funds are released. For instance, in a real estate transaction, the buyer deposits funds into an escrow account held by a third-party escrow agent. These funds remain in the account until the transaction is successfully completed, at which point they are disbursed to the seller. This process acts as a safeguard against fraudulent transactions, as funds are released only when both parties have met their obligations.",
        "Escrow payments also offer a mechanism to resolve disputes. If any disputes arise during the course of the transaction, the escrow service can mediate and work towards a fair and impartial resolution. This proactive approach can prevent conflicts from escalating and help in finding mutually agreeable solutions.",
        "The Cogito Metaverse has remarkably streamlined the escrow process, making it exceptionally straightforward and accessible for various types of transactions, and the best part is that the associated cost is minimal. Despite the numerous advantages offered by this innovative platform, traditional escrow payments still have certain drawbacks.",
        "One notable disadvantage is the additional cost incurred for employing traditional escrow services. These services typically charge a fee, contributing to the overall expenses of the transaction. Additionally, opting for a conventional escrow service may extend the timeline of the transaction since funds are held until both parties fulfil their obligations. This extended timeframe can present challenges for buyers and sellers seeking a swift completion of their transactions. In contrast, the Cogito Metaverse has overcome these obstacles, providing a cost-effective and efficient solution for secure and reliable escrow payments.",
        "The traditional model of escrow payments has limited recourse they may offer in certain situations. While escrow services provide a significant layer of protection, there might be instances where they cannot fully shield against fraud or breaches of contract. This can be particularly concerning if the buyer or seller resides in a different country, where legal recourse might be limited. However, the Cogito Metaverse model adds a layer of security that no one else can provide and that is because all payments, including escrow go between Cogito e-residents, so we know who everyone is, which makes for easier resolution of disputes. Within this structure, we can hold any payment until disputes are resolved.",
      ],
      spanList: [],
      sub: "",
    },
    {
      title: "Examples of Escrow Payments",
      description: [
        "The prevalence of escrow payments continues to rise, and they are now widely employed in various high-value transactions. Here are some examples of how escrow payments are commonly utilized:",
      ],
      spanList: [
        {
          title: "Real estate transactions:",
          sub: "Buyers may deposit funds into an escrow account managed by a third-party escrow agent. These funds remain held until the completion of the transaction, upon which they are released to the seller.",
        },
        {
          title: "Freelance work:",
          sub: "Clients may utilize an escrow service to hold payment until a freelancer satisfactorily completes the work as agreed upon in the contract. The funds are then released to the freelancer.",
        },
        {
          title: "Reserve Value:",
          sub: "The cash reserve backing the currency serves as a safeguard against economic downturns and financial crises. In times of economic instability, the cash reserve can be utilized to support the currency's value and maintain overall financial integrity.",
        },
        {
          title: "Online purchases:",
          sub: "In some instances, online purchases are facilitated through escrow services. Buyers deposit funds into an escrow account, and the seller ships the product. The funds are subsequently released to the seller once the buyer confirms satisfaction with the received product.",
        },
        {
          title: "Mergers and acquisitions:",
          sub: "Escrow accounts are often employed in business acquisitions to hold a portion of the purchase price until specific conditions are met. This ensures that the seller fulfills all obligations before the funds are released.",
        },
        {
          title: "Intellectual property sales:",
          sub: "Escrow services may be utilized in the sale of patents or other intellectual property. Payment is held in escrow until the transfer of ownership is complete, and all necessary documents have been provided, after which the funds are released to the seller.",
        },
      ],
      sub: "Escrow payments serve as a robust and secure solution for conducting high-value transactions, providing invaluable protection against fraud, breaches of contract, and disputes. While there may be some associated costs and potential limitations, the benefits they offer in terms of security, reliability, and confidence make them a preferred choice for various industries and individuals alike.",
    },
  ];

  return (
    <Grid container>
      <Helmet>
        <title>Escrow Payments</title>
        <meta
          name="description"
          content="Stay informed on virtual reality and metaverse finance with Cogito's press kit for media professionals. Get resources on investment & services."
        />
        <link
          rel="canonical"
          href="https://cogitometaverse.com/escrowPayments"
        />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    Escrow Paymnets
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    Escrow Paymnets
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Container className={classes.content}>
        <Grid item container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Typography className={classes.bodyContent}>
              In recent years, escrow payments have gained significant
              popularity as a secure and dependable method for conducting
              high-value transactions. This rise in demand can be attributed, in
              part, to advancements in technology, particularly in the realm of
              online marketplaces and e-commerce platforms. As these platforms
              facilitate remote transactions between buyers and sellers, there
              is a growing need for payment methods that offer enhanced security
              and protection against fraud and breaches of contract. Escrow
              services have emerged to fulfil this need by providing a trusted
              intermediary, minimising risks, and ensuring smooth and reliable
              transactions. Moreover, the integration of digital payment systems
              and blockchain technology has further streamlined escrow payments,
              making them faster and more efficient than ever before.
            </Typography>
          </Grid>

          {escrowTextData.map((data, index) => (
            <Grid key={index} item xs={12}>
              <Typography
                className={classes.title}
                style={{ fontSize: matches ? "1.8rem" : "3rem" }}
              >
                {data.title}
              </Typography>
              <img
                src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/shadow.jpg`}
                alt="shadow"
                width={"100%"}
              />
              {data.description.map((e) => (
                <Box style={{ margin: "2% 0" }}>
                  <Typography className={classes.bodyContent}>{e}</Typography>
                </Box>
              ))}
              <Box>
                {data.spanList.map((item) => {
                  return (
                    <Container>
                      <Typography className={classes.boxmidsecondtypo}>
                        <span style={{ fontWeight: "normal" }}>
                          {item.title}
                        </span>{" "}
                        {item.sub}
                      </Typography>
                    </Container>
                  );
                })}
              </Box>
              <Box style={{ margin: "2% 0" }}>
                <Typography className={classes.bodyContent}>
                  {data.sub}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
};

export default EscrowPage;
