import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Link,
} from "@material-ui/core";
import ThemeButton from "../../Component/common/button";
import { getWithExpiry } from "../../assets/utility";
import memberCitizenHeader from "../../assets/member-citizen/member-citizenHeader.png";
import { Opacity } from "@material-ui/icons";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    background: `url(${memberCitizenHeader})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },

  regssmargin: {
    margin: "19rem 0 19rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontSize: 22,
    textAlign: "center",
    fontWeight: "bolder",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontFamily: "Arial",
    fontWeight: "bolder",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },

  mobile: {
    marginTop: "5rem",
    position: "absolute",
    height: "630px",
    [theme.breakpoints.down(440)]: {
      height: "400px",
    },
    [theme.breakpoints.down(350)]: {
      height: "300px",
    },
    [theme.breakpoints.down(290)]: {
      height: "200px",
    },
  },

  lastboxTypo: {
    fontfamily: "Open Sans",
    fontSize: "1.5rem",
    fontWeight: 900,
    textAlign: "center",
    [theme.breakpoints.down(656)]: {
      padding: "0 2rem 0 2rem",
    },
  },
  grid_second: {
    height: "15rem",
    width: "100%",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    marginTop: "9rem",
  },

  pointtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
  },
  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    textAlign: "left",
  },

  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem 8rem 2rem 8rem",
    [theme.breakpoints.down(700)]: {
      padding: "2rem 1rem 0rem 1rem",
    },
  },
  mobileBox: {
    color: "#359DB6",
    fontFamily: "Open Sans",
    fontSize: "1.8rem",
    fontWeight: "bold",
    // fontStyle: "normal",
    marginBottom: "2rem",
    [theme.breakpoints.down(850)]: {
      fontSize: 18,
      marginRight: "1.2rem",
    },
  },

  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    textAlign: "center",
    margin: "0rem 10rem 2rem 10rem",
    [theme.breakpoints.down(2000)]: {
      margin: "0rem 1.2rem 3rem 1.2rem",
    },
    [theme.breakpoints.down(440)]: {
      margin: "0rem 1.2rem 3rem 1.2rem",
      textAlign: "justify",
      fontSize: "1rem",
    },
  },

  boxDialoge: {
    height: "9.5rem",
    width: "9.5rem",
    borderRadius: 30,
    color: "#fff",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignContent: "center",
    margin: "auto auto auto auto",
    transitionDuration: "1s",
    transitionProperty: "background",
    "&:hover": {
      background: "rgb(68, 68, 68)",
    },
    [theme.breakpoints.down(856)]: {
      // background: "rgb(68, 68, 68)",
      // "&:hover": {
      // 	background: "rgb(68, 68, 68)",
      // },
    },
    [theme.breakpoints.down(420)]: {
      height: "5rem",
      width: "5.5rem",
      borderRadius: 20,
      margin: "auto 0 auto 0",
    },
  },
  outerBox: {
    width: "13rem",
    height: "13rem",
  },
  boxDialogetypo: {
    fontFamily: "Arial ",
    fontSize: "1.2rem",
    fontWeight: 900,
    textAlign: "center",
    [theme.breakpoints.down(856)]: {
      fontSize: "0.7rem",
    },
  },
}));

const MemberCitizenPage = () => {
  const classes = useStyles();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickSingUp = () => {
    const storedRef = getWithExpiry("ref") || null;

    if (storedRef) {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      window.open(signupLink, "_blank");
    } else {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      window.open(signupLink, "_blank");
    }
  };
  const redirectToAbout = () => {
    window.location.pathname = "/faq";
  };
  return (
    <div className="classes.root">
      <Helmet>
        <title>E-Residency</title>
        <meta
          name="description"
          content="Join the global community of e-residents and entrepreneurs at Cogito Metaverse. Learn about the benefits of e-Residency and start your digital journey today."
        />
        <link rel="canonical" href="https://cogitometaverse.com/about" />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              {/* 1st part */}
              <Grid item xs={12} sm={12}>
                {matches ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBoxmob}>E-RESIDENCY</Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBox}>E-RESIDENCY</Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box style={{ marginTop: "6%", marginBottom: "3%" }}>
          {/* 2ndpart */}

          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box className={classes.boxmid}>
                <Box>
                  <Typography
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      // fontStyle: "normal",
                      fontFamily: "Open Sans",
                      textAlign: matches ? "justify" : "center",
                      color: "#359db6",
                      paddingBottom: "5%",
                      paddingLeft: matches ? "7%" : "2%",
                      paddingRight: matches ? "7%" : "2%",
                      letterSpacing: matches ? 0 : 2,
                    }}
                  >
                    The Cogito Metaverse gives global citizens a whole new way
                    of looking at the world.
                  </Typography>
                </Box>
                {matches ? (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        //  justifyContent:"space-between",
                        padding: "0rem 1rem 1rem 0",
                      }}
                    >
                      <ThemeButton
                        title="REGISTER"
                        size={14}
                        onClick={handleClickSingUp}
                        width={130}
                        margin={20}
                      />

                      <ThemeButton
                        title="LEARN MORE"
                        size={14}
                        margin="2% 0 2% 2%"
                        width={135}
                        onClick={redirectToAbout}
                      />
                    </Box>
                  </>
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "0rem 0 4rem 0rem",
                    }}
                  >
                    <ThemeButton
                      title="REGISTER"
                      size={16}
                      onClick={handleClickSingUp}
                      width={200}
                    />

                    <ThemeButton
                      title="LEARN MORE"
                      size={15}
                      margin="0 0 0 4%"
                      width={200}
                      onClick={redirectToAbout}
                    />
                  </Box>
                )}

                <Box>
                  <Typography className={classes.boxmidsecondtypo}>
                    The Cogito Metaverse gives global citizens the ability to
                    become e-residents and therefore the opportunity to work
                    together to build wealth in a new way. The Cogito Metaverse
                    is unique in that it is borderless, working entirely in a
                    digital space. This digital space uses advanced technology
                    to connect people with goods and services. It also enables
                    individuals (e-residents) to build wealth and plan for their
                    own financial future.
                  </Typography>
                </Box>
                <Box direction="column" className={classes.pointtext}>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Centralized Digital Currency called “Cog”
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Ability to buy and sell goods and services with Cog
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Easy to use mobile app for financial transactions
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Become financially literate and begin investing
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                      height={35}
                      alt="tick"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Free to join affiliate program
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* 3rd part */}
          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box paddingY={4}>
                <Typography className={classes.lastboxTypo}>
                  Do you want to find out more about registration?
                </Typography>
              </Box>
              <Box alignSelf="center" paddingY={2}>
                <Link href="/registration" style={{ textDecoration: "none" }}>
                  <ThemeButton title="CLICK HERE" width={200} />
                </Link>
              </Box>
              <Box paddingY={4}>
                <Typography className={classes.lastboxTypo}>
                  Learn more about what it means to be an e-resident?
                </Typography>
              </Box>
              <Box alignSelf="center" paddingY={2}>
                <Link style={{ textDecoration: "none" }}>
                  <ThemeButton title="CLICK HERE" width={200} />
                </Link>
              </Box>
              {matches ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    padding: "2rem 0rem 2rem 0rem",
                    width: "100%",
                  }}
                >
                  <Box
                    className={classes.boxDialoge}
                    style={{ marginBottom: "2rem", marginLeft: "1.5rem" }}
                    onClick={handleClickSingUp}
                  >
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/staticdesign_icon/setting.svg`}
                      height={28}
                      alt="register"
                      style={{
                        marginBottom: 8,
                      }}
                    />
                    <Typography className={classes.boxDialogetypo}>
                      Register
                    </Typography>
                  </Box>

                  <Box
                    className={classes.boxDialoge}
                    style={{ marginBottom: "2rem" }}
                    onClick={() => (window.location.pathname = "/cog")}
                  >
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/staticdesign_icon/cog.svg`}
                      height={12}
                      alt="cog"
                      style={{
                        // paddingBottom:2,
                        marginBottom: 8,
                      }}
                    />
                    <Typography className={classes.boxDialogetypo}>
                      The COG
                    </Typography>
                  </Box>
                  <Box
                    className={classes.boxDialoge}
                    style={{ marginBottom: "2rem", marginRight: "1.5rem" }}
                    onClick={() => (window.location.pathname = "/about")}
                  >
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/staticdesign_icon/coglogo.svg`}
                      height={28}
                      alt="about"
                      style={{
                        marginBottom: 8,
                      }}
                    />
                    <Typography className={classes.boxDialogetypo}>
                      About
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "center",
                    alignItems: "center",
                    padding: "5rem 4rem 2rem 4rem",
                    justifyContent: "space-around",
                    // width: '80%'
                  }}
                >
                  <Box className={classes.outerBox}>
                    <Box
                      className={classes.boxDialoge}
                      onClick={handleClickSingUp}
                    >
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/staticdesign_icon/setting.svg`}
                        height={50}
                        alt="register"
                        style={{
                          marginBottom: 8,
                        }}
                      />
                      <Typography className={classes.boxDialogetypo}>
                        Register
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.outerBox}>
                    <Box
                      className={classes.boxDialoge}
                      onClick={() => (window.location.pathname = "/cog")}
                    >
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/staticdesign_icon/cog.svg`}
                        height={28}
                        alt="cog"
                        style={{
                          marginBottom: 8,
                        }}
                      />
                      <Typography className={classes.boxDialogetypo}>
                        The COG
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.outerBox}>
                    <Box
                      className={classes.boxDialoge}
                      onClick={() => (window.location.pathname = "/about")}
                    >
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/staticdesign_icon/coglogo.svg`}
                        height={50}
                        alt="about"
                        style={{
                          marginBottom: 8,
                        }}
                      />
                      <Typography className={classes.boxDialogetypo}>
                        About
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default MemberCitizenPage;
