import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import ThemeButton from "../../Component/common/button";
import { Link } from "react-router-dom";
import Carousel from "./Carousel";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/hero.png)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },

  regssmargin: {
    margin: "12rem 0 5rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontWeight: "bold",
    fontSize: 22,
    textAlign: "center",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },
  regBox2: {
    fontSize: 34,
    fontFamily: "Open Sans",
    fontWeight: 900,
    color: "#fff",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 2,
    // padding: " 0rem 4rem 5rem 4rem",
  },

  grid_second: {
    display: "flex",
    flexDirection: "column",
    height: "25rem",
    width: "100%",
    backgroundColor: "#231f20",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    marginTop: "9rem",
  },

  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    textAlign: "left",
  },

  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "3rem",
    marginBottom: "3rem",
    [theme.breakpoints.down(700)]: {
      marginTop: "3rem",
    },
  },

  mobileBox: {
    color: "#359DB6",
    fontSize: 25,
    fontWeight: 700,
    fontStyle: "normal",
    marginBottom: "2rem",
  },

  boxmidfirsttypo: {
    color: "#4a4a4a",
    fontSize: "1.2rem",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    padding: "4rem 7rem 5rem 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "2rem 2rem 2rem 2rem",
      textAlign: "justify",
      fontSize: "1rem",
    },
  },

  // boxmidsecondtypo: {
  //   color: "#231F20",
  //   fontSize: "1rem",
  //   fontWeight: "lighter",
  //   fontFamily: "Open Sans",
  //   fontStyle: "normal",
  //   textAlign: "center",
  //   margin: "0rem 10rem 2rem 10rem",
  //   [theme.breakpoints.down("md")]: {
  //     margin: "0rem 1rem 2rem 1rem",
  //     fontSize: "0.8",
  //     textAlign: "justify",
  //   },
  // },

  mobboxDialoge: {
    height: "6.5rem",
    width: "6.5rem",
    borderRadius: 30,
    color: "#fff",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    [theme.breakpoints.down("420")]: {
      borderRadius: 20,
      height: "5rem",
      width: "5.5rem",
    },
  },
  boxDialoge: {
    height: "13rem",
    width: "13rem",
    borderRadius: 30,
    fontFamily: "Open Sans ",
    fontSize: "0.9rem",
    fontWeight: 900,
    color: "#fff",
    textAlign: "center",
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "0  0 2rem",
    background: "#231220",
    boxShadow: "1px 1.5px 1.5px #9E9E9E",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    boxShadow: "3px 3px 3px 3px #85827d",
    transitionProperty: "opacity",
    transitionDuration: "0.5s",

    "&:hover": {
      opacity: "0.9",
    },
  },

  boxDialogeLinks: {
    height: "4.5rem",
    width: "12.5rem",
    borderRadius: 8,
    fontFamily: "Open Sans ",
    fontWeight: 900,
    color: "#359db6",
    fontSize: "0.9rem",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "row",
    // padding: "0 5% 0 8% ",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto  auto auto auto",
    transitionProperty: "background",
    transitionDuration: "0.5s",
    [theme.breakpoints.up(400)]: {
      "&:hover": {
        background: "rgb(68, 68, 68)",
      },
    },
  },

  mobDialogeLinks: {
    height: "4.5rem",
    width: "10.5rem",
    borderRadius: 8,
    fontFamily: "Open Sans ",
    fontWeight: 900,
    color: "#359db6",
    fontSize: "0.9rem",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto auto auto auto",
    transitionProperty: "height,width",
    transitionDuration: "0.5s",
    [theme.breakpoints.down(450)]: {
      height: "3rem",
      width: "9rem",
    },
  },
  outerBox: {
    width: "16rem",
    height: "7rem",
    fontSize: "0.5rem",
  },

  boxDialogetypo: {
    fontFamily: "Open Sans ",
    fontSize: "0.9rem",
    fontWeight: 900,
    color: "#359db6",
    textAlign: "Left",
    paddingTop: 4,
    paddingLeft: 3,
    [theme.breakpoints.up(400)]: {
      "&:hover": {
        fontSize: "1.2rem",
      },
    },
  },
  boxDialogetypo1: {
    fontFamily: "Open Sans ",
    fontSize: "0.9rem",
    fontWeight: 900,
    color: "#fff",
    textAlign: "center",
    paddingTop: 4,
    paddingLeft: 3,
    [theme.breakpoints.down(420)]: {
      fontSize: "0.7rem",
    },
  },
  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    textAlign: "justify",
    padding: "4rem 7rem 0 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "2rem 2rem 2rem 2rem",
      textAlign: "justify",
      fontSize: "1rem",
    },
  },
  last_banner: {
    height: "12rem",
    width: "100%",
    backgroundColor: "#FF7300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    marginTop: "1rem",
  },

  carouselInne: {
    // width: "100%",
    height: "auto",

    // [theme.breakpoints.down(400)]: {
    // height: "500px",
    // width: "55%",
    // },

    // [theme.breakpoints.down(350)]: {
    // height: "20px",
    // width: "100%",
    // },

    // [theme.breakpoints.down(290)]: {
    // height: "15px",
    //  /* height: 200px, */
    // },
    // width: "inherit",
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // backgroundSize:" cover",
    // display: "flex",
  },
}));

const Banking = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");
  const [showDescriptionOf, setShowDescriptionOf] = useState("");

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  const redirectToPersonal = () => {
    window.location.pathname = "/personal-banking";
  };
  const redirectToBusiness = () => {
    window.location.pathname = "/business";
  };
  const redirectToFaq = () => {
    window.location.pathname = "/faq";
  };
  const redirectToRegistration = () => {
    window.location.pathname = "/registration";
  };

  return (
    <div className="classes.root">
      <Helmet>
        <title>Banking</title>
        <meta
          name="description"
          content="Discover virtual reality banking and financial services in the metaverse at Cogito Metaverse. Learn about the future of finance and investment in VR."
        />
        <link rel="canonical" href="https://cogitometaverse.com/banking" />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              {/* 1st part */}
              <Grid item xs={12} sm={12}>
                {matches ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBoxmob}>BANKING</Box>
                      <Box
                        style={{
                          fontSize: "1.2rem",
                          fontFamily: "Open Sans",
                          fontWeight: 300,
                          textAlign: "center",
                          color: "#fff",
                        }}
                      >
                        The Cogito Metaverse is a whole <br />
                        new way of{" "}
                        <span style={{ color: "#ff7300" }}>
                          organising finance
                        </span>
                        .
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // alignItems: 'center',
                          justifyContent: "center",
                          padding: "7rem 0 0 0",
                        }}
                      >
                        <ThemeButton
                          title="PERSONAL"
                          size={12}
                          onClick={redirectToPersonal}
                          width={145}
                        />
                        <ThemeButton
                          title="BUSINESS"
                          size={12}
                          margin=" 0 0 0 4%"
                          width={145}
                          onClick={redirectToBusiness}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Grid item className={classes.regssmargin}>
                      <Box>
                        <Box className={classes.regBox}>BANKING</Box>
                        <Box className={classes.regBox2}>
                          The Cogito Metaverse is a whole new way of <br />
                          <span style={{ color: "#ff7300" }}>
                            organising finance.
                          </span>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // alignItems: 'center',
                          justifyContent: "center",
                          padding: "7rem 0 0 0",
                        }}
                      >
                        <ThemeButton
                          title="PERSONAL"
                          size={16}
                          onClick={redirectToPersonal}
                          width={200}
                        />
                        <ThemeButton
                          title="BUSINESS"
                          size={15}
                          margin="0 0 0 4%"
                          width={200}
                          onClick={redirectToBusiness}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box style={{ marginBottom: matches ? "0%" : "8%" }}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box className={classes.boxmid}>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      The Cogito Metaverse is an advanced digital space where
                      users can engage and interact in a virtual world. It
                      brings together intelligent individuals who aim to grow
                      investments, build businesses, and enhance the lives of
                      ordinary people.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      Within the Cogito Metaverse, members have the opportunity
                      to support each other, engage in free trade and finance,
                      and benefit from financial education. The platform offers
                      avenues for investment and wealth-building that differ
                      from traditional banks and investment companies. Users can
                      establish companies and asset protection trusts within the
                      Cogito Metaverse to safeguard their wealth and explore
                      innovative paths to prosperity.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      One notable aspect of the Cogito Metaverse is its global
                      accessibility, welcoming individuals from all countries.
                      It breaks down traditional barriers and fosters a sense of
                      inclusivity. The platform is continuously evolving, with
                      ongoing construction and development. Stay informed about
                      the latest updates and advancements by following the
                      Cogito Metaverse's social media channels
                    </Typography>
                  </Container>
                </Box>
              </Box>
              <Grid item xs={12} sm={12}>
                <Grid container direction="row">
                  <Box className={classes.last_banner}>
                    <Typography
                      style={{
                        fontSize: "3rem",
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontStyle: "normal",
                        textAlign: "center",
                      }}
                    >
                      Introducing Cogito Currencies
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <Container>
                  <Typography className={classes.boxmidfirsttypo}>
                    Welcome to the Cogito Metaverse, where we are reshaping the
                    future by embracing innovation, inclusivity, and the
                    transformative potential of digital technology. Together, we
                    can unlock new possibilities and create a world where
                    financial freedom and educational empowerment know no
                    bounds.
                  </Typography>
                </Container>
              </Box>
              <Grid
                container
                direction="column"
                style={{
                  background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/contents/banking_poster.png)`,
                  height: matches ? "40vh" : "90vh",
                  backgroundSize: "100%",
                  // backgroundPosition: "",
                  backgroundRepeat: "initial",
                  marginBottom: matches ? "" : "5%",
                }}
              ></Grid>
              <Box>
                <Typography
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#359db6",
                    paddingBottom: "1.9%",
                    fontFamily: "Open Sans",
                    [theme.breakpoints.down(856)]: {
                      fontSize: "0.6rem",
                    },
                  }}
                >
                  Joining the Cogito Metaverse Banking
                </Typography>
              </Box>
              <Box>
                <Container>
                  <Typography className={classes.boxmidfirsttypo}>
                    Joining the Cogito Metaverse Banking is as simple as
                    becoming a member of our digital community. Through our
                    website, you can register and gain access to our mobile app
                    or computer based web page, which enables you to create a
                    personal Cog account. This account empowers you to send and
                    receive Cogito Cog, providing you with the flexibility and
                    convenience of traditional banking systems. As we continue
                    to evolve, the Cogito Metaverse Stock Market will soon be
                    available, offering exciting opportunities for investment
                    and growth.
                  </Typography>
                </Container>
              </Box>
              <Box>
                <Typography
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#359db6",
                    paddingBottom: "1.9%",
                    fontFamily: "Open Sans",
                    [theme.breakpoints.down(856)]: {
                      fontSize: "0.6rem",
                    },
                  }}
                >
                  Security and Transparency
                </Typography>
              </Box>
              <Box>
                <Container>
                  <Typography className={classes.boxmidfirsttypo}>
                    Ensuring the security of your financial transactions is of
                    paramount importance to us. In a closed economy, every
                    transaction is able to be audited, we know who the person is
                    on both ends of the transaction. Because the currency cannot
                    be moved outside of the metaverse to for example, a bank,
                    the security is in the accountability and transparency of
                    all the parties involved. There is no ability to steal Cog
                    as it were from the Metaverse, and if you did, the Metaverse
                    knows who you are. Our systems and processes are as robust
                    as you would expect out of any financial institution.
                  </Typography>
                </Container>
              </Box>
              <Box>
                <Typography
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#359db6",
                    paddingBottom: "1.9%",
                    fontFamily: "Open Sans",
                    [theme.breakpoints.down(856)]: {
                      fontSize: "0.6rem",
                    },
                  }}
                >
                  Digital Citizenship and Trusts
                </Typography>
              </Box>
              <Box>
                <Container>
                  <Typography className={classes.boxmidfirsttypo}>
                    Joining the Cogito Metaverse Banking is as simple as
                    becoming a member of our digital community. Through our
                    website, you can register and gain access to our mobile app
                    or computer based web page, which enables you to create a
                    personal Cog account. This account empowers you to send and
                    receive Cogito Cog, providing you with the flexibility and
                    convenience of traditional banking systems. As we continue
                    to evolve, the Cogito Metaverse Stock Market will soon be
                    available, offering exciting opportunities for investment
                    and growth.
                  </Typography>
                </Container>
              </Box>
              <Box>
                <Typography
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#359db6",
                    paddingBottom: "1.9%",
                    fontFamily: "Open Sans",
                    [theme.breakpoints.down(856)]: {
                      fontSize: "0.6rem",
                    },
                  }}
                >
                  Cogito in Everyday Life
                </Typography>
              </Box>
              <Box>
                <Container>
                  <Typography className={classes.boxmidfirsttypo}>
                    The Cogito currency is a legal tender within the Cogito
                    Metaverse, enabling you to utilise it for everyday
                    transactions. As our app becomes more widely available, you
                    can seamlessly integrate Cog into your daily financial
                    activities, transforming the way you engage with the digital
                    world.
                  </Typography>
                </Container>
              </Box>
              <Box>
                <Typography
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#359db6",
                    paddingBottom: "1.9%",
                    fontFamily: "Open Sans",
                    [theme.breakpoints.down(856)]: {
                      fontSize: "0.6rem",
                    },
                  }}
                >
                  Trading and Money Laundering
                </Typography>
              </Box>
              <Box>
                <Container>
                  <Typography className={classes.boxmidfirsttypo}>
                    Joining the Cogito Metaverse Banking is as simple as
                    becoming a member of our digital community. Through our
                    website, you can register and gain access to our mobile app
                    or computer based web page, which enables you to create a
                    personal Cog account. This account empowers you to send and
                    receive Cogito Cog, providing you with the flexibility and
                    convenience of traditional banking systems. As we continue
                    to evolve, the Cogito Metaverse Stock Market will soon be
                    available, offering exciting opportunities for investment
                    and growth.
                  </Typography>
                </Container>
              </Box>
              <Box>
                <Typography
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#359db6",
                    paddingBottom: "1.9%",
                    fontFamily: "Open Sans",
                    [theme.breakpoints.down(856)]: {
                      fontSize: "0.6rem",
                    },
                  }}
                >
                  Embrace the Future of Banking in the Cogito Metaverse
                </Typography>
              </Box>
              <Box>
                <Container>
                  <Typography className={classes.boxmidfirsttypo}>
                    Joining the Cogito Metaverse Banking is as simple as
                    becoming a member of our digital community. Through our
                    website, you can register and gain access to our mobile app
                    or computer based web page, which enables you to create a
                    personal Cog account. This account empowers you to send and
                    receive Cogito Cog, providing you with the flexibility and
                    convenience of traditional banking systems. As we continue
                    to evolve, the Cogito Metaverse Stock Market will soon be
                    available, offering exciting opportunities for investment
                    and growth.
                  </Typography>
                </Container>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default Banking;
