import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ThemeButton from "../../../Component/common/button";
import ChangePageTitle from "../../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";
// import KCarousel from "./Carousel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontSize: 22,
    textAlign: "center",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 3,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 3,
  },
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    textAlign: "center",
    [theme.breakpoints.down(450)]: {
      fontSize: 22,
      letterSpacing: 12,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },
  grid_second: {
    display: "flex",
    flexDirection: "column",
    height: "38rem",
    width: "100%",
    backgroundColor: "#231f20",
    justifyContent: "space-around",
    alignItems: "center",
    color: "#359db6",
    paddingTop: "5rem",
  },
  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",
    // marginBottom: "5rem",
    [theme.breakpoints.down(700)]: {
      marginTop: "3rem",
    },
  },

  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontWeight: "lighter",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    textAlign: "center",
    margin: "0rem 10rem 2rem 10rem",
    [theme.breakpoints.down(2000)]: {
      margin: "0rem 2rem 2rem 2rem",
      fontSize: "1rem",
      textAlign: "justify",
    },
  },
}));

const CompanyOffice = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");
  const [showDescriptionOf, setShowDescriptionOf] = useState("");

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };
  const redirectToBusinessDirectory = () => {
    window.location.pathname = "/business-directory";
  };
  const redirectToRegistration = () => {
    window.location.pathname = "/registration";
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  return (
    <div className="classes.root">
      <Helmet>
        <title>Company Officie</title>
        <meta
          name="description"
          content="Establish your virtual reality and metaverse presence with Cogito Metaverse company offices. Explore virtual office spaces for VR businesses."
        />
        <link
          rel="canonical"
          href="https://cogitometaverse.com/company-office"
        />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              {/* 1st part */}
              <Grid item xs={12} sm={12}>
                {matches ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item container className={classes.header}>
                      <Grid item xs={12}>
                        <Typography className={classes.headerName}>
                          COMPANY OFFICE
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Link to="/business" style={{ textDecoration: "none" }}>
                          <Typography
                            variant="body1"
                            className={classes.headerbackLink}
                          >
                            Back to Business Page
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Grid item container className={classes.header}>
                      <Grid item xs={12}>
                        <Typography className={classes.headerName}>
                          COMPANY OFFICE
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Link to="/business" style={{ textDecoration: "none" }}>
                          <Typography
                            variant="body1"
                            className={classes.headerbackLink}
                          >
                            Back to Business Page
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box style={{ marginTop: "6%", marginBottom: "6%" }}>
          {/* 1stpart */}
          <Grid item xs={12} sm={12}>
            <Container>
              <Grid container direction="column">
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography className={classes.boxmidsecondtypo}>
                      All company records are kept in the secure area of the
                      metaverse and are private and confidential. Anyone who
                      accesses the secure area, is unable to search directors,
                      shareholders or company details.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      Existing companies can choose whether to register using
                      their Certificate of Incorporation from the jurisdiction
                      of their location or can choose to register within the
                      Cogito Metaverse.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      There will be a Business Directory which is a list of
                      businesses registered within the Cogito Metaverse and
                      which accept payment in Cog for their goods and services.
                      This will be a searchable list by company name, category
                      or location.
                    </Typography>
                  </Box>

                  {matches ? (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        style={{
                          paddingBottom: "2rem",
                        }}
                      >
                        <ThemeButton
                          title="BUSINESS DIRECTORY"
                          height={40}
                          width={250}
                          onClick={redirectToBusinessDirectory}
                        />
                      </Box>

                      <ThemeButton
                        title="REGISTRATION PAGE"
                        height={40}
                        width={250}
                        onClick={redirectToRegistration}
                      />
                    </Box>
                  ) : (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        // width: '50%',
                        padding: "2rem 16rem 2rem 16rem",
                      }}
                    >
                      <ThemeButton
                        title="BUSINESS DIRECTORY"
                        height={40}
                        width={250}
                        onClick={redirectToBusinessDirectory}
                      />

                      <ThemeButton
                        title="REGISTRATION PAGE"
                        height={40}
                        width={250}
                        margin=" 0 0 0 4%"
                        onClick={redirectToRegistration}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Container>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default CompanyOffice;
