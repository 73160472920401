const pressData = [
  {
    title: "What is the Cogito Metaverse?",
    data: [
      "The Cogito Metaverse is a digital space where the future of finance is being created. Forget your off shore Islands, the Cogito Metaverse is run by the Principality of Cogito making it the original digital safe haven. The Cogito Metaverse has its own digital currency, the Cog; which can be used to buy and sell goods and services in real time in day to day life. The Cogito Metaverse is also developing a stockmarket and investment framework for the future.",
    ],
    point: [],
  },
  {
    title: "Who created Cogito?",
    data: [
      "The Principality of Cogito was established as a decentralized libertarian state. It now stands as one of the last safe havens that has not been controlled via large virtual government sanctions.",
      "Principality of Cogito was first developed in the early 1990’s by a reclusive dyslexic entrepreneur. The mastermind documentation around the technology and concept was locked away for many years until it was stumbled upon in early 2017.",
    ],
    point: [],
  },
  {
    title: "Who controls the Cogito Metaverse?",
    data: [
      "The Cogito Metaverse is managed by a highly skilled and competent board who are based in different countries around the world. Their backgrounds range from law, accounting, IT, governance, risk management, and customer service systems. In simple terms, the Cogito Metaverse is divided into eight regions which each have an elected board in democratic process by the Digital Citizens.",
    ],
    point: [],
  },

  {
    title: "",
    data: [],
    point: [
      "Companies Register",
      // "Sell goods and services and earn Cog",
      // "Global network of members",
      // "Innovative investment opportunities",
      // "Business Directory",
      // "Stock Market",
    ],
  },
];

export default pressData;

// last
{
  /*  */
}

// <Box
//     style={{
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "space-around",
//         // width: '50%',
//         padding: "2rem 16rem 2rem 16rem",
//     }}
// >
//     <ThemeButton
//         title="DOWNLOAD"
//         height={40}
//         width={250}
//         onClick={() =>
//             window.open(
//                 `${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/Press%20Kit%20for%20PDF%20Export.pdf`,
//                 "_blank"
//             )
//         }
//     />

//     <ThemeButton
//         title="BACK TO CONTACT"
//         height={40}
//         width={250}
//         onClick={redirectToContact}
//     />
// </Box>

{
  /* <Box>
    <Box direction="column" className={classes.pointtext}>
        <Box direction="column" className={classes.boxpointtext}>
            <img
                src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                height={35}
                style={{
                    paddingRight: "2rem",
                }}
            />
            <Typography className={classes.mobileBox}>
                Companies Register
            </Typography>
        </Box>
        <Box direction="column" className={classes.boxpointtext}>
            <img
                src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                height={35}
                style={{ paddingRight: "2rem" }}
            />
            <Typography className={classes.mobileBox}>
                Sell goods and services and earn Cog
            </Typography>
        </Box>
        <Box direction="column" className={classes.boxpointtext}>
            <img
                src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                height={35}
                style={{ paddingRight: "2rem" }}
            />
            <Typography className={classes.mobileBox}>
                Global network of members
            </Typography>
        </Box>
        <Box direction="column" className={classes.boxpointtext}>
            <img
                src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                height={35}
                style={{ paddingRight: "2rem" }}
            />
            <Typography className={classes.mobileBox}>
                Innovative investment opportunities
            </Typography>
        </Box>
        <Box direction="column" className={classes.boxpointtext}>
            <img
                src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                height={35}
                style={{ paddingRight: "2rem" }}
            />
            <Typography className={classes.mobileBox}>
                Business Directory
            </Typography>
        </Box>
        <Box direction="column" className={classes.boxpointtext}>
            <img
                src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                height={35}
                style={{ paddingRight: "2rem" }}
            />
            <Typography className={classes.mobileBox}>
                Stock Market
            </Typography>
        </Box>
    </Box>
</Box> */
}
