import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import cogData from "./data";
import ThemeButton from "../../Component/common/button";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },

  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      letterSpacing: 7,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },
  content: { paddingTop: "6rem", paddingBottom: "6rem", textAlign: "center" },
  bodyContent: {
    fontFamily: "Open Sans",
    fontSize: "1.2rem",
    // textAlign: "justify",
    [theme.breakpoints.down(420)]: {
      fontSize: "1rem",
      textAlign: "justify",
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
  },
  bodyContent1: {
    fontFamily: "Open Sans",
    fontSize: "1.2rem",
    textAlign: "justify",
    [theme.breakpoints.down(420)]: {
      fontSize: "1rem",
      textAlign: "justify",
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
  },
  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    textAlign: "justify",
    padding: "2rem 0 2rem 0",
    [theme.breakpoints.down(856)]: {
      padding: "2rem 2rem 2rem 2rem",
      textAlign: "justify",
      fontSize: "1rem",
    },
  },
  imageSubs: {
    fontFamily: "Roboto",
  },
  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0rem 8rem 2rem 8rem",
    [theme.breakpoints.down(600)]: {
      padding: "2rem 2rem 2rem 2rem",
    },
  },
  title: {
    color: "#359DB6",
    fontFamily: "Open sans",
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginTop: "3rem",
  },
}));

const Cog = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  const redirectToBusiness = () => {
    window.location.pathname = "/business";
  };
  return (
    <Grid container>
      <Helmet>
        <title>Cog</title>
        <meta
          name="description"
          content="Invest in the future of virtual reality with COG, the metaverse token at Cogito Metaverse. Discover the benefits of owning a digital asset in the metaverse."
        />
        <link rel="canonical" href=" https://cogitometaverse.com/cog " />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>COG</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>COG</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Container className={classes.content}>
        <Grid item container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Typography className={classes.bodyContent}>
              The Cogito Metaverse introduces its digital currencies, the Cog
              and the Cash Backed Cog. The Cog serves as a digital fiat currency
              for everyday transactions, including buying, selling, and
              investing. The Cog is seamlessly integrated with the euro,
              providing continuous monitoring of its value and stability.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bodyContent}>
              The Cog offers convenience and accessibility to both individuals
              and businesses. Transactions can be effortlessly initiated through
              the Cog bank app on your smartphone or via your Internet banking
              account. Payments can be made between phones, from phone to Cog
              bank, or between Cog banks. Each transaction is swiftly
              authenticated using a PIN and our advanced ALDS technology.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bodyContent}>
              When conducting a transaction, the Cog bank automatically converts
              the amount from your local currency to the corresponding value in
              Cog, based on the prevailing exchange rate for the day.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/contents/cog-coin.png`}
              height="150"
              style={{
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                marginLeft: -25,
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={5} justifyContent="center">
          {cogData.listData.map((item) => {
            return (
              <>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {item.title}
                  </Typography>
                </Grid>
                {item.data.map((para) => {
                  return (
                    <Grid item xs={12}>
                      <Typography className={classes.bodyContent}>
                        {para}
                      </Typography>
                    </Grid>
                  );
                })}
              </>
            );
          })}
        </Grid>

        <Grid item container spacing={5} justifyContent="center">
          <>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                CBC - Cash Backed Cog
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.bodyContent1}>
                Cash-backed currency is the second currency that Cogito offers.
                This is where a currency's value is directly linked to and
                backed by a specific reserve of physical assets, typically held
                in a central bank or a secure financial institution. The primary
                purpose of cash-backed currency is to instil confidence and
                stability in the currency's value, as it is tied to tangible
                assets that can be redeemed upon demand.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bodyContent1}>
                Here's what cash-backed currency does:
              </Typography>
            </Grid>
            <Box>
              {cogData.spanList.map((item) => {
                return (
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ fontWeight: "normal" }}>{item.title}</span>{" "}
                      {item.sub}
                    </Typography>
                  </Container>
                );
              })}
            </Box>
          </>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Cog;
