import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Button,
  Divider,
  List,
} from "@material-ui/core";
import axios from "axios";
import { dateToWords } from "../../utility";
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
import Spinner from "../../Component/common/spinner/spinner";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    fontSize: "2rem",
    color: "#359DB6",
    fontWeight: 600,
    fontFamily: "Open Sans",
    [theme.breakpoints.down(820)]: {
      paddingTop: "4rem",
    },
  },
  newsletterBlock: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #000",
    padding: 12,
  },

  title: {
    fontSize: "1rem",
    color: "#000",
    fontWeight: 600,
    fontFamily: "open sans",
  },
  secondRow: {
    padding: "2%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  readmore: {
    fontSize: 12,
    color: "#FF7300",
    fontWeight: 600,
    fontFamily: "Arial",
  },
  date: {
    fontSize: 12,
    color: "#000",
    fontWeight: 500,
    fontFamily: "Arial",
  },
  NewsletterBox: {
    marginBottom: 10,
    marginTop: "6%",
    display: "flex",
    // overflow: "ellipsis",
    // alignItems: "flex-end",
  },
}));

const Newsletter = (props) => {
  const classes = useStyles();
  const [newsletter, setNewsletter] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pagination, setPagination] = useState({
    count: 0,
    active: 1,
    limit: 14,
  });

  useEffect(() => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_LINK}/admin/getInsightNewsLetters`, {
        // limit: pagination.limit,
        // skip: (pagination.active - 1) * pagination.limit,
        hideDetail: true,
      })
      .then((res) => {
        // console.log("Res: ", res);
        setLoading(false);
        if (res?.data?.data?.length) {
          setNewsletter(res?.data?.data);
          // pagination.count != res.count &&
          // setPagination((data) => ({
          // ...data,
          // count: res?.data?.count || 0,
          // }));
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const newsletterJsx = (data, index) => (
    <Grid
      item
      xs={12}
      sm={12}
      style={{
        backgroundColor: index % 2 === 0 ? "#D9D9D9" : "#fff",
      }}
    >
      <Grid item>
        <Box className={classes.secondRow}>
          <a
            href={`/insight/newsletter/${data._id}`}
            target="_blank"
            style={{ textDecoration: "unset" }}
          >
            {/* <p className={classes.readmore}>Read More</p> */}
            <p className={classes.title}>{`${dateToWords(data.sent_at)}`}</p>
          </a>
        </Box>
      </Grid>
    </Grid>
  );
  const handleChange = (e, v) => {
    setPagination((data) => ({ ...data, active: v }));
  };
  return (
    <>
      {!loading ? (
        <>
          <Box className={classes.container}>
            <Grid container spacing={2} className={classes.NewsletterBox}>
              {newsletter.slice(0, 12).map((data, index) => {
                return newsletterJsx(data, index);
              })}
            </Grid>
          </Box>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};
export default Newsletter;
