import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import pressData from "./pressdata";
import ThemeButton from "../../Component/common/button";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  pointtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    // padding: '0 0rem 0 10rem'
  },
  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    textAlign: "left",
  },
  mobileBox: {
    color: "#359DB6",
    fontSize: 18,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    marginBottom: "2rem",
  },
  webBox: {
    color: "#359DB6",
    fontFamily: "Open Sans",
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: "2rem",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 23,
      fontWeight: "bold",
      letterSpacing: 5,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },
  content: { paddingTop: "6rem", paddingBottom: "6rem", textAlign: "center" },
  bodyContent: {
    fontFamily: "Open Sans",
    fontSize: "1.2rem",
    [theme.breakpoints.down(420)]: {
      textAlign: "justify",
      fontSize: "1rem",
      padding: "0 1.2rem 0 1.2rem",
    },
  },
  imageSubs: {
    fontFamily: "Roboto",
  },
  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0rem 8rem 2rem 8rem",
    [theme.breakpoints.down(600)]: {
      padding: "0rem 2rem 2rem 2rem",
    },
  },
  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontWeight: "lighter",
    fontFamily: "Open Sans",
    // fontStyle: "normal",
    textAlign: "justify",
    // margin: "0rem 5rem 2rem 5rem",
    [theme.breakpoints.down("md")]: {
      margin: "0rem 2rem 2rem 2rem",
      fontSize: "1rem",
    },
  },
  title: {
    color: "#FF7300",
    fontFamily: "Open sans",
    fontSize: "1.7rem",
    fontWeight: "600",
    marginTop: "1rem",

    [theme.breakpoints.down("md")]: {
      marginTop: "0rem",
      fontSize: "1.3rem",
    },
  },
  points: {
    color: "#359DB6",
    fontSize: 25,
    fontWeight: 700,
    fontStyle: "normal",
  },
}));

const Cog = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const redirectToContact = () => {
    window.location.pathname = "/contact";
  };
  return (
    <Grid container>
      <Helmet>
        <title>Press kit</title>
        <meta
          name="description"
          content="Stay informed on virtual reality and metaverse finance with Cogito's press kit for media professionals. Get resources on investment & services."
        />
        <link rel="canonical" href="https://cogitometaverse.com/presskit" />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    PRESS KIT
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/contact" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Contact Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    PRESS KIT
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/contact" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Contact Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Container className={classes.content}>
        <Grid item container spacing={5} justifyContent="center">
          {pressData.map((item) => {
            return (
              <>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {item.title}
                  </Typography>
                </Grid>
                {item.data.map((para) => {
                  return (
                    <Grid item xs={12}>
                      <Typography className={classes.bodyContent}>
                        {para}
                      </Typography>
                    </Grid>
                  );
                })}
              </>
            );
          })}
          {matches ? (
            <Box className={classes.boxmid}>
              <Box direction="column" className={classes.pointtext}>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{
                      paddingRight: "2rem",
                    }}
                  />

                  <Typography className={classes.mobileBox}>
                    Companies Register
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{ paddingRight: "2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Sell goods and services and earn Cog
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{ paddingRight: "2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Global network of members
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{ paddingRight: "2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Innovative investment opportunities
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{ paddingRight: "2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Business Directory
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{ paddingRight: "2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Stock Market
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "3rem",
                  // alignItems: "center",
                }}
              >
                <Box
                  style={{
                    paddingBottom: "2rem",
                    paddingRight: "0.5rem",
                  }}
                >
                  <ThemeButton
                    title="DOWNLOAD"
                    height={40}
                    width={135}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/Press%20Kit%20for%20PDF%20Export.pdf`,
                        "_blank",
                      )
                    }
                  />
                </Box>

                <ThemeButton
                  title="BACK TO CONTACT"
                  fontSize="1rem"
                  height={40}
                  width={180}
                  onClick={redirectToContact}
                />
              </Box>
            </Box>
          ) : (
            <Box className={classes.boxmid}>
              <Box direction="column" className={classes.pointtext}>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{
                      paddingRight: "2rem",
                    }}
                  />

                  <Typography className={classes.webBox}>
                    Companies Register
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{ paddingRight: "2rem" }}
                  />
                  <Typography className={classes.webBox}>
                    Sell goods and services and earn Cog
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{ paddingRight: "2rem" }}
                  />
                  <Typography className={classes.webBox}>
                    Global network of members
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{ paddingRight: "2rem" }}
                  />
                  <Typography className={classes.webBox}>
                    Innovative investment opportunities
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{ paddingRight: "2rem" }}
                  />
                  <Typography className={classes.webBox}>
                    Business Directory
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                    alt="tick"
                    height={30}
                    style={{ paddingRight: "2rem" }}
                  />
                  <Typography className={classes.webBox}>
                    Stock Market
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  width: "150%",
                  padding: "2rem 8rem 2rem 8rem",
                }}
              >
                <ThemeButton
                  title="DOWNLOAD"
                  height={40}
                  width={250}
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/Press%20Kit%20for%20PDF%20Export.pdf`,
                      "_blank",
                    )
                  }
                />

                <ThemeButton
                  title="BACK TO CONTACT"
                  height={40}
                  width={250}
                  // margin=" 0  0 0 16px"
                  onClick={redirectToContact}
                />
              </Box>
            </Box>
          )}
          <Box>
            <Typography className={classes.boxmidsecondtypo}>
              Want to write about us, access hi-res photos, or learn more? We
              would love to hear from you. Please email us at{" "}
              <span style={{ color: "#359DB6", fontWeight: 400 }}>
                content@principalityofcogito.com{" "}
              </span>{" "}
              to get in touch!
            </Typography>
          </Box>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Cog;
