import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Button,
  TextField,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Divider,
  List,
  ListItem,
  ListItemText,
  ButtonBase,
} from "@material-ui/core";
import InsightsBG from "../../assets/Insight/insight.png";
import BlogList from "./bloglist";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { dateToWords } from "../../utility";
import Newsletter from "./Newsletter";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { SearchRounded } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import banner from "../../assets/Insight/Banner2.svg";
import Spinner from "../../Component/common/spinner/spinner.js";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ThemeButton from "../../Component/common/button";
import { getWithExpiry, setWithExpiry } from "../../assets/utility";
import SmallSpinner from "../../Component/common/spinner/smallSpinner";
import SearchResults from "./search_results";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    height: "auto",
    background: `url(${InsightsBG})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  regssmargin: {
    margin: "19rem 0 19rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontWeight: "bold",
    fontSize: 22,
    textAlign: "center",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },

  header2: {
    display: "flex",
    justifyContent: "center",
    fontSize: "2rem",
    color: "#359DB6",
    fontWeight: "bold",
    fontFamily: "Open Sans",
    [theme.breakpoints.down("750")]: {
      paddingTop: "3rem",
    },
  },

  bodyText: {
    lineHeight: "25px",
    height: "calc(10*15px)",
    overflow: "hidden",
    position: "relative",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    color: "#231F20",
    marginBottom: "1rem",
    fontSize: "1.3rem",
    "&::after": {
      content: `""`,
      position: "absolute",
      bottom: "0",
      right: "0",
      height: "21px",
      width: "75%",
      background: "linear-gradient(90deg,transparent,white)",
    },
  },
  date: {
    fontSize: "0.8rem",
    color: "#8F8D8D",
    fontStyle: "italic",
    fontWeight: 300,
    fontFamily: "Open Sans",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "8% 0%",
    },
  },
  firstContainer: {
    margin: "2% 4%",
  },
  searchBox: {
    backgroundColor: "#fff",
    borderRadius: 14,
    // margin: "1% 2%",
    "& input": {
      borderRadius: 20,
    },
    [`& fieldset`]: {
      borderRadius: 14,
    },
  },
  FeaturedAnnouncement: {
    background: "#F1F1F1",
    marginTop: "4%",
    height: "85vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  searchResults: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginLeft: "2%",
    // padding: "2%",
    width: "96%",
    background: "#fff",
    boxShadow: "0px 4.23895px 12.7169px 4.23895px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
  },
  listItem: {
    color: "#359DB6",
  },
  resultBox: {
    border: "1px solid #359DB6",
    borderRadius: "10px",
    margin: "2%",
    height: "130px",
    padding: "15px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const Insight = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");
  const [announcements, setAnnouncements] = useState([]);

  const [announcementsByKeyword, setAnnouncementsByKeyword] = useState([]);
  const [recentSearcResults, setRecentSearchResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [announcementsArchived, setAnnouncementsArchived] = useState([]);
  const [loading, setLoading] = useState(false);
  const [featuredAnn, setFeaturedAnn] = useState(null);
  const [announcementsObj, setAnnouncementsObj] = useState();
  const [apicalled, setApicalled] = useState(false);
  const [selectedAnn, setSelectedAnn] = useState(null);
  const [pagination, setPagination] = useState({
    count: 0,
    active: 1,
    limit: 3,
  });
  const [searchPage, setSearchPage] = useState(false);
  const theme = useTheme();
  const paramId = useParams().id || false;
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [searchedQuery, setSearchedQuery] = useState([]);
  const [fieldFullwidth, setFieldFullwidth] = useState(false);
  const [keyword, setKeyword] = useState("");
  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };

  // if param has ID open detailed page of insight

  useEffect(() => {
    if (paramId) {
      fetchPostById(paramId);
    }
  }, [paramId]);

  //Fetching Live announcements
  useEffect(() => {
    fetchPost(false, 1, 4);
  }, []);

  //Fetching archived announcements

  useEffect(() => {
    fetchPost(false, pagination.active, pagination.limit);
  }, [pagination.active]);

  //Setting featured announcement

  useEffect(() => {
    if (announcements && announcements.length > 0 && !featuredAnn) {
      setFeaturedAnn(announcements[0]);
    }
  }, [announcements]);

  // creating an object of announcements with id as key
  useEffect(() => {
    let liveAnn = announcements;
    let archivedAnn = announcementsArchived;
    let allAnn = liveAnn?.concat(archivedAnn);
    let annObjArr = {};
    allAnn?.map((item) => {
      annObjArr[item.id] = {
        pic: item.pic,
        title: item.title,
        body: item.body,
        createdAt: item.created_at,
        author: item.author_name,
      };
    });

    setAnnouncementsObj(annObjArr);
  }, [announcements, featuredAnn, announcementsArchived]);

  const fetchPost = async (isArchived, page, limit) => {
    setLoading(true);
    const res = axios
      .post(`${process.env.REACT_APP_API_LINK}/admin/GetAnnouncements`, {
        archived: isArchived,
        page: page,
        limit: page > 1 ? 3 : 4,
        skip: page > 1 ? 1 : 0,
      })

      .then(function (response) {
        if (isArchived && response.data.data.announcements != null) {
          setAnnouncementsArchived(response.data.data.announcements);
        } else {
          let ann = [
            ...(page == 1 ? [] : [""]),
            ...(response.data.data.announcements || []),
          ];
          setAnnouncements(ann);
          pagination.count != response.data.data.total_count &&
            setPagination((data) => ({
              ...data,
              count: response.data?.data?.total_count || 0,
            }));
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const fetchPostById = async (id) => {
    setLoading(true);
    const res = axios
      .post(`${process.env.REACT_APP_API_LINK}/admin/GetAnnouncement`, {
        id,
      })

      .then(function (response) {
        const { status, errorMessage, data } = response;
        if (status && data.status) {
          setSelectedAnn(data?.announcements?.[0]);
          setSearchPage(false);
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedAnn]);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  const handleChange = (e, v) => {
    setPagination((data) => ({ ...data, active: v }));
  };

  const handleClickSingUp = () => {
    const storedRef = getWithExpiry("ref") || null;
    if (storedRef) {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      window.open(signupLink, "_blank");
    } else {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      window.open(signupLink, "_blank");
    }
  };

  const handleSearchChange = (value) => {
    setKeyword(value);
  };
  useEffect(() => {
    if (announcementsByKeyword) {
      if (apicalled) {
        setSearchPage(true);
        setSelectedAnn(null);
      } else {
        setSearchPage(false);
      }
    } else if (announcementsByKeyword == null) {
      if (apicalled) {
        setSearchPage(false);
        setRecentSearchResults([]);
        setSearchResults([]);
      }
    }
  }, [announcementsByKeyword]);

  const SearchAnnouncements = (keywordList, isArchived) => {
    setApicalled(true);
    setLoading(true);
    const res = axios
      .post(
        `${process.env.REACT_APP_API_LINK}/admin/GetAnnouncementsByKeyword`,
        {
          keyword: keywordList,
          archived: isArchived,
        },
      )
      .then((response) => {
        let responseAnnByKey = response?.data?.announcements;
        setAnnouncementsByKeyword(responseAnnByKey);
        setLoading(false);
        let allAnnouncemnets = [...responseAnnByKey];
        if (responseAnnByKey.length !== 0 || responseAnnByKey.length >= 3) {
          setRecentSearchResults(responseAnnByKey.slice(0, 3));
        }
        setSearchResults(allAnnouncemnets.slice(3, allAnnouncemnets.length));
        if (response?.data?.announcements == null) {
          setRecentSearchResults([]);
          setSearchResults([]);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (keyword) {
      let keywordList = keyword.split(" ")?.filter((words) => words.length > 2);
      if (keywordList.length >= 1) {
        const timeInterval = setTimeout(() => {
          SearchAnnouncements(keywordList, false);
        }, 1000);
        return () => clearTimeout(timeInterval);
      } else {
        setSearchPage(false);
        setAnnouncementsByKeyword([]);
        setSearchResults([]);
        setRecentSearchResults([]);
        setApicalled(false);
      }
    }
  }, [keyword]);

  const handleBackButton = () => {
    setKeyword("");
    setSearchPage(false);
    setApicalled(false);
    setAnnouncementsByKeyword([]);
    setSelectedAnn(null);
  };

  return (
    <div className="classes.root">
      <Helmet>
        <title>Insights</title>
        <meta
          name="description"
          content="Discover valuable insights and latest trends in the Metaverse with Cogito. Stay ahead of the curve with our expert analysis and commentary."
        />
        <link rel="canonical" href="https://cogitometaverse.com/insights" />
      </Helmet>

      <Grid container>
        {/* {console.log('hi')} */}
        <Grid item xs={12} sm={12}>
          <Box className={classes.grid_first}>
            {/* 1st part */}
            {matches ? (
              <Grid item container alignItems="center">
                <Grid item className={classes.regssmargin}>
                  <Typography className={classes.regBoxmob}>
                    INSIGHTS
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid item container direction="column">
                <Grid item className={classes.regssmargin}>
                  <Box className={classes.regBox}>INSIGHTS</Box>
                </Grid>
              </Grid>
            )}
            <FormControl fullWidth={fieldFullwidth || keyword}>
              <TextField
                variant="outlined"
                margin="normal"
                name="searchedQuery"
                type="text"
                placeholder="Search..."
                id="search"
                className={classes.searchBox}
                value={keyword}
                onFocus={() => {
                  setFieldFullwidth(true);
                }}
                onBlur={() => {
                  setFieldFullwidth(false);
                }}
                onChange={(e) => handleSearchChange(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={classes.icons}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  ...(loading && keyword.length < 3
                    ? {
                        endAdornment: <SmallSpinner />,
                      }
                    : {}),
                }}
              />
            </FormControl>
            {/* {keyword != "" && !searchPage ? (
              <Grid className={classes.searchResults}>
                {announcementsByKeyword?.slice(0, 4)?.map((insight, i) => {
                  return (
                    <>
                      <List sx={{ width: "100%", maxWidth: 360 }}>
                        <ListItem button className={classes.listItem}>
                          <ListItemText primary={insight.title} />
                        </ListItem>
                        <Divider />
                      </List>
                    </>
                  );
                })}
                {announcementsByKeyword?.length > 3 ? (
                  <Button
                    style={{
                      color: "#848484",
                      // marginBottom: "20px",
                      width: "50%",
                      textAlign: "left",
                    }}
                    onClick={handleSearch}
                  >
                    see all results
                  </Button>
                ) : null}
              </Grid>
            ) : null} */}
          </Box>
        </Grid>

        {!selectedAnn && !searchPage && announcementsByKeyword != null ? (
          <Grid item container className={classes.firstContainer}>
            <Grid item xs={12} sm={7}>
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "2% 0%",
                    padding: matches ? "3% 0% 0% 0%" : "0%",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "2rem",
                      color: "#359DB6",
                      fontWeight: "bold",
                    }}
                  >
                    Latest Insight/Blog
                  </Typography>
                </Grid>

                {!loading ? (
                  <>
                    <Grid
                      item
                      style={{
                        padding: "0%",
                        flexDirection: "column",
                        borderRadius: "20px",
                        boxShadow:
                          "0px 4.23895px 12.7169px 4.23895px rgba(0, 0, 0, 0.25)",
                        heigt: "100%",
                      }}
                    >
                      {/* image */}
                      <Grid
                        item
                        style={{ maxHeight: "47vh", overflow: "hidden" }}
                      >
                        <img
                          width="100%"
                          src={`${
                            process.env.REACT_APP_ENV === "prod"
                              ? process.env.REACT_APP_SPACE_KEY_PROD
                              : process.env.REACT_APP_SPACE_KEY
                          }/${featuredAnn?.pic}`}
                          style={{ borderRadius: "20px 20px 0px 0px" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} style={{ padding: "4%" }}>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h2
                            style={{
                              paddingBottom: "0.3rem",
                              fontFamily: "Open Sans",
                              color: "#382823",
                              fontStyle: "normal",
                              fontWeight: 700,
                            }}
                          >
                            {featuredAnn?.title}
                          </h2>
                          <Box
                            style={{
                              backgroundColor: "#D9D9D9",
                              padding: "1% 4%",
                              borderRadius: "50px",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "Open Sans",
                                color: "#000",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: 15,
                                textAlign: "center",
                              }}
                            >
                              Featured/Announcement
                            </p>
                          </Box>
                        </Grid>
                        <h4
                          className={classes.bodyText}
                          dangerouslySetInnerHTML={{
                            __html: featuredAnn?.body,
                          }}
                        ></h4>
                        {/* read more button */}
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            id={featuredAnn?.id}
                            to={`/insight/${featuredAnn?.id}`}
                            style={{
                              textDecoration: "underline",
                              fontFamily: "Open Sans",
                              color: "#FF7300",
                              fontStyle: "normal",
                              fontWeight: 600,
                              cursor: "pointer",
                              fontSize: "1.5rem",
                            }}
                          >
                            Read More
                          </Link>
                          <h6
                            className={classes.date}
                            style={{
                              paddingLeft: "2rem",
                            }}
                          >
                            {featuredAnn
                              ? dateToWords(featuredAnn?.created_at)
                              : null}
                          </h6>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Spinner />
                )}
              </>
            </Grid>

            <Grid
              item
              xs={12}
              md={5}
              sm={5}
              style={{
                flexDirection: "column",
                padding: "1% 4%",
              }}
            >
              <Grid item>
                <p className={classes.header2}>Newsletters</p>
              </Grid>
              <Newsletter />
            </Grid>
          </Grid>
        ) : null}
        {!selectedAnn && !searchPage && announcementsByKeyword != null ? (
          <>
            <Grid
              item
              container
              className={classes.FeaturedAnnouncement}
              style={{}}
            >
              <Grid
                item
                xs={12}
                style={{
                  margin: "2% 5% 2% 8%",
                  background: "#F1F1F1",
                }}
              >
                <Typography
                  style={{
                    margin: matches ? "2% 1% 4%" : "2% 1%",
                    fontSize: "2rem",
                    color: "#359DB6",
                    fontWeight: "bold",
                    textAlign: matches ? "center" : "left",
                  }}
                >
                  More Blogs
                </Typography>
                <BlogList blogs={announcements} location={"Insights"} />

                <Grid item xs={12} className={classes.paginationContainer}>
                  {loading ? (
                    <SmallSpinner />
                  ) : (
                    <Pagination
                      page={pagination.active}
                      onChange={handleChange}
                      count={Math.ceil(
                        (pagination.count - 1) / pagination.limit,
                      )}
                      shape="rounded"
                      color="secondary"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Container>
              <Grid item container justifyContent="center">
                {/* <Grid item xs={8}> */}
                <Box onClick={handleClickSingUp} style={{ margin: "4% 0%" }}>
                  <img src={banner} width="100%" />
                </Box>
                {/* </Grid> */}
              </Grid>
            </Container>
          </>
        ) : null}
        {selectedAnn && announcementsByKeyword != null ? (
          <Container>
            {!loading ? (
              <>
                <Grid
                  container
                  style={{
                    padding: matches ? "5% 5%" : "5% 20%",
                    // paddingLeft: "20%",
                    // paddingRight: "20%",
                    // paddingTop: "5%",
                    // paddingBottom: "5%",
                  }}
                  spacing={4}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: "center",
                      height: "auto",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      width="100%"
                      src={`${
                        process.env.REACT_APP_ENV === "prod"
                          ? process.env.REACT_APP_SPACE_KEY_PROD
                          : process.env.REACT_APP_SPACE_KEY
                      }/${selectedAnn?.pic}`}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <p
                      style={{
                        fontFamily: "Open Sans",
                        color: "#382823",
                        fontWeight: 600,
                        fontSize: matches ? "2rem" : "2.5rem",
                      }}
                    >
                      {selectedAnn?.title}
                    </p>
                  </Grid>
                  <Grid item xs={5} style={{ textAlign: "right" }}>
                    <p className={classes.date} style={{ fontSize: "1rem" }}>
                      {selectedAnn?.author ? selectedAnn?.author : "Cogito"}
                    </p>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <p
                      className={classes.date}
                      style={{ fontSize: "1rem", fontStyle: "normal" }}
                    >
                      |
                    </p>
                  </Grid>
                  <Grid item xs={5}>
                    <p className={classes.date} style={{ fontSize: "1rem" }}>
                      {dateToWords(selectedAnn?.created_at)}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: selectedAnn?.body,
                      }}
                      style={{
                        lineHeight: "30px",
                        paddingTop: "5%",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        color: "#231F20",
                        marginBottom: "1rem",
                        fontSize: "1.3rem",
                      }}
                    ></p>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Link to="/insights" style={{ textDecoration: "none" }}>
                      <Button
                        variant="small"
                        style={{
                          backgroundColor: "#ff7300",
                          color: "white",
                          borderRadius: "10%",
                        }}
                        onClick={() => setSelectedAnn(null)}
                      >
                        Back
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Spinner />
            )}
          </Container>
        ) : null}
        {searchPage ? (
          <Container>
            {!loading ? (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "2%",
                    textAlign: "right",
                    color: "#359DB6",
                  }}
                >
                  <ButtonBase onClick={handleBackButton}>
                    Back to Insights Main Page{" "}
                  </ButtonBase>
                </Grid>
                {/* : ( */}
                <>
                  <Grid container>
                    <Grid item xs={12} style={{ color: "#359DB6" }}>
                      <Typography>
                        {recentSearcResults?.length != 0
                          ? ` Results from latest Insights/Blogs`
                          : null}
                      </Typography>
                    </Grid>
                    <BlogList
                      blogs={recentSearcResults}
                      location={`Search Page`}
                    />
                  </Grid>

                  <Grid>
                    <Typography style={{ color: "#359DB6" }}>
                      {searchResults?.length == 0 ? null : ` Search Results`}
                    </Typography>
                  </Grid>
                  <SearchResults results={searchResults} loading={loading} />
                </>
                {/* )} */}
              </>
            ) : (
              <Spinner />
            )}
          </Container>
        ) : null}

        {announcementsByKeyword == null ? (
          <>
            <Grid
              item
              xs={12}
              style={{
                margin: "2%",
                textAlign: "right",
                color: "#359DB6",
              }}
            >
              <ButtonBase onClick={handleBackButton}>
                Back to Insights Main Page{" "}
              </ButtonBase>
            </Grid>
            <Grid
              xs={12}
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                color: "#359DB6",
                margin: "4% 3%",
                textAlign: "center",
              }}
            >
              <Typography>No Results Found</Typography>
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  );
};

export default Insight;
