import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Button } from "@material-ui/core";
import * as AiIcons from "react-icons/ai";
import styled from "styled-components";
import "./Navbar.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles, withStyles } from "@material-ui/core/styles";
import { getWithExpiry, setWithExpiry } from "../../assets/utility";
import LogoDark from "../../assets/header/logo_dark.svg";
import ThemeButton from "../common/button";
import Navbar from "../Navbar";
import { menuItems } from "./menuItems";
import SubMenu from "./SubMenu";

const Nav = styled.div`
 background: #ff7300;
 height: 80px;
 display: flex;
 justify-content: flex-start;
 align-items: center;
`;

const NavIcon = styled(Link)`
 margin-left: 2rem;
 font-size: 2rem;
 height: 80px;
 display: flex;
 justify-content: flex-start;
 align-items: center;
`;

const SidebarNav = styled.nav`
 background: #0f2c33;
 width: 280px;
 height: 100vh;
 display: flex;
 fontstyle: Arial;
 justify-content: center;
 position: fixed;
 top: 0;
 right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
 transition: 300ms;
 z-index: 55;
 overflow-y: scroll;
`;

const SidebarWrap = styled.div`
 width: 100%;
`;

const Sidebar = () => {
 const [sidebar, setSidebar] = useState(false);
 const showSidebar = () => setSidebar(!sidebar);
 const [navbar, setNavbar] = useState(false);

 const handleLogin = () => {
  const storedRef = getWithExpiry("ref") || null;

  if (storedRef) {
   let loginLink = `${process.env.REACT_APP_URL_LOGIN}/${storedRef}`;
   window.open(loginLink, "_blank");
  } else {
   let loginLink = `${process.env.REACT_APP_URL_LOGIN}`;
   window.open(loginLink, "_blank");
  }
 };
 const changeBackground = () => {
  if (window.scrollY >= 80) {
   setNavbar(true);
  } else {
   setNavbar(false);
  }
 };
 window.addEventListener("scroll", changeBackground);
 const handleClickSingUp = () => {
  const storedRef = getWithExpiry("ref") || null;

  if (storedRef) {
   let signupLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
   window.open(signupLink, "_blank");
  } else {
   let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
   window.open(signupLink, "_blank");
  }
 };
 const theme = useTheme();
 const matches = useMediaQuery(theme.breakpoints.down("md"));

 return (
  <>
   <nav
    className={navbar ? "navbar active" : "navbar"}
    style={{ height: matches ? "11vh" : "11vh" }}
   >
    <Link to="/" className="navbar-logo">
     <img src={LogoDark} alt="Company logo" height={matches ? 55 : 75} />
    </Link>

    {matches ? (
     <Grid
      item
      // xs={2.4}
      style={{
       display: "flex",
       flexDirection: "row",
       // paddingLeft: "9%",
      }}
     >
      <ThemeButton
       background="#359DB6"
       title="LOGIN"
       id="login"
       size={12}
       height={27}
       // width={100}
       radius={11}
       style={{ paddingTop: "6px" }}
       onClick={handleLogin}
      />

      <ThemeButton
       background="#FF7300"
       title="REGISTER"
       id="register"
       size={12}
       height={27}
       // width={100}
       radius={11}
       onClick={handleClickSingUp}
       margin="0 0 0 10px"
      />

      <Button
       style={{
        backgroundColor: "transparent",
        color: "white",
        // maxWidth: "20%",
        padding: "3px 8px 3px 1px",
       }}
       onClick={showSidebar}
       aria-controls="menu"
       aria-haspopup="true"
      >
       <img
        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/menuIcon.png`}
        style={{ maxWidth: "40%" }}
       />
      </Button>
      <SidebarNav sidebar={sidebar}>
       <SidebarWrap>
        <Box
         onClick={showSidebar}
         style={{
          padding: "2rem 0  2rem 2rem ",
          fontSize: "1.2rem",
          color: "#359DB",
          cursor: "pointer",
         }}
        >
         ✖
        </Box>
        {menuItems.map((item, index) => {
         return (
          <SubMenu
           item={item}
           key={index}
           onClick={showSidebar}
           showSidebar={showSidebar}
          />
         );
        })}
       </SidebarWrap>
      </SidebarNav>
     </Grid>
    ) : (
     <Box
      style={{
       display: "flex",
       flexDirection: "row",
       paddingRight: "32px",
       marginLeft: "70px",
      }}
     >
      <Navbar />
      <ThemeButton
       background="#359DB6"
       title="LOGIN"
       id="login"
       size={14}
       width={110}
       radius={12}
       onClick={handleLogin}
       margin="0 30px 0 5px"
      />
      <ThemeButton
       background="#FF7300"
       title="REGISTER"
       size={14}
       width={110}
       radius={12}
       onClick={handleClickSingUp}
       margin="0 5px 5px 2px"
      />
     </Box>
    )}
   </nav>
  </>
 );
};

export default Sidebar;
