import React from "react";
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Quote from "../../assets/quote-image/quote1.png";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      letterSpacing: 7,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
    fontFamily: "Open Sans",
  },
  content: { paddingTop: "6rem", paddingBottom: "6rem", textAlign: "center" },
  title: {
    fontFamily: "Open Sans",
    fontSize: "1.5rem",
    color: "#359DB6",
    fontWeight: "bold",
  },
  bodyContent: {
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    fontSize: "1.2rem",
    textAlign: "left",
    paddingTop: "3%",
    [theme.breakpoints.down(420)]: {
      textAlign: "justify",
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
  },
  orangeBox: {
    // borderWidth: 2,
    // borderColor: "#FF7300",
    borderRadius: "35px",
    marginTop: "3rem",
    border: "2px solid  #FF7300",
    // backgroundImage: "url(/assets/quote-image/quote.png)",
    // width: "40px",
    // height: "32px",
  },
  orangeBoxText: {
    fontFamily: "Open Sans",
    fontSize: "1.3rem",
    fontStyle: "italic",
    // color: "white",
    padding: "2rem",
    [theme.breakpoints.down(420)]: {
      fontSize: "1.1rem",
    },
  },
  container: {
    position: "relative",
    borderRadius: "35px",
    marginTop: "3rem",
    border: "2px solid  #FF7300",
    width: "88%",
    height: "150%",
  },

  label: {
    position: "absolute",
    top: "-62px",
    left: "53px",
    textAlign: "center",
  },
}));

const UniversalIncome = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  return (
    <Grid container>
      <Helmet>
        <title>Universal-income</title>
        <meta
          name="description"
          content="Join the future of finance with universal income at Cogito Metaverse. Learn about financial services in the virtual reality and metaverse economy."
        />
        <link
          rel="canonical"
          href=" https://cogitometaverse.com/universal-income "
        />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    UNIVERSAL RETAINER
                    <br /> INCOME
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    UNIVERSAL RETAINER INCOME
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Container className={classes.content}>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12}>
            <Typography className={classes.title}>
              The Universal Retainer Income (URI) in the Cogito Metaverse:
              <br /> name Enhancing Participation and Growth
            </Typography>
            <Typography className={classes.bodyContent}>
              In the realm of the Cogito Metaverse, a unique income provision
              called the Universal Retainer Income (URI) exists, aimed at all
              e-residents who engage in working in the Metaverse. Unlike the
              traditional Universal Basic Income (UBI) concept, URI adapts and
              evolves, granting residents a minimum income that increases over
              time based on their active involvement and contributions to the
              development and expansion of the Cogito Metaverse.
            </Typography>
            <Typography className={classes.bodyContent}>
              The URI acts as a powerful incentive, motivating individuals to
              actively contribute to the Cogito Metaverse. This virtuous cycle
              of growth and development strengthens the community, propelling it
              towards further advancements and prosperity.
            </Typography>
            <Typography className={classes.bodyContent}>
              <span style={{ fontWeight: "bold" }}>
                Participating in growing a business offers numerous advantages
                for individuals involved.
              </span>
            </Typography>
            <Typography className={classes.bodyContent}>
              Firstly, it presents exceptional financial opportunities. As the
              business expands and succeeds, participants create wealth and
              improve the financial well-being of those involved. Furthermore,
              participants have the chance to learn new skills and build on
              existing knowledge to grow a network of like minded individuals to
              work with.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        direction="column"
        style={{
          background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/contents/uri-poster.png)`,
          height: matches ? "40vh" : "80vh",
          backgroundSize: "100%",
          // backgroundPosition: "",
          backgroundRepeat: "initial",
        }}
      ></Grid>
      <Container className={classes.content}>
        <Grid justifyContent="center">
          <Grid item xs={12}>
            <Typography className={classes.bodyContent}>
              Secondly, engaging in growing a business fosters personal
              fulfilment. Participants in the Cogito Metaverse URI program will
              have the opportunity to develop a strong sense of ownership and
              pride in their work as they witness their contributions directly
              impacting the success and expansion of the Cogito Metaverse. Being
              part of this program enables individuals to gain valuable
              experience in strategic planning, marketing, finance, operations,
              and leadership, providing them with an exceptional opportunity for
              personal and professional growth.
            </Typography>
            <Typography className={classes.bodyContent}>
              The Cogito Metaverse URI program harnesses power of collaboration
              and networking among like-minded individuals and businesses. By
              working together, participants can enhance their professional and
              personal growth, as well as enjoy a unique experience of
              contributing to the growth of a digital space with limitless
              potential. This program offers a platform for individuals to
              connect, exchange ideas, and collaborate with others who share
              their vision and goals. Through these connections, participants
              can expand their professional network, access new opportunities,
              and find mentorship and support in their journey of growing the
              Cogito Metaverse.
            </Typography>
            <Typography className={classes.bodyContent}>
              In summary, participating in the Cogito Metaverse URI program not
              only fosters personal fulfilment through a sense of ownership and
              impact, but also offers a platform for networking and
              collaboration. By joining forces with like-minded individuals and
              businesses, participants can enhance their professional and
              personal growth, while contributing to the development of a
              digital space that holds vast possibilities for everyone involved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default UniversalIncome;
