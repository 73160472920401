import React from "react";
import {
  Container,
  Box,
  Grid,
  Link,
  useTheme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FbIcon from "../../../assets/FooterItem/fb.svg";
import InstaIcon from "../../../assets/FooterItem/insta.svg";
import TwitterIcon from "../../../assets/FooterItem/twt.svg";
import LinkedIcon from "../../../assets/FooterItem/linked.svg";
import AppStore from "../../../assets/FooterItem/AppStore.svg";
import GooglePlay from "../../../assets/FooterItem/GooglePlay.svg";

const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles((theme) => ({
    downloadIcon: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: "3%",
      justifyContent: "center",
    },
    downIcon: {
      display: "flex",
      flexDirection: "column",
      paddingTop: 6,
    },

    alginText: {
      textAlign: "center",
    },
    headText: {
      fontFamily: "Open sans",
      fontWeight: "bolder",
      fontSize: 16,
      fontStyle: "normal",
    },
    subText: {
      fontFamily: "Open Sans",
      fontSize: 16,
    },
    paddingDown: {
      paddingBottom: 40,
      fontFamily: "Open Sans",
    },
    AppLinks: {
      width: matches ? "116px" : "150px",
    },
    LinkTitle: {
      textAlign: "left",
      fontWeight: "bold",
      padding: " 3%",
    },
    appLinksDiv: {
      alignItems: "center",
      justifyContent: "space-between",
    },
    AppsLinkGrid: {
      // textAlign: matches ? "left" : "right",
      display: "flex",
      flexDirection: "column",
      padding: "14px",
      alignItems: "end",
    },
    PageLinksCOntainer: {
      alignItems: "flex-start",
      padding: matches ? "5% 5% 0%" : "5% 0% 0%",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();
  return (
    <Box
      px={{ xs: 3, sm: 8 }}
      py={{ xs: 3, sm: 8 }}
      bgcolor="#E2E2E2"
      style={{
        overflow: "clip",
        padding: matches ? "40px" : "10px 20px 10px 100px",
      }}
    >
      <Grid
        container
        spacing={2}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ textAlign: matches ? "center" : "left" }}
        >
          <Link
            target="_blank"
            href="https://www.facebook.com/cogitometaverse/"
          >
            <img
              // src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/socialMediaIcons/Facebook%20Icon.png`}
              src={FbIcon}
              style={{
                width: "55px",
                paddingLeft: "1.5%",
              }}
            />
          </Link>
          <Link target="_blank" href="https://www.instagram.com/cogito/">
            <img
              // src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/socialMediaIcons/Instagram%20Icon.png`}
              src={InstaIcon}
              style={{
                width: "55px",
                paddingLeft: "1.5%",
              }}
            />
          </Link>
          <Link target="_blank" href="https://twitter.com/CogitoMetaverse">
            <img
              // src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/socialMediaIcons/Twitter%20Icon.png`}
              src={TwitterIcon}
              style={{
                width: "55px",
                paddingLeft: "1.5%",
              }}
            />
          </Link>
          <Link
            target="_blank"
            href="https://www.linkedin.com/company/principality-cogito/"
          >
            <img
              // src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/socialMediaIcons/LinkedIn%20Icon.png`}
              src={LinkedIcon}
              style={{
                width: "55px",
                paddingLeft: "1.5%",
              }}
            />
          </Link>
        </Grid>
        <Grid item xs={12} md={5} className={classes.AppsLinkGrid}>
          <div className={classes.appLinksDiv}>
            <Typography className={classes.LinkTitle}>CM app Links:</Typography>
            <div style={{ alignItems: "right" }}>
              <Link
                target="_blank"
                href="https://apps.apple.com/in/app/cogitometaverse/id1644162232"
              >
                <img
                  // src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/footerimages/appstore.png`}
                  src={AppStore}
                  className={classes.AppLinks}
                />
              </Link>
              <Link
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.cogito"
              >
                <img
                  // src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/footerimages/playstore.png`}
                  src={GooglePlay}
                  style={{
                    paddingLeft: "2%",
                  }}
                  className={classes.AppLinks}
                />
              </Link>
            </div>
          </div>
          <div className={classes.appLinksDiv}>
            <Typography className={classes.LinkTitle}>
              Business app Links:
            </Typography>
            <Link
              target="_blank"
              href="https://apps.apple.com/in/app/cogitopos/id1611183581"
            >
              <img
                // src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/footerimages/appstore.png`}
                src={AppStore}
                className={classes.AppLinks}
              />
            </Link>
            <Link
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.cogitoapp"
            >
              <img
                // src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/footerimages/playstore.png`}
                src={GooglePlay}
                style={{
                  paddingLeft: "2%",
                }}
                className={classes.AppLinks}
              />
            </Link>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.PageLinksCOntainer}>
        {/* <Grid item xs={0} md={1}></Grid> */}

        <Grid item xs={5} sm={2}>
          <Box className={classes.headText} py={{ xs: 0.8 }}>
            <Link href="/" color="inherit">
              Home
            </Link>
          </Box>
          <Box className={classes.headText} py={{ xs: 0.8 }}>
            <Link href="/about" color="inherit">
              About
            </Link>
          </Box>
          <Box className={classes.headText}>
            <Link href="/e-residency" color="inherit" py={{ xs: 0.8 }}>
              E-Residency
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href={process.env.REACT_APP_URL_SIGNUP} color="inherit">
              Register
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/cog" color="inherit">
              The Cog
            </Link>
          </Box>

          <Box className={classes.headText} py={{ xs: 0.8 }}>
            <Link href="/influencers" color="inherit">
              Influencers
            </Link>
          </Box>
          <Box className={classes.headText} py={{ xs: 0.8 }}>
            <Link href="/affiliate-program" color="inherit">
              Affiliate-programs
            </Link>
          </Box>
          <Box className={classes.headText} py={{ xs: 0.8 }}>
            <Link href="/legal" color="inherit">
              Legal
            </Link>
          </Box>
        </Grid>
        <Grid item xs={5} sm={2}>
          <Box className={classes.headText} py={{ xs: 0.8 }}>
            <Link href="/banking" color="inherit">
              Banking
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/cog" color="inherit">
              COG
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/app-pos" color="inherit">
              APP/POS
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/universal-income" color="inherit">
              Universal Income
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/finance" color="inherit">
              Finance
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/fees-taxes" color="inherit">
              {"Fees & Taxes"}
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/security" color="inherit">
              Security
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/personal-banking" color="inherit">
              Personal Banking
            </Link>
          </Box>

          <Box className={classes.headText} py={{ xs: 0.8 }}>
            <Link href="/trusts" color="inherit">
              Trusts
            </Link>
          </Box>
        </Grid>
        <Grid item xs={5} sm={2}>
          <Box className={classes.headText} py={{ xs: 0.8 }}>
            <Link href="/business" color="inherit">
              Business
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/stock-market" color="inherit">
              Stock Exchange
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/business-directory" color="inherit">
              Business Directory
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/company-office" color="inherit">
              Company Office
            </Link>
          </Box>
        </Grid>
        <Grid item xs={5} sm={2}>
          <Box className={classes.headText} py={{ xs: 0.8 }}>
            <Link href="/faq" color="inherit">
              FAQ
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/faq#general" color="inherit">
              General
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/faq#opening an account" color="inherit">
              Opening an account
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/faq#bank account" color="inherit">
              Bank Account
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/faq#security" color="inherit">
              Security
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/faq#our history" color="inherit">
              Our History
            </Link>
          </Box>
        </Grid>
        <Grid item xs={5} sm={2}>
          <Box className={classes.headText} py={{ xs: 0.8 }}>
            <Link href="/registration" color="inherit">
              Registration
            </Link>
          </Box>
        </Grid>
        <Grid item xs={5} sm={2}>
          <Box className={classes.headText} py={{ xs: 0.8 }}>
            <Link href="/contact" color="inherit">
              Contact
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href={process.env.REACT_APP_URL_LOGIN} color="inherit">
              Login
            </Link>
          </Box>
          <Box className={classes.subText} py={{ xs: 0.5 }}>
            <Link href="/presskit" color="inherit">
              Press Kit
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Grid className={classes.downIcon}>
        <Box alignSelf="center" paddingY={2}>
          <Link href="/">
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/footerimages/logo.png`}
              style={{
                // height: "5%",
                width: 94,
              }}
            />
          </Link>
        </Box>
        <Box textAlign="center">
          <Typography
            style={{ fontFamily: "Open Sans", fontWeight: "lighter" }}
          >
            {"Copyright © "}{" "}
            <Link
              style={{
                color: "#359DB6",
                fontFamily: "Open Sans",
                fontWeight: "lighter",
                textDecoration: "none",
              }}
              href={process.env.REACT_APP_URL_POC}
            >
              Principality of Cogito
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
        <Box textAlign="center" paddingY={2}>
          <a
            href="/faq/#privacyTerms"
            style={{
              color: "#359DB6",
              fontFamily: "Open Sans",
              fontWeight: "lighter",
              textDecoration: "none",
            }}
          >
            Privacy Policy
          </a>
        </Box>
        <Box textAlign="center" className={classes.paddingDown}>
          <Link
            href={process.env.REACT_APP_URL_TERMS}
            style={{
              color: "#359DB6",
              fontFamily: "Open Sans",
              fontWeight: "lighter",
              textDecoration: "none",
            }}
          >
            Terms & condition
          </Link>
        </Box>
      </Grid>
    </Box>
  );
};

export default Footer;
