import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/hero.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  regssmargin: {
    margin: "19rem 0 19rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontSize: 22,
    textAlign: "center",
    fontWeight: "bolder",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontFamily: "Arial",
    fontWeight: "bolder",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },
  grid_second: {
    display: "flex",
    flexDirection: "column",
    height: "40rem",
    width: "100%",
    backgroundColor: "#231f20",
    justifyContent: "space-around",
    alignItems: "center",
    color: "#359db6",
    // marginTop: "9rem",
  },

  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 8rem 0 8rem",
    [theme.breakpoints.down(600)]: {
      padding: "2rem 2rem 2rem 2rem",
    },
    [theme.breakpoints.down(450)]: {
      margin: " 0 1rem 1rem 1rem",
      padding: "2rem 2rem 1rem 2rem",
    },
  },

  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    textAlign: "left",
    margin: "0rem 6rem 2rem 6rem",
    [theme.breakpoints.down(2000)]: {
      margin: "0rem 2rem 2rem 2rem",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: "1rem",
      margin: "0rem 1rem 2rem 1rem",
      PaddingLeft: "0",
      paddingRight: "0",
      textAlign: "left",
      alignItems: "left",
    },
  },

  title: {
    fontFamily: "Open Sans",
    color: "#359DB6",
    marginTop: "6rem",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down(420)]: {
      marginTop: "0rem",
      paddingTop: "1rem",
    },
  },
}));

const Influencer_page = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");

  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  return (
    <div className="classes.root">
      <Helmet>
        <title>Influencers</title>
        <meta
          name="description"
          content="Join the virtual reality influencer community at Cogito Metaverse. Connect with VR content creators and start your journey as a VR influencer today. "
        />
        <link rel="canonical" href="https://cogitometaverse.com/influencers" />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Box className={classes.grid_first}>
            <Grid item xs={12} sm={12}>
              {matches ? (
                <Grid container direction="column" alignItems="center">
                  <Grid item className={classes.regssmargin}>
                    <Box className={classes.regBoxmob}>INFLUENCERS</Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction="column">
                  <Grid item className={classes.regssmargin}>
                    <Box className={classes.regBox}>INFLUENCERS</Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Box style={{ marginTop: "6%", marginBottom: "2%" }}>
          <Grid item xs={12} sm={12}>
            <Box className={classes.boxmid}>
              <Typography
                style={{
                  // marginTop: "2%",
                  fontFamily: "Open Sans",
                  textAlign: matches ? "justify" : "center",
                  fontSize: matches ? "1rem" : "1.2rem",
                }}
              >
                At the Cogito Metaverse, we recognise that influencers are a
                brilliant way for us to connect with more people in a whole
                range of demographics. It is important to us that the members
                know that the Cogito Metaverse is built on the desire to develop
                in a way that does not discriminate against someone based on
                their location, status or education.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box className={classes.boxmid}>
                <Box>
                  <Box>
                    <Typography
                      // id={item.title}
                      style={{
                        fontSize: matches ? "1.5rem" : "3rem",
                        fontWeight: "bold",
                        marginTop: matches ? "0rem" : null,
                      }}
                      className={classes.title}
                    >
                      The Cogito Metaverse’s Commitments
                    </Typography>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/shadow.jpg`}
                      alt="shadow"
                      width={"100%"}
                      paddingTop="-30%"
                    />
                  </Box>
                  <Box>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        1.
                      </span>{" "}
                      The Cogito Metaverse is committed to respect towards all
                      people of all genders, backgrounds, skin types and walks
                      of life. We seek to work with influencers who share our
                      values and principles.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        2.
                      </span>{" "}
                      We seek to partner with influencers who have built their
                      following organically through efforts and talent. We check
                      that influencers do not buy or artificially inflate
                      follower count or engagement through paid-for or
                      ‘like-for-like’ means.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        3.
                      </span>{" "}
                      We are fully transparent when communicating about the
                      nature of our relationship with influencers, not only
                      because we must comply with the applicable regulations
                      worldwide, but also to ensure and retain consumer trust.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        4.
                      </span>{" "}
                      We outline our expectations, objectives and other details
                      like timing for publications (or posting deadlines) in a
                      clear way. We provide influencers with the creative
                      liberty of relaying the message in their own style and
                      voice as we value their creativity and authenticity.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        5.
                      </span>{" "}
                      We respect our mutual terms of agreement in terms of image
                      and intellectual property rights (including patents, trade
                      marks, designs, copyrights and neighbouring rights, as
                      well as moral rights) and personal data.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        6.
                      </span>{" "}
                      We do not work with influencers who are under 16 years old
                      or the legal contractual age.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        7.
                      </span>{" "}
                      We will act and communicate respectfully with influencers
                      during all interactions, whether virtual or face-to-face.
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Typography
                      // id={item.title}
                      style={{
                        fontSize: matches ? "1.5rem" : "3rem",
                        fontWeight: "bold",
                      }}
                      className={classes.title}
                    >
                      Influencers’ commitments
                    </Typography>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/shadow.jpg`}
                      alt="shadow"
                      width={"100%"}
                      paddingTop="-30%"
                    />
                  </Box>
                  <Box>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        1.
                      </span>{" "}
                      Influencers commit to communicating in an authentic way,
                      sharing creative, engaging and valuable content.{" "}
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        2.
                      </span>{" "}
                      Influencers share with the Cogito Metaverse the same
                      Ethical principles and values and commit to not posting or
                      have previously posted content which is at odds with our
                      values of respect, tolerance and inclusion. Furthermore,
                      influencers will not share views or engage in behavior
                      which could be interpreted as racism, antisemitism,
                      homophobia, misogyny, religious intolerance, violence,
                      bullying or aggressiveness towards others, pornography or
                      any type of criminal activity.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        3.
                      </span>{" "}
                      Influencers are free to share honest opinions about the
                      Cogito Metaverse, while refraining from posting defamatory
                      content.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        4.
                      </span>{" "}
                      Depending on the nature of the relationship, influencers
                      commit to respect terms of agreement including rules of
                      engagement, expectations and objectives, or content
                      posting deadlines.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        5.
                      </span>{" "}
                      Influencers shall communicate in a transparent way
                      regarding the nature of their relationship with the Cogito
                      Metaverse by fully disclosing their commercial interests
                      to their audience, so that there is complete transparency
                      when content has been paid-for or incentivized (paid
                      partnership, #SponsoredBy, etc).
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        6.
                      </span>{" "}
                      Influencers will respect all image and intellectual
                      property rights of third parties (including trade marks,
                      designs, copyrights, neighbouring rights, and moral
                      rights) as well as all consumer rights to data privacy.
                      More specifically, influencers will obtain all necessary
                      authorizations from image and intellectual property rights
                      owners (including models, photographers, music publishers
                      and record labels) before using any third parties’ image
                      and works (including music) in any content.
                    </Typography>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ color: "#ff7300", fontWeight: "bold" }}>
                        7.
                      </span>{" "}
                      Influencers will act and communicate respectfully with the
                      Cogito Metaverse teams during all their interactions,
                      whether virtual or face-to-face.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default Influencer_page;
