import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import securityData from "./securityData";
import { isMobile } from "react-device-detect";
import BG from "../../assets/work_bg.png";
import ThemeButton from "../../Component/common/button";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      letterSpacing: 7,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },
  content: { paddingTop: "6rem", paddingBottom: "6rem", textAlign: "center" },
  bodyContent: {
    fontFamily: "Open Sans",
    fontWeight: "ligter",
    fontSize: "1rem",
    paddingBottom: "2%",
    [theme.breakpoints.down(450)]: {
      textAlign: "justify",
      padding: "0 1.2rem 0 1.2rem",
    },
  },
  title: {
    color: "#FF7300",
    font: "Open Sans",
    fontWeight: "bold",
    fontSize: "2.2rem",
  },
  boxend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "3rem 1rem 3rem 1rem",
    backgroundColor: "#231f20",
  },
  boxend2typo: {
    fontSize: "1.2rem",
    fontWeight: "lighter",
    fontFamily: "open sans",
    textAlign: "center",
    padding: "0 7rem 1rem 7rem",
    color: "#359db6",

    [theme.breakpoints.down(856)]: {
      padding: "0 0 2rem 0",
      fontSize: "0.9rem",
      fontWeight: "600",
    },
  },
}));

const Workus = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container>
      <Helmet>
        <title>Work with us</title>
        <meta
          name="description"
          content="Join the team at Cogito Metaverse and explore career opportunities in virtual reality, metaverse finance, investment, and services. Start working with us today."
        />
        <link
          rel="canonical"
          href=" https://cogitometaverse.com/work_with_us "
        />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    WORK WITH US
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    WORK WITH US
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        style={{
          background: `url(${BG})`,
          height: matches ? "40vh" : "80vh",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // marginBottom: matches ? "" : "3%",
          justifyContent: "center",
        }}
      >
        <Typography
          style={{
            fontFamily: "Arial",
            fontStyle: "bolder",
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
            fontSize: matches ? "1.6rem" : "3rem",
          }}
        >
          The Cogito Metaverse is the
          <br />
          future of global finance
        </Typography>
      </Grid>
      <Container className={classes.content}>
        <Grid item container spacing={5} justifyContent="center">
          <Grid item xs={12}>
            <Typography className={classes.bodyContent}>
              The Cogito Metaverse is always looking for great people. While
              your skills and ability is obviously important – it is your
              attitude and desire to be part of a progressive, all-inclusive
              team culture that will set you apart.
            </Typography>
            <Typography className={classes.bodyContent}>
              We have opportunities coming up all around the globe for people.
            </Typography>
            <Typography className={classes.bodyContent}>
              If you would like to join the Cogito Metaverse workforce, keep
              checking back to this page, we will be listing opportunities here
              from February 2022.
            </Typography>
            <Typography className={classes.bodyContent}>
              Whether we are advertising for people – we always welcome CVs from
              people with industry experience. Any communication with us will be
              treated in the strictest of confidence.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12} sm={12}>
        <Box className={classes.boxend}>
          <Container>
            <Box>
              <Typography className={classes.boxend2typo}>
                If you would like to work with us please send your details and
                CV to
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ThemeButton
                background="#FF7300"
                title="support@principalityofcogito.com"
                size={matches ? 12 : 14}
                width={matches ? 260 : 400}
                // height={40}
                radius={11}
                // onClick={handleClickSingUp}
                margin="10 0 0 10px"
              />
            </Box>
          </Container>
        </Box>
      </Grid>

      <Container className={classes.content}>
        <Grid item container spacing={5} justifyContent="center">
          <Grid item xs={12}>
            <Typography className={classes.bodyContent}>
              At the Cogito Metaverse our mission is to give people the tools
              they need to create the financial future they want, free from the
              restrictions of location, governments and pressure from vested
              interests.
            </Typography>
            <Typography
              style={{
                paddingBottom: "1rem",
              }}
            ></Typography>
            <Typography className={classes.bodyContent}>
              At the Cogito Metaverse we have created a place of inclusivity,
              our team consists of many different cultures. At all our Cogito
              Metaverse branches we strive to create a great work environment
              for everyone.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Workus;
