import { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";

import Dropdown from "./Dropdown";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.submenu ? (
        <>
          <a
            href={items.to}
            target={items.target && items.target}
            style={{
              padding: 0,
            }}
          >
            <button
              type="button"
              aria-haspopup="menu"
              aria-expanded={dropdown ? "true" : "false"}
              onClick={() => setDropdown((prev) => !prev)}
            >
              <Grid container style={{ border: "none" }}>
                <Grid
                  item
                  xs={10}
                  style={{ textAlign: "left", border: "none" }}
                >
                  {items.title}
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  {depthLevel > 0 ? (
                    <span>&#9654;</span>
                  ) : (
                    <span className="arrow" />
                  )}
                </Grid>
              </Grid>
            </button>
            <Dropdown
              depthLevel={depthLevel}
              submenus={items.submenu}
              dropdown={dropdown}
            />
          </a>
        </>
      ) : (
        <a href={items.to} target={items.title === "REGISTER" && "_blank"}>
          {items.title}
        </a>
      )}
    </li>
  );
};

export default MenuItems;
