import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      letterSpacing: 7,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },
  content: { paddingTop: "3%", paddingBottom: "3%", textAlign: "center" },
  bodyContent: {
    fontFamily: "Open Sans",
    fontSize: "1.2rem",
    paddingTop: "4%",
    [theme.breakpoints.down(420)]: {
      textAlign: "justify",
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
  },
}));

const AppPos = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  return (
    <Grid container>
      <Helmet>
        <title>App/POS</title>
        <meta
          name="description"
          content="Transform your metaverse business with the App POS at Cogito Metaverse. Enjoy a seamless virtual reality point-of-sale experience for your VR business."
        />
        <link rel="canonical" href="https://cogitometaverse.com/app-pos" />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    APP / POS
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    APP / POS
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Container className={classes.content}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12}>
            <Typography className={classes.bodyContent}>
              Manage your Cog with the Cogito POS, Point of Sale app, available
              on iOS and Android. This allows you to purchase goods or pay for
              services or receive money for goods and services on your phone or
              mobile device.
            </Typography>
            <Typography className={classes.bodyContent}>
              You will not be able to see your balance but you will be able to
              use your affiliate QR code.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/app-pos-page.png`}
              style={{ width: "80%" }}
            />
          </Grid>
          <Grid item container xs={12} sm={6} style={{ textAlign: "left" }}>
            <Grid item xs={12}>
              <Typography className={classes.bodyContent}>
                Download now and start using
                <br /> those Cog that you have earned!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Link href="/">
                <img
                  src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/footerimages/appstore.png`}
                  style={{
                    width: "19%",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                  }}
                />
              </Link>
              <Link href="https://play.google.com/store/apps/details?id=com.cogitoapp">
                <img
                  src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/footerimages/playstore.png`}
                  style={{
                    width: "23%",
                    paddingLeft: "2%",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                  }}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default AppPos;
