import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
 display: flex;
 color: #e1e9fc;
 justify-content: space-between;
 align-items: center;
 padding: 20px;
 list-style: none;
 height: 60px;
 text-decoration: none;
 font-size: 18px;

 &:hover {
  background: #252831;
  border-left: 4px solid #ff7300;
  cursor: pointer;
 }
`;

const NestedSidebarLink = styled(Link)`
 display: flex;
 color: #e1e9fc;
 justify-content: space-between;
 align-items: center;
 padding: 20px;
 list-style: none;
 height: 60px;
 text-decoration: none;
 font-size: 18px;
 padding-left: 3rem;

 &:hover {
  background: #252831;
  border-left: 4px solid #ff7300;
  cursor: pointer;
 }
`;

const SidebarLabel = styled.span`
 margin-left: 16px;
 font-family: Arial, sans-serif;
 font-weight: bold;
`;

const DropdownLink = styled(Link)`
 background: #414757;
 height: 60px;
 padding-left: 3rem;
 display: flex;
 align-items: center;
 text-decoration: none;
 color: #f5f5f5;
 font-size: 18px;

 &:hover {
  background: #ff7300;
  cursor: pointer;
 }
`;

const NestedDropdownLink = styled(Link)`
 background: #ffead8;
 height: 60px;
 padding-left: 3rem;
 display: flex;
 align-items: center;
 text-decoration: none;
 color: #000;
 font-size: 18px;
 padding-left: 4.5rem;

 &:hover {
  background: #ff7300;
  cursor: pointer;
 }
`;

const SubMenu = ({ item, showSidebar }) => {
 const [subNav, setSubNav] = useState(false);
 const [bankingNestedSubNav, setBankingNestedSubNav] = useState(false);
 const [businessNestedSubNav, setBusinessNestedSubNav] = useState(false);
 const [eresidencyNestedSubNav, setEResidencyNestedSubNav] = useState(false);

 const showSubnav = () => {
  setSubNav(!subNav);
 };
 const showNestedSubnav = (item) => {
  if (item.title === "BANKING") {
   setBankingNestedSubNav((prevState) => !prevState);
   setBusinessNestedSubNav(false);
   setEResidencyNestedSubNav(false);
  } else if (item.title === "E-RESIDENCY") {
   setEResidencyNestedSubNav((prevState) => !prevState);
   setBankingNestedSubNav(false);
   setBusinessNestedSubNav(false);
  } else if (item.title === "BUSINESS") {
   setBusinessNestedSubNav((prevState) => !prevState);
   setBankingNestedSubNav(false);
   setEResidencyNestedSubNav(false);
  }
 };

 const handleClickSignUp = (item) => {
  if (item == `${process.env.REACT_APP_URL_SIGNUP}`) {
   let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
   window.open(signupLink, "_blank");
  }
 };

 return (
  <>
   <SidebarLink
    to={item.to}
    onClick={() => item.submenu && showSubnav()}
    DropdownLink={DropdownLink}
   >
    <div>
     <SidebarLabel onClick={item.submenu ? null : showSidebar}>
      {item.title}
     </SidebarLabel>
    </div>
    <div>{item.submenu && subNav ? item.iconOpened : item.iconClosed}</div>
   </SidebarLink>

   {subNav &&
    item.submenu &&
    item.submenu.map((subItem, subIndex) => (
     <div key={subIndex}>
      {!subItem.submenu && (
       <DropdownLink to={subItem.to}>
        <SidebarLabel onClick={showSidebar}>{subItem.title}</SidebarLabel>
       </DropdownLink>
      )}
      {subItem.submenu && (
       <>
        <NestedSidebarLink
         to={subItem.to}
         onClick={() => subItem.submenu && showNestedSubnav(subItem)}
         DropdownLink={DropdownLink}
        >
         <div>
          <SidebarLabel onClick={subItem.submenu ? null : showSidebar}>
           {subItem.title}
          </SidebarLabel>
         </div>
         <div>
          {subItem.submenu &&
          (bankingNestedSubNav ||
           businessNestedSubNav ||
           eresidencyNestedSubNav)
           ? subItem.nestediconOpened
           : subItem.nestediconClosed}
         </div>
        </NestedSidebarLink>
        {((bankingNestedSubNav && subItem.title === "BANKING") ||
         (businessNestedSubNav && subItem.title === "BUSINESS") ||
         (eresidencyNestedSubNav && subItem.title === "E-RESIDENCY")) &&
         subItem.submenu.map((nestedItem, nestedIndex) => (
          <NestedDropdownLink
           to={nestedItem.to}
           key={nestedIndex}
           target={
            nestedItem.to === `${process.env.REACT_APP_URL_SIGNUP}`
             ? "_blank"
             : "_self"
           }
           onClick={(e) => {
            if (nestedItem.to === `${process.env.REACT_APP_URL_SIGNUP}`) {
             handleClickSignUp(nestedItem.to);
            }
           }}
          >
           <SidebarLabel onClick={showSidebar}>{nestedItem.title}</SidebarLabel>
          </NestedDropdownLink>
         ))}
       </>
      )}
     </div>
    ))}
  </>
 );
};

export default SubMenu;
