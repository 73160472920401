import { Link } from "react-router-dom";

//login section start
const signIn = [
  {
    title: (
      <>
        {"Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/metaverse.svg`,
  },
  {
    title:
      "Step 2. Click on the Login button on the top right corner of the screen.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Web/login-2.svg`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and your password in the mentioned fields.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Web/login-3.svg`,
  },
  {
    title: "Step 4. Click on ‘submit’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Web/login-3.svg`,
  },
];

const signUp = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/metaverse.svg`,
  },
  {
    title:
      "Step 2. Click on the ‘Register’ button on the top-right corner of your screen.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Web/registerButton.svg`,
  },
  {
    title: "Step 3. Enter your email id in the given field.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Web/email.svg`,
  },
  {
    title: "Step 4. Click on ‘Send OTP’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Web/sendOtp.svg`,
  },
  {
    title:
      "Step 5. Check your given email id’s inbox, an email with a 6 digit OTP should be there. If you are unable to find it then make sure that you check your spam and junk email folders.",
    media: "",
  },
  {
    title:
      "Step 6. Copy the OTP mentioned in the email you received and enter it in the mentioned field on the Cogito webpage.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Web/verifyOtp.svg`,
  },
  {
    title: "Step 7. Click on ‘Verify OTP’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Web/verifyOtp2.svg`,
  },
  {
    title:
      "Step 8. Next, create your profile. Enter your name, phone number, date of birth and address.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Web/createAccount1.svg`,
  },

  {
    title: "Step 9. Click on ‘Next’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Web/createAccount2.svg`,
  },
  {
    title:
      "Step 10. Agree to the mentioned terms and conditions. Turn on the toggle buttons and click on the checkbox.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Web/t&C.svg`,
  },
  {
    title: "Step 11. Click on ‘Next’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Web/t&cNext.svg`,
  },
  {
    title: "Step 12. Create a username and password for yourself.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Web/userName1.svg`,
  },
  {
    title:
      "Step 13. Make sure your password includes minimum 6 characters, at least one uppercase letter, one lowercase letter and a numeric character. ",
    media: "",
  },
  {
    title: "Step 14. Re-enter your password.",
    media: "",
  },
  {
    title: "Step 15. Click on ‘Submit’.",
    media: "",
  },
  {
    title:
      "Step 16. Your registration is complete. You can use your set username and password to sign in now.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Web/complete.svg`,
  },
];

const forgotPassword = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/metaverse.svg`,
  },
  {
    title: "",
    media: "",
  },
  {
    title: "Step 2. Click on ‘Login’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Web/login-2.svg`,
  },
  {
    title: "Step 3. Click on ‘forgot password’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Web/step3.svg`,
  },
  {
    title: "Step 4. Next, enter your registered email id.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Web/step4.svg`,
  },
  {
    title: "",
    media: "",
  },
  {
    title: "Step 5. Click on ‘Send OTP’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Web/step5.svg`,
  },
  {
    title: "Step 6. Go to your registered email-id’s inbox. ",
    media: "",
  },
  {
    title:
      "Step 7. You would have received an email with an OTP. In case you don’t find it in your primary inbox, check your junk or spam folders.",
    media: "",
  },
  {
    title: "Step 8. Copy the OTP mentioned in the email.",
    media: "",
  },
  {
    title: "Step 9. Enter the OTP on the field given on the webpage.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Web/step9.svg`,
  },
  {
    title: "Step 10. Click on ‘Verify OTP’. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Web/step10.svg`,
  },
  {
    title: "Step 11. Enter a new password.",
    media: "",
  },
  {
    title: "Step 12. Re-enter the new password.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Web/step12.svg`,
  },
  {
    title: "Step 13. Click on ‘Submit’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Web/step13.svg`,
  },
  {
    title: "Your password is now updated!",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Web/complete.svg`,
  },
];

const loginSection = {
  0: signIn,
  1: signUp,
  2: forgotPassword,
}; //login section end

// Escrow Section Start
const escrowPayment = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step1.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step2.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step3.png`,
  },
  {
    title: "Step 4. Click on the ‘Banking’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step4.jpg`,
  },
  {
    title: "Step 5. Click on ‘Escrow’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step5.jpg`,
  },
  {
    title:
      "Step 6. Under the ‘Initiate Payment’ tab, you will see that there is an Escrow Form.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step6.jpg`,
  },
  {
    title: "Step 7. Fill in all the details in the Escrow Form.",
    media: "",
  },
  {
    title:
      "Step 8. First enter the ‘Payment Type’- select between COG, CBC and EURO transaction here.  ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step8.jpg`,
  },
  {
    title:
      "Step 9. Add the Cogito registered email id or the username of the person or business who you are sending the payment to in the ‘Pay to’ field.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step9.jpg`,
  },
  {
    title:
      "Step 10. Enter the amount you would like to send in the ‘You Send’ field. You will be able to see the converted value of the same under the ‘Recipient Gets’ field",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step10.png`,
  },
  {
    title:
      "Step 11. Add a ‘Title’ and ‘Description’, which will act as a reference detail for your payment.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step11.jpg`,
  },
  {
    title:
      "Step 12. Click ‘Add Conditions’ and add at least one condition for successful initiation of the payment. A condition is a requirement that needs to be fulfilled by the recipient you are initiating the Escrow Payment to.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step12.png`,
  },
  {
    title: "Step 13. Write your condition in the box that appears.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step13.jpg`,
  },
  {
    title: "Step 14. Click on ‘Add’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step14.png`,
  },
  {
    title:
      "Step 15. Click on ‘List Condition’ to add your condition to the payment. You can add more conditions using the same steps. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step15.jpg`,
  },
  {
    title: "Step 16. Click on ‘Submit’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step16.png`,
  },
  {
    title:
      "Step 17. In the next step, fill in your Cogito transaction PIN to and click on the ‘Initiate’ button to complete the initiation process.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step17.jpg`,
  },
  {
    title: "Step 18. Your Escrow Payment has been initiated successfully.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step18.png`,
  },
  {
    title:
      "Step 19. You will be able to see the initiated Escrow Payment in the following step, under the ‘Payment Requests’ tab. Click on ‘Sent Requests’ here.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step19.jpg`,
  },
  {
    title:
      "Step 20. Once the recipient receives your Escrow Payment request and marks all the mentioned conditions of the payment complete, you will be able to move on to the final steps of the process.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step20.jpg`,
  },
  {
    title:
      "Step 21.After the conditions are completed by the recipient, open your ‘Ongoing Payments’ tab under the ‘Escrow’ section.",
    media: "",
  },
  {
    title:
      "Step 22. You will see a ‘Pay Now’ button next to the Escrow Payment initiated by you. Click on this button.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step22.jpg`,
  },
  {
    title:
      "Step 23. Enter your Cogito transaction PIN again and click on ‘Pay’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step23.jpg`,
  },
  {
    title: "Step 24.The payment amount has now been transferred to Escrow.",
    media: "",
  },
  {
    title:
      "Step 25. Next, check that all the conditions have been marked completed and approved by you and the recipient.",
    media: "",
  },
  {
    title:
      "Step 26. Go to the ‘Ongoing Payments’ tab. Under this tab, you will see the ‘Transfer Now’ button next to the Escrow Payment initiated by you. Click on this button.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step26.jpg`,
  },
  {
    title:
      "Step 27. Enter your transaction PIN for the last time and click on ‘Pay’. Your amount will then be transferred to the recipient via Escrow. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step27.jpg`,
  },
  {
    title: "Step 28. Your Escrow Payment to your recipient is now complete.",
    media: "",
  },
  {
    title:
      "Step 29. You can check your completed payments under the ‘Completed Payments’ tab under the ‘Escrow’ section. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step29.png`,
  },
];

const initiateEscrow = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step1.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step2.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step3.png`,
  },
  {
    title: "Step 4. Click on the ‘Banking’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step4.jpg`,
  },
  {
    title: "Step 5. Click on ‘Escrow’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step5.jpg`,
  },
  {
    title:
      "Step 6. Under the ‘Initiate Payment’ tab, you will see that there is an Escrow Form.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step6.jpg`,
  },
  {
    title: "Step 7. Fill in all the details in the Escrow Form.",
    media: "",
  },
  {
    title:
      "Step 8. First enter the ‘Payment Type’- select between COG, CBC and EURO transaction here.  ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step8.jpg`,
  },
  {
    title:
      "Step 9. Add the Cogito registered email id or the username of the person or business who you are sending the payment to in the ‘Pay to’ field.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step9.png`,
  },
  {
    title:
      "Step 10. Enter the amount you would like to send in the ‘You Send’ field. You will be able to see the converted value of the same under the ‘Recipient Gets’ field.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step10.png`,
  },
  {
    title:
      "Step 11. Add a ‘Title’ and ‘Description’, which will act as a reference detail for your payment.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step11.jpg`,
  },
  {
    title:
      "Step 12. Click ‘Add Conditions’ and add at least one condition for successful initiation of the payment. A condition is a requirement that needs to be fulfilled by the recipient you are initiating the Escrow Payment to.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step12.png`,
  },
  {
    title: "Step 13. Write your condition in the box that appears.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step13.jpg`,
  },
  {
    title: "Step 14. Click on ‘Add’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step14.png`,
  },
  {
    title:
      "Step 15. Click on ‘List Condition’ to add your condition to the payment. You can add more conditions using the same steps. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step15.jpg`,
  },
  {
    title: "Step 16. Click on ‘Submit’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step16.png`,
  },
  {
    title:
      "Step 17. In the next step, fill in your Cogito transaction PIN to and click on the ‘Initiate’ button to complete the initiation process.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step17.jpg`,
  },
  {
    title: "Step 18. Your Escrow Payment has been initiated successfully.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step18.png`,
  },
  {
    title:
      "Step 19. You will be able to see the initiated Escrow Payment in the following step, under the ‘Payment Requests’ tab. Click on ‘Sent Requests’ here.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Payment/step19.jpg`,
  },
];

const cancelEscrowSent = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step1.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step2.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step3.png`,
  },
  {
    title: "Step 4. Click on the ‘Banking’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step4.jpg`,
  },
  {
    title: "Step 5. Click on ‘Escrow’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step5.jpg`,
  },
  {
    title: "Step 6. Click on the ‘Payment Requests’ tab.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow//Cancel Sent Request/step6.jpg`,
  },
  {
    title: "Step 7. Under this tab, click on the ‘Sent Request’ option.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow//Cancel Sent Request/step7.jpg`,
  },
  {
    title:
      "Step 8. You will be able to see all the Escrow Payment initiation requests sent by you.",
    media: "",
  },
  {
    title:
      "Step 9. Choose the Escrow Payment request you want to cancel. Click on the ‘Cancel’ button next to, under the Actions column.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow//Cancel Sent Request/step9.png`,
  },
  {
    title: (
      <>
        <text>Step 10. In the pop-up box that appears, click on ‘Yes’.</text>,
        <br />
        <br />
        <text>
          Now, once the recipient approves the cancellation of the Escrow
          Payment initiated by you through their Escrow account, your sent
          request will be cancelled successfully.
        </text>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow//Cancel Sent Request/step10.png`,
  },
];
const cancelReceivedEscrow = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step1.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step2.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step3.png`,
  },
  {
    title: "Step 4. Click on the ‘Banking’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step4.jpg`,
  },
  {
    title: "Step 5. Click on ‘Escrow’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step5.jpg`,
  },
  {
    title: "Step 6. Click on the ‘Payment Requests’ tab.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Cancel Received request/step6.jpg`,
  },
  {
    title: "Step 7. Under this tab, click on the ‘Received Request’ option.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Cancel Received request/step7.jpg`,
  },
  {
    title:
      "Step 8. You will be able to see all the Escrow Payment initiation requests sent to you by buyers.",
    media: "",
  },
  {
    title:
      "Step 9. Choose the Escrow Payment request that you want to cancel or deny. Click on the ‘Reject’ button next to it, under the Actions column. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Cancel Received request/step9.jpg`,
  },
  {
    title: "Step 10. In the pop-up box that appears, click on ‘Reject’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Cancel Received request/step10.jpg`,
  },
  {
    title:
      "Step 11. The Escrow Payment request received by you is now successfully cancelled.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Cancel Received request/step11.png`,
  },
];

const completedEscrow = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step1.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step2.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step3.png`,
  },
  {
    title: "Step 4. Click on the ‘Banking’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step4.jpg`,
  },
  {
    title: "Step 5. Click on ‘Escrow’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step5.jpg`,
  },
  {
    title: "Step 6. Click on the ‘Completed Payments’ tab.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Completed Escrow/step6.jpg`,
  },
  {
    title:
      "Step 7. Under this tab, all your completed Escrow Payments will be listed from the latest to the oldest. Click on the payment you would like to go through and you will be able to see all its details.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Completed Escrow/step7.jpg`,
  },
  {
    title: "",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Completed Escrow/step8.png`,
  },
  {
    title: "",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Completed Escrow/step9.png`,
  },
];
const escrowPaymentRequests = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step1.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step2.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step3.png`,
  },
  {
    title: "Step 4. Click on the ‘Banking’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step4.jpg`,
  },
  {
    title: "Step 5. Click on ‘Escrow’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/step5.jpg`,
  },
  {
    title: "Step 6. Select the ‘Payment Requests’ tab.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Requests/step6.jpg`,
  },
  {
    title:
      "Step 7. Under this tab you can find all the Escrow Payment requests received by you by selecting the ‘Received Request’ option.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Requests/step7.jpg`,
  },
  {
    title:
      "Step 8. To view the Escrow Payment requests sent by you or initiated by you, select the ‘Sent Request’ option.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Requests/step8.jpg`,
  },
];
const escrowSection = {
  0: escrowPayment,
  1: initiateEscrow,
  2: cancelEscrowSent,
  3: cancelReceivedEscrow,
  4: completedEscrow,
  5: escrowPaymentRequests,
};
//escrowSection end

{
  /* changes by pk */
}
//adding Using the Website/App section
const Kyc = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/0.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/1.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/2.png`,
  },
  {
    title: "Step 4. Click on the ‘Profile’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/3.jpg`,
  },
  {
    title:
      "Step 5. Under the Profile section, you will see a ‘Your KYC Status’ option.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/4.png`,
  },
  {
    title:
      "Step 6. Click on the ‘View Now’ button on the ‘Your KYC Status’ option.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/5.jpg`,
  },
  {
    title: "Step 7. A pop-up will appear listing the KYC Steps.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/6.png`,
  },
  {
    title:
      "Step 8. Complete your profile in case you have not added all your information.",
    media: "",
  },
  {
    title:
      "Step 9. Then, click on the toggle button to agree to the terms and conditions.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/7.jpg`,
  },
  {
    title: "Step 10. Click on ‘Next’ to move to the final step.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/8.jpg`,
  },
  {
    title:
      "Step 11. Check the box that appears next to confirm your consent for processing your personal data for KYC and click on ‘Next’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/9.jpg`,
  },
  {
    title: "Step 12. Select your ID issuing country.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/10.jpg`,
  },
  {
    title:
      "Step 13. Choose the type of document you will use as your ID proof- ID card, Passport, Residence permit, Driver’s licence.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/11.jpg`,
  },
  {
    title:
      "Step 14. Upload a clear and bright photo of your ID document. Both the front and back side.",
    media: "",
  },
  {
    title: "Step 15. Click on ‘Confirm and Proceed’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/12.jpg`,
  },
  {
    title:
      "Step 16. Read the instructions to take a selfie face scan and click on ‘I’m Ready’ to proceed further.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/13.jpg`,
  },
  {
    title:
      "Step 17. Once successful, check the ID information you have provided and click on ‘Next’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/14.jpg`,
  },
  {
    title: "Step 18. Your provided data will be automatically processed.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/15.jpg`,
  },
  {
    title:
      "Step 19. It can take up to 48 hrs for your KYC status to be updated.",
    media: "",
  },
  {
    title:
      "Step 20. When your KYC Process is complete, you will be able to see it under the ‘Your KYC Status’ option.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/web/16.png`,
  },
];

const profilePicture = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/web/0.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/web/1.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/web/2.png`,
  },
  {
    title: "Step 4. Click on the ‘Profile’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/web/3.jpg`,
  },
  {
    title: "Step 5. Click on the ‘Update Profile Pic’ option.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/web/4.jpg`,
  },
  {
    title: "Step 6. Select a picture of your choice from your device.",
    media: "",
  },
  {
    title: "Step 7. Click on ‘Save’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/web/5.png`,
  },
  {
    title:
      "Step 8. Your profile avatar is now set. You can always change this under the ‘Update Profile Pic’ option.",
    media: "",
  },
];

const members = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/web/0.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/web/1.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/web/2.png`,
  },
  {
    title: "Step 4. Click on the ‘Profile’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/web/3.jpg`,
  },
  {
    title:
      "Step 5. Under the ‘Family Members’ option, click on the ‘Check Now’ button.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/web/4.png`,
  },
  {
    title:
      "Step 6. In the given field, to add a family member, enter their registered Cogito username or their email id.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/web/5.png`,
  },
  {
    title:
      "Step 7. In case of an email id, click on the arrow that appears next to ‘User doesn't exist, click on button to invite’ your family member.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/web/6.png`,
  },
  {
    title:
      "Step 8. In case of a username, add their registered Cogito username and click on ‘Send Invite’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/web/7.png`,
  },
  {
    title:
      "Step 9. Once your invite is accepted, your family members will be listed here.",
    media: "",
  },
];

const userInformation = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/web/0.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/web/1.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/web/2.png`,
  },
  {
    title: "Step 4. Click on the ‘Profile’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/web/3.jpg`,
  },
  {
    title: "Step 5. Slide down and click on the option ‘User Information’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/web/4.png`,
  },
  {
    title: "Step 6. Find all your registered user profile details here.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/web/5.png`,
  },
  {
    title:
      "Step 7. You can even edit your user information by clicking on the ‘Modify’ button.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/web/6.png`,
  },
];

const changePassword = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/web/0.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/web/1.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/web/2.png`,
  },
  {
    title: "Step 4. Click on the ‘Profile’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/web/3.jpg`,
  },
  {
    title: "Step 5. Slide down to the option ‘Change Password’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/web/4.png`,
  },
  {
    title: "Step 6. Enter your ‘current password’.",
    // media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Requests/step6.jpg`,
  },
  {
    title: "Step 7. Then enter your ‘new password’.",
    // media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Requests/step7.jpg`,
  },
  {
    title: "Step 8. Then re-enter your ‘new password’. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/web/5.png`,
  },
  {
    title: "Step 9. Click on ‘Save’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/web/6.png`,
  },
  {
    title: "Step 10. Your password is now changed.",
    media: "",
  },
];

const companyInformation = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_company_information/web/0.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_company_information/web/1.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_company_information/web/2.png`,
  },
  {
    title: "Step 4. Click on the ‘Profile’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_company_information/web/3.jpg`,
  },
  {
    title: "Step 5. Side down to the ‘Company Information’ option.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_company_information/web/4.png`,
  },
  {
    title:
      "Step 6. You can find all your registered company’s information here.",
    // media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Escrow/Escrow Requests/step6.jpg`,
  },
];

const qrposter = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code_poster/web/0.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code_poster/web/1.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code_poster/web/2.png`,
  },
  {
    title: "Step 4. Click on the ‘Business’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code_poster/web/3.png`,
  },
  {
    title:
      "Step 5. Click on the tab that says ‘QR Poster’ option to view your QR Code Poster.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code_poster/web/4.png`,
  },
  {
    title:
      "Step 6. Download the PDF version of your QR Code Poster by clicking on the ‘Download PDF’ button at the bottom of the page. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code_poster/web/5.png`,
  },
];

const defaultCurrency = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/web/0.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/web/1.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/web/2.png`,
  },
  {
    title: "Step 4. Click on the ‘Banking’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/web/3.png`,
  },
  {
    title: "Step 5. Click on the option ‘Default Currency’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/web/4.png`,
  },
  {
    title:
      "Step 6. Choose your preferred default currency from the dropdown list. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/web/5.png`,
  },
  {
    title: "Step 7. Click on ‘Set’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/web/6.png`,
  },
  {
    title: "Step 8. Your default currency is now set.",
    media: "",
  },
  {
    title:
      "Step 9. You can always change your default currency preference following the same steps in the future.",
    media: "",
  },
];

const qrcode = [
  {
    title: (
      <>
        {"  Step 1. Go to"}{" "}
        <Link to="/" target="_blank" style={{ textDecoration: "none" }}>
          www.cogitometaverse.com
        </Link>
      </>
    ),
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code/web/0.png`,
  },
  {
    title: "Step 2. Click on 'Login'.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code/web/1.png`,
  },
  {
    title:
      "Step 3. Enter your registered email id or username and password, and login in to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code/web/2.png`,
  },
  {
    title: "Step 4. Click on the ‘Profile’ button on the left side.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code/web/3.jpg`,
  },
  {
    title:
      "Step 5. Click on the ‘QR Code’ option to view your personal QR code.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code/web/4.png`,
  },
  {
    title:
      "Step 6. You can find your QR code under the ‘Affiliate Program’ section too.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code/web/5.png`,
  },
];
const webApp = {
  0: Kyc,
  1: profilePicture,
  2: members,
  3: userInformation,
  4: changePassword,
  5: companyInformation,
  6: qrposter,
  7: defaultCurrency,
  8: qrcode,
};

// end Using the Website/App section

//export Web help data
export const webData = {
  Login: loginSection,
  Escrow: escrowSection,
  "Using the Website/App": webApp,
};
