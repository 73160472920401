import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import React from "react";
// import step1 from "../../../../../assets/help-section/Login/Mobile/step-1.svg";
// import step2 from "../../../../../assets/help-section/Login/Mobile/step-2.svg";
// import step3 from "../../../../../assets/help-section/Login/Mobile/step-3.svg";
import { mobileData } from "../../RightContainer/mobileData";

const useStyles = makeStyles((theme) => ({
  Steps: {
    fontSize: "1.2rem",
    fontWeight: "bolder",
    color: "#359DB6",
    wordSpacing: 3,
    paddingBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  StepContainer: {
    textAlign: "left",
    marginLeft: "2%",
  },
}));
const AppContent = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { section } = props;
  return (
    <Grid container style={{ padding: "2%" }}>
      {mobileData[section.section][section.key]?.map((data, index) => {
        return (
          <Grid item xs={12} key={index} className={classes.StepContainer}>
            {data?.title ? (
              <Typography className={classes.Steps}>{data?.title}</Typography>
            ) : null}
            {data?.media ? <img src={data?.media} width="55%" /> : null}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AppContent;
