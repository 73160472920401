const securityData = [
  {
    title: "",
    data: [
      "The Cogito Metaverse is built with the type of cutting edge security that you would expect from any financial institution around the world. In addition to this, the design of the Cogito Metaverse means that someone from outside the Cogito Metaverse cannot hack in and steal cog and remove them from the Metaverse. This ensures there is a digital trail for any one attempting fraudulent behaviour.  ",
      "It is not possible for a member of the Cogito Metaverse to create rules and enforce their own rules on other users. As per the current specification, double spending is not possible on the Cogito systems, and neither is spending Cog without a valid signature. Therefore, it is not possible to generate uncontrolled amounts of Cog out of thin air, spend other users’ funds, corrupt the network, or anything similar.",
      "All members of the Cogito Metaverse are required to carry out the KYC and AML process. KYC and AML compliance are critical for preventing fraud, money laundering and other financial crime. Regardless of your industry, if you enable customers to move money, you could be a target for money laundering.",
    ],
  },
  {
    title: "What is KYC and AML?",
    data: [
      "KYC stands for ‘know your customer’ and AML stands for ‘anti-money laundering’. It is the process of a business identifying and verifying the identity of its clients. This regulatory process is a legislative requirement in many jurisdictions to ensure the security and integrity of the system. ",
    ],
  },
  {
    title: "Is Cog secure?",
    data: [
      "The Cogito technology - the protocol and cryptography - has a strong security track record. Cogitos most common vulnerability is in user error. Cog account files that store necessary security can not be accidentally deleted, lost or stolen.",
    ],
  },
];

export default securityData;
