import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Link,
  Container,
} from "@material-ui/core";

import { useParams } from "react-router";
import { setWithExpiry, getWithExpiry } from "../../assets/utility";
import ThemeButton from "../../Component/common/button";
import homeBackground from "../../assets/home/homeBackground.png";
import homeBusiness from "../../assets/home/homeBusiness.png";
import { Helmet } from "react-helmet";
import BulletPoint from "../../Component/BulletPoint";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    // background: `url(${homeBackground})`,
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/hero.png)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  regssmargin: {
    margin: "0rem 0 6rem 0",
  },

  typo1: {
    [theme.breakpoints.down(450)]: {
      fontSize: "1.1rem",
      marginBottom: "0.7rem",
    },
  },

  typo2: {
    fontSize: "1.3em",
    color: "#ff7300",
    [theme.breakpoints.down(440)]: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "0.8em",
      fontWeight: "bold",
    },
  },

  vector: {
    marginTop: "1%",
  },

  img1: {
    height: "120px",
    [theme.breakpoints.down(440)]: {
      height: "59px",
      marginBottom: "0.7rem",
    },
    [theme.breakpoints.down(350)]: {
      height: "40px",
    },
  },

  vector1: {
    margin: "0 0 0.4rem 0.4rem",
  },
  vector2: {
    margin: "3rem 0 0 0",
    justifyContent: "space-evenly",
    display: "flex",
    width: "100%",
  },

  img2: {
    height: "45px",
    [theme.breakpoints.down(440)]: {
      height: "22px",
    },
    [theme.breakpoints.down(350)]: {
      height: "15px",
    },
  },
  img3: {
    height: "30px",
    [theme.breakpoints.down(440)]: {
      height: "25px",
    },
    [theme.breakpoints.down(350)]: {
      height: "20px",
    },
    [theme.breakpoints.down(290)]: {
      height: "15px",
    },
  },

  mobile: {
    marginTop: "8rem",
    position: "absolute",
    height: "630px",
    [theme.breakpoints.down(440)]: {
      height: "400px",
    },
    [theme.breakpoints.down(350)]: {
      height: "300px",
    },
    [theme.breakpoints.down(290)]: {
      height: "200px",
    },
  },

  grid_second: {
    height: "18rem",
    width: "100%",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    marginTop: "9rem",
  },

  mobile1: {
    height: "400px",
    marginTop: "5rem",
    [theme.breakpoints.down(440)]: {
      height: "350px",
    },
    [theme.breakpoints.down(350)]: {
      height: "250px",
    },
    [theme.breakpoints.down(290)]: {
      height: "150px",
    },
  },

  grid_second1: {
    position: "absolute",
    right: "0rem",
    top: "63rem",
    height: "15rem",
    width: "100vw",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    [theme.breakpoints.down(440)]: {
      top: "54rem",
    },
    [theme.breakpoints.down(350)]: {
      top: "45.5rem",
    },
    [theme.breakpoints.down(290)]: {
      top: "39rem",
    },
  },

  grid_second1_typo: {
    fontSize: "1.8rem",
    fontWeight: 500,
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    [theme.breakpoints.down(440)]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.down(290)]: {
      fontSize: "1rem",
    },
  },

  mainBx: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10rem",
  },

  boxmid: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    marginTop: "3rem",
    // marginBottom: "3rem",
    [theme.breakpoints.down(700)]: {
      marginTop: "3rem",
      alignItems: "justify",
    },
  },

  imgbox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    padding: "0 9rem 0 9rem",
    [theme.breakpoints.down(856)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      width: "100%",
      padding: "0 4rem 0 4rem",
    },
  },

  boxmidfirsttypo: {
    color: "#4a4a4a",
    fontSize: "1.2rem",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    padding: "0 7rem 5rem 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
      textAlign: "justify",
      fontSize: "1rem",
    },
  },

  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    textAlign: "justify",
    padding: "4rem 7rem 0 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "2rem 2rem 2rem 2rem",
      textAlign: "justify",
      fontSize: "1rem",
    },
  },
  bulletbox: {
    textAlign: "justify",
    width: "88%",
    padding: "2rem 0rem 0rem 10rem",
    [theme.breakpoints.down(856)]: {
      padding: "2rem 2rem 2rem 2rem",
    },
  },
  bulletboxText: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    textAlign: "justify",
    padding: "3rem 0 0 0",
    [theme.breakpoints.down(856)]: {
      textAlign: "justify",
      padding: "2rem 0 0 0 ",
      fontSize: "1rem",
    },
  },
  boxend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "justify",
    padding: "3rem",
    backgroundColor: "#231f20",
    textAlign: "center",
    [theme.breakpoints.down(856)]: {
      padding: "1.5rem 1.5rem 1.5rem 1.5rem",
    },
  },

  boxend1typo: {
    color: "white",
    fontSize: "1.2rem",
    fontWeight: "lighter",
    fontFamily: "Open Sans",
    textAlign: "center",
    padding: "4rem 7rem 3rem 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "2rem 0rem 1rem 0rem",
      fontSize: "1rem",
      textAlign: "justify",
    },
  },

  boxend2typo: {
    fontSize: "1.2rem",
    fontWeight: "lighter",
    textAlign: "center",
    padding: "0 7rem 4rem 7rem",
    color: "#359db6",
    [theme.breakpoints.down(856)]: {
      padding: "1.5rem 0rem 2rem 0rem",
      // textAlign: "justify",
    },
    [theme.breakpoints.down(420)]: {
      padding: "1rem 0rem 2rem 0rem",
      textAlign: "justify",
      fontSize: "1rem",
    },
  },
}));

const Homepage = () => {
  const classes = useStyles();

  const theme = useTheme();
  const paramRef = useParams().ref || null;
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (paramRef) {
      setWithExpiry("ref", paramRef, 24 * 7);
    }
  }, [paramRef]);

  const handleClickSingUp = () => {
    const storedRef = getWithExpiry("ref") || null;

    if (storedRef) {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      window.open(signupLink, "_blank");
    } else {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      window.open(signupLink, "_blank");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const redirectToAbout = () => {
    window.location.pathname = "/about";
  };

  const bulletPoints = [
    "Equality: Promoting equal access and opportunities for all.",
    "Build Wealth: Providing avenues to grow personal wealth.",
    "Interest-free Finance: Granting access to finance without the burden of interest.",
    "Establish Companies: Enabling the creation of companies within the Metaverse.",
    "Develop Asset Protection Trusts: Facilitating the establishment of trusts to safeguard assets.",
    "Low Tax: Benefiting from a simple and low tax framework.",
    "Financial Education: Offering resources to enhance financial literacy.",
  ];

  const communityBullet = [
    {
      id: 0,
      head: "Virtual Social Spaces:",
      sub: " We will provide dedicated virtual social spaces where users can gather, interact, and engage with one another. These spaces may include virtual lounges, meeting areas, or customizable virtual environments where users can socialize, exchange ideas, and build relationships.",
    },
    {
      id: 1,
      head: "Events and Gatherings: ",
      sub: "Our platform will host a variety of virtual events and gatherings, such as conferences, workshops, and social gatherings. These events will offer opportunities for users to network, share knowledge, and collaborate on common interests or goals.",
    },
    {
      id: 2,
      head: "Group Activities and Projects:",
      sub: "We encourage users to form interest-based groups, clubs, or communities within the Cogito Metaverse. These groups can engage in collaborative activities, projects, or discussions centred around specific topics, fostering a sense of belonging and shared purpose.",
    },
    {
      id: 3,
      head: "Cross-Cultural Exchange:",
      sub: "The Cogito Metaverse will facilitate cross-cultural exchange by connecting users from diverse backgrounds. Through interactive features, language translation capabilities, and cultural showcases, we aim to create an environment where users can learn from one another, appreciate different perspectives, and celebrate cultural diversity.",
    },
    {
      id: 4,
      head: "User-Generated Content:",
      sub: "Our platform will empower users to create and share their own content, whether it's educational resources, immersive experiences, or virtual art. This user-generated content will contribute to the richness and diversity of the community, encouraging creativity, collaboration, and active participation.",
    },
    {
      id: 5,
      head: "Communication Tools:",
      sub: "We will provide robust communication tools, including voice chat, messaging systems, and forums, to facilitate seamless interaction and collaboration among users. These tools will enable users to connect, exchange ideas, and support one another within the community.",
    },
    {
      id: 6,
      head: "Feedback and Engagement Channels:",
      sub: "We value the input and ideas of our users. We will establish feedback channels, such as surveys, user forums, or suggestion boxes, to actively involve the community in shaping the evolution of the Cogito Metaverse. User feedback will be considered and implemented whenever possible, fostering a sense of ownership and collective progress.",
    },
  ];

  return (
    <div className="classes.root">
      <Helmet>
        <title>Principality of Cogito - Homepage</title>
        <meta
          name="description"
          content="Discover a smarter way to manage your payments with Principality of Cogito's app. Get organized, boost productivity
and achieve your goals easily.
"
        />
        <link rel="canonical" href="https://cogitometaverse.com/" />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              {/* <Grid item xs={12} sm={12}> */}
              {matches ? (
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Box className={classes.mainBx}>
                      <Box>
                        <Typography variant="h6" className={classes.typo1}>
                          Build your own financial future in the
                        </Typography>
                      </Box>
                      <Grid className={classes.vector}>
                        <img
                          style={{ marginRight: "0.2rem" }}
                          src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/CogitoText.svg`}
                          alt="vector-c"
                          className={classes.img1}
                        />
                      </Grid>
                      <Box>
                        <Typography className={classes.vector1}>
                          <img
                            style={{ marginRight: "0.8rem" }}
                            src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/metaverseText.svg`}
                            alt="vector-m"
                            className={classes.img2}
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="h6"
                          className={classes.typo2}
                          style={{ textAlign: "right" }}
                          // fontWeight="bold"
                          // fontFamily="open sans"
                        >
                          Earn, spend, save, invest in digital currency
                        </Typography>
                      </Box>

                      <Box className={classes.vector2}>
                        <ThemeButton
                          title="REGISTER"
                          height={30}
                          width={120}
                          onClick={handleClickSingUp}
                        />
                        <Link href="/about">
                          <ThemeButton
                            title="LEARN MORE"
                            height={30}
                            width={135}
                            onClick={redirectToAbout}
                          />
                        </Link>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/mobile1.png`}
                        alt="mobile1"
                        className={classes.mobile1}
                      />
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={12}>
                      <Grid container direction="row">
                        <Grid item className={classes.grid_second1}>
                          <Typography className={classes.grid_second1_typo}>
                            What is the Cogito Metaverse?
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid> */}
                </Grid>
              ) : (
                <Grid item xs={12} sm={12}>
                  <Grid item className={classes.regssmargin}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                    >
                      <Grid item>
                        <Box
                          style={{ marginRight: "25rem", marginTop: "2rem" }}
                        >
                          <img
                            src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/mobile.png`}
                            alt="mobile"
                            className={classes.mobile}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box style={{ marginTop: "14rem" }}>
                          <Typography
                            variant="h4"
                            style={{ textAlign: "right", color: "white" }}
                          >
                            Build your own financial future in the
                          </Typography>
                          <Typography style={{ marginTop: "2%" }}>
                            <img
                              src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/CogitoText.svg`}
                              alt="vector-c"
                              className={classes.img1}
                            />
                          </Typography>
                          <Typography
                            style={{ marginLeft: "2.5%", marginTop: "1rem" }}
                          >
                            <img
                              src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/metaverseText.svg`}
                              alt="vector-m"
                              className={classes.img2}
                            />
                          </Typography>
                          <Typography
                            variant="h5"
                            style={{
                              textAlign: "right",
                              color: "#ff7300",
                              marginTop: "0.3rem",
                              fontWeight: "400",
                            }}
                          >
                            Earn, spend, save, invest in digital currency
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "3rem",
                            }}
                          >
                            <ThemeButton
                              title="REGISTER"
                              size={16}
                              onClick={handleClickSingUp}
                              width={200}
                            />

                            <ThemeButton
                              title="LEARN MORE"
                              size={15}
                              margin="0 0 0 4%"
                              width={200}
                              onClick={redirectToAbout}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      {/* <Grid item xs={12} sm={12}>
                      <Grid container direction="row">
                        <Box className={classes.grid_second}>
                          <Typography
                            style={{
                              fontSize: "3rem",
                              fontWeight: 500,
                              fontStyle: "normal",
                              paddingTop: "5%",
                            }}
                          >
                            What is the Cogito Metaverse?
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/* </Grid> */}
            </Box>
          </Grid>
        </Grid>
        <Box style={{ marginTop: "10%", marginBottom: matches ? "0%" : "8%" }}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box className={classes.boxmid}>
                <Box>
                  <Typography
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      fontStyle: "normal",
                      textAlign: "center",
                      color: "#359db6",
                      paddingBottom: "3%",
                      fontFamily: "Open Sans",
                      [theme.breakpoints.down(856)]: {
                        fontSize: "0.6rem",
                      },
                    }}
                  >
                    What is the Cogito Metaverse?
                  </Typography>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidfirsttypo}>
                      Welcome to the Cogito Metaverse, where we are reshaping
                      the future by embracing innovation, inclusivity, and the
                      transformative potential of digital technology. Together,
                      we can unlock new possibilities and create a world where
                      financial freedom and educational empowerment know no
                      bounds.
                    </Typography>
                  </Container>
                </Box>
                <Grid item container className={classes.imgbox}>
                  <Grid item style={{ textAlign: "center" }}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/contents/home/finance.png`}
                      height={80}
                      style={{ marginBottom: "2rem" }}
                    />
                    <Typography variant="body1" style={{ color: "#4a4a4a" }}>
                      Finance
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      textAlign: "center",
                      paddingTop: matches ? "2rem" : "0rem",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/contents/home/education.png`}
                      alt="bag"
                      height={80}
                      style={{ marginBottom: "2rem" }}
                    />
                    <Typography variant="body1" style={{ color: "#4a4a4a" }}>
                      Education
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      textAlign: "center",
                      paddingTop: matches ? "2rem" : "0rem",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/contents/home/community.png`}
                      height={80}
                      style={{ marginBottom: "2rem" }}
                    />
                    <Typography variant="body1" style={{ color: "#4a4a4a" }}>
                      Community
                    </Typography>
                  </Grid>
                </Grid>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      The Cogito Metaverse is an advanced digital space where
                      users can engage and interact in a virtual world. It
                      brings together intelligent individuals who aim to grow
                      investments, build businesses, and enhance the lives of
                      ordinary people.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      Within the Cogito Metaverse, members have the opportunity
                      to support each other, engage in free trade and finance,
                      and benefit from financial education. The platform offers
                      avenues for investment and wealth-building that differ
                      from traditional banks and investment companies. Users can
                      establish companies and asset protection trusts within the
                      Cogito Metaverse to safeguard their wealth and explore
                      innovative paths to prosperity.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      One notable aspect of the Cogito Metaverse is its global
                      accessibility, welcoming individuals from all countries.
                      It breaks down traditional barriers and fosters a sense of
                      inclusivity. The platform is continuously evolving, with
                      ongoing construction and development. Stay informed about
                      the latest updates and advancements by following the
                      Cogito Metaverse's social media channels.
                    </Typography>
                  </Container>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          direction="column"
          style={{
            background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/contents/home/home-poster.png)`,
            height: matches ? "30vh" : "100vh",
            backgroundSize: "100%",

            backgroundRepeat: "initial",
            marginBottom: matches ? "" : "5%",
          }}
        />
        <Box style={{ marginBottom: matches ? "0%" : "8%" }}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box className={classes.boxmid}>
                <Box>
                  <Typography
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      fontStyle: "normal",
                      textAlign: "center",
                      color: "#359db6",
                      paddingBottom: "3%",
                      fontFamily: "Open Sans",
                      [theme.breakpoints.down(856)]: {
                        fontSize: "0.6rem",
                      },
                    }}
                  >
                    Finance
                  </Typography>
                </Box>

                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      The Cogito Metaverse represents a groundbreaking approach
                      to managing finance within a digital realm. Utilising
                      advanced technology, it shatters traditional barriers and
                      welcomes citizens from every corner of the globe, without
                      any age or country restrictions.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      In this revolutionary space, the Cogito Metaverse empowers
                      individuals to support one another, enjoy freedom of
                      trade, and embark on investment opportunities. It offers a
                      range of advantages, including:
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    {/* <Typography
                      className={classes.boxmidsecondtypo}
                    ></Typography> */}

                    <BulletPoint
                      type={1}
                      items={bulletPoints}
                      classNameBox={classes.bulletbox}
                      classNameText={classes.bulletboxText}
                    />
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      By bringing the global market together in one place, the
                      Cogito Metaverse dismantles boundaries and eliminates
                      government control over individuals' finances. Over time,
                      users will gain access to a growing market of fractional
                      investments, allowing for both small and large investments
                      with heightened levels of security and transparency.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      The Cogito Metaverse signifies a transformative leap
                      forward, revolutionising the way finance is managed and
                      providing individuals with unprecedented opportunities for
                      financial growth and empowerment.
                    </Typography>
                  </Container>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginBottom: matches ? "0%" : "8%" }}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box className={classes.boxmid}>
                <Box>
                  <Typography
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      fontStyle: "normal",
                      textAlign: "center",
                      color: "#359db6",
                      paddingBottom: "3%",
                      fontFamily: "Open Sans",
                      [theme.breakpoints.down(856)]: {
                        fontSize: "0.6rem",
                      },
                    }}
                  >
                    Education
                  </Typography>
                </Box>

                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      The Cogito Metaverse is driven by a vision and dream to
                      harness the power of the metaverse and revolutionize
                      education. Our team is passionately working towards
                      developing a platform that will unlock a plethora of
                      advantages for learners and educators alike:
                    </Typography>
                  </Container>
                </Box>

                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ fontWeight: "normal" }}>
                        Immersive Learning Experiences:
                      </span>{" "}
                      Our goal is to create an environment where students can
                      engage in immersive and interactive learning like never
                      before. By utilizing virtual reality (VR) and augmented
                      reality (AR), they will have the opportunity to explore
                      realistic simulations, historical reenactments, and
                      scientific phenomena, enhancing their understanding and
                      retention of complex concepts.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ fontWeight: "normal" }}>
                        Access to Diverse Educational Resources:
                      </span>{" "}
                      We aspire to bring together educational content from
                      various sources, making it easily accessible to students
                      worldwide. Through our platform, students will be able to
                      access vast libraries, museums, and educational
                      institutions, gaining exposure to diverse perspectives and
                      resources that were previously out of reach. This
                      inclusivity will broaden their knowledge and deepen their
                      understanding of various subjects.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ fontWeight: "normal" }}>
                        Collaborative Learning Spaces:
                      </span>{" "}
                      We envision a platform that fosters collaboration among
                      students and educators across geographical boundaries.
                      Virtual spaces will enable students to participate in
                      group projects, discussions, and problem-solving
                      activities with peers from different backgrounds,
                      promoting cross-cultural understanding and teamwork
                      skills.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ fontWeight: "normal" }}>
                        Personalized Learning Journeys:
                      </span>{" "}
                      Our aim is to provide adaptive technologies that tailor
                      educational content to individual students' needs and
                      preferences. Through intelligent algorithms, our platform
                      will analyze students' performance and offer personalized
                      recommendations, adaptive challenges, and feedback. This
                      personalization will ensure that each student receives an
                      education that suits their unique learning style.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ fontWeight: "normal" }}>
                        Real-Time Feedback and Assessment:
                      </span>{" "}
                      We are working towards a platform that enables educators
                      to observe students' actions and progress in real time,
                      offering immediate feedback and assessment. This dynamic
                      feedback loop will empower students to identify and
                      correct mistakes promptly, leading to improved learning
                      outcomes and the cultivation of a growth mindset.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ fontWeight: "normal" }}>
                        Experiential and Practical Learning:
                      </span>{" "}
                      Our vision includes creating opportunities for hands-on,
                      practical learning experiences that are challenging to
                      replicate in traditional classrooms. Students will have
                      the chance to conduct virtual experiments, practice
                      real-world skills in simulated environments, and embark on
                      virtual field trips. This experiential approach will make
                      learning engaging, interactive, and directly applicable to
                      real-life scenarios.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      <span style={{ fontWeight: "normal" }}>
                        Lifelong Learning and Continuous Education:
                      </span>{" "}
                      We envision our platform as a lifelong learning hub,
                      enabling individuals to acquire new skills, knowledge, and
                      certifications throughout their lives. Our goal is to
                      offer opportunities for professional development,
                      vocational training, and upskilling, ensuring that
                      education remains accessible and relevant in an
                      ever-evolving world.
                    </Typography>
                  </Container>
                </Box>
                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      It is important to acknowledge that the concept of the
                      metaverse is still evolving, and its implementation in
                      education is in its early stages. As we progress, we
                      recognize the need to address challenges such as equitable
                      access, privacy and security concerns, and bridging the
                      digital divide. We are committed to meticulous planning,
                      collaboration, and ethical considerations to ensure that
                      the Cogito Metaverse can truly transform education and
                      create limitless opportunities for learners worldwide.
                      Together, we can shape a future where education knows no
                      bounds.
                    </Typography>
                  </Container>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginBottom: matches ? "0%" : "8%" }}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box className={classes.boxmid}>
                <Box>
                  <Typography
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      fontStyle: "normal",
                      textAlign: "center",
                      color: "#359db6",
                      paddingBottom: "3%",
                      fontFamily: "Open Sans",
                      [theme.breakpoints.down(856)]: {
                        fontSize: "0.6rem",
                      },
                    }}
                  >
                    Community
                  </Typography>
                </Box>

                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      In the Cogito Metaverse, we envision community as an
                      integral and thriving aspect of the platform. We
                      understand the importance of fostering connections,
                      collaboration, and shared experiences among users. Here
                      are some key ways in which we aim to create a vibrant
                      community within the Cogito Metaverse:
                    </Typography>
                  </Container>
                </Box>

                <Box>
                  <Container>
                    {/* <Typography
                      className={classes.boxmidsecondtypo}
                    ></Typography> */}

                    <BulletPoint
                      type={2}
                      items={communityBullet}
                      classNameBox={classes.bulletbox}
                      classNameText={classes.bulletboxText}
                    />
                  </Container>
                </Box>

                <Box>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      By incorporating these community-focused elements, we aim
                      to cultivate a vibrant and inclusive community within the
                      Cogito Metaverse. We believe that fostering meaningful
                      connections, collaboration, and a sense of belonging will
                      enhance the overall user experience and drive the
                      platform's growth and success.
                    </Typography>
                  </Container>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12} sm={12}>
          <Box className={classes.boxend}>
            <Container>
              <Box>
                <Typography className={classes.boxend1typo}>
                  The Cogito Metaverse is a new concept that breaks traditional
                  borders and is open to citizens from every country in the
                  world.
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.boxend2typo}>
                  The Cogito Metaverse is an evolving platform, this site is
                  under constant construction. Follow our social media pages to
                  be kept updated of developments.
                </Typography>
              </Box>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Homepage;
