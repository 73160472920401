import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import InsightsBG from "../../assets/Insight/insight.png";
import { dateToWords } from "../../utility";

const useStyles = makeStyles((theme) => ({
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  headingContainer: {
    padding: "20px 0",
    backgroundColor: "#00000018",
    top: "19vh",
  },
  title: {
    fontSize: "2.5rem",
    color: "#382823",
    fontWeight: 600,
    fontFamily: "Arial",
  },
  subtitle: {
    fontSize: "1rem",
    color: "#8F8D8D",
    fontWeight: 400,
    fontFamily: "Arial",
    padding: "10px 0",
  },
  grid_first: {
    background: `url(${InsightsBG})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    maxHeight: 256,
  },
  regssmargin: {
    margin: "6rem 0 6rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontWeight: "bold",
    fontSize: 22,
    textAlign: "center",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },
}));
const Newsletter = () => {
  const id = useParams().id;
  const [newsletter, setNewletter] = useState({});
  const [navbar, setNavbar] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_LINK}/admin/getInsightNewsLetters`, {
        filter: { id },
      })
      .then((res) => {
        if (res?.data?.data?.length) {
          setNewletter(res?.data?.data[0]);
        }
      })
      .catch((err) => console.log(err));
  }, [id]);
  useEffect(() => {}, []);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const changeBackground = () => {
    if (window.scrollY >= 140) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              {/* 1st part */}
              {matches ? (
                <Grid container direction="column" alignItems="center">
                  <Grid item className={classes.regssmargin}>
                    <Typography className={classes.regBoxmob}>
                      NEWSLETTER
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction="column">
                  <Grid item className={classes.regssmargin}>
                    <Box className={classes.regBox}>NEWSLETTER</Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        className={classes.headingContainer}
        style={{ backgroundColor: navbar ? "#fff" : "#00000018" }}
      >
        {newsletter?.template && newsletter.sent_at ? (
          <Grid item xs={12}>
            <Box>
              <Box className={classes.justifyCenter}>
                <p className={classes.title}>{`Newsletter: ${
                  months[new Date(newsletter.sent_at).getMonth() || 0]
                }`}</p>
              </Box>
              <Box className={classes.justifyCenter}>
                <p className={classes.subtitle}>{`Cogito | ${dateToWords(
                  newsletter.sent_at,
                )}`}</p>
              </Box>
            </Box>
          </Grid>
        ) : null}
      </Grid>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Box>
            <Box>{parse(newsletter?.template || "")}</Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Newsletter;
