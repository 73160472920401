import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ChangePageTitle from "../../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  regssmargin: {
    margin: "12rem 0 6rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontSize: 22,
    textAlign: "center",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 3,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 3,
  },
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    textAlign: "center",
    [theme.breakpoints.down(450)]: {
      fontSize: 22,
      letterSpacing: 12,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },

  grid_second: {
    display: "flex",
    flexDirection: "column",
    height: "38rem",
    width: "100%",
    backgroundColor: "#231f20",
    justifyContent: "space-around",
    alignItems: "center",
    color: "#359db6",
    paddingTop: "5rem",
  },
  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "5rem",
    marginBottom: "5rem",
    [theme.breakpoints.down(700)]: {
      marginTop: "3rem",
    },
  },

  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontWeight: "lighter",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    textAlign: "center",
    margin: "0rem 10rem 2rem 10rem",
    [theme.breakpoints.down(2000)]: {
      margin: "0rem 2rem 2rem 2rem",
    },
  },
}));

const BusinessDirectory = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");
  const [showDescriptionOf, setShowDescriptionOf] = useState("");

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  return (
    <div className="classes.root">
      <Helmet>
        <title>Business Directory</title>
        <meta
          name="description"
          content="Find your next virtual reality and metaverse business opportunity with the Cogito Metaverse directory. Discover VR businesses and resources for entrepreneurs."
        />
        <link
          rel="canonical"
          href="https://cogitometaverse.com/business-directory"
        />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              {/* 1st part */}
              <Grid item xs={12} sm={12}>
                {matches ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item container className={classes.header}>
                      <Grid item xs={12}>
                        <Typography className={classes.headerName}>
                          BUSINESS DIRECTORY
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Link to="/business" style={{ textDecoration: "none" }}>
                          <Typography
                            variant="body1"
                            className={classes.headerbackLink}
                          >
                            Back to Business Page
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Grid item container className={classes.header}>
                      <Grid item xs={12}>
                        <Typography className={classes.headerName}>
                          BUSINESS DIRECTORY
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Link to="/business" style={{ textDecoration: "none" }}>
                          <Typography
                            variant="body1"
                            className={classes.headerbackLink}
                          >
                            Back to Business Page
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {/* 1stpart */}
        <Grid item xs={12} sm={12}>
          <Container>
            <Grid container direction="column">
              <Box className={classes.boxmid}>
                <Box>
                  <Typography
                    className={classes.boxmidsecondtypo}
                    style={{ paddingBottom: "4rem" }}
                  >
                    The Cogito Metaverse Business Directory – Connecting
                    Businesses, Empowering Success.
                    <br />
                    <br />
                    <br />
                    <br />
                    The Cogito Metaverse Business Directory – Connecting
                    Businesses, Empowering Success.
                    <br />
                    <br />
                    <br />
                    Are you on the lookout for fresh avenues to attract new
                    customers? Is your exceptional product or service waiting to
                    make its mark in the market?
                    <br />
                    <br />
                    <br />
                    We're excited to introduce the upcoming Cogito Metaverse
                    Business Directory – your gateway to enhanced visibility and
                    increased opportunities.
                    <br />
                    <br />
                    <br />
                    Imagine having your business showcased where countless eyes
                    from around the world converge. The Cogito Metaverse
                    Business Directory will not only be a listing but a
                    testament to your dedication, creativity, and commitment to
                    excellence.
                    <br />
                    <br />
                    <br />
                    Stay tuned as we put the finishing touches on this
                    innovative feature. Your journey to reaching a broader
                    audience, boosting your sales, and achieving your business
                    goals is about to take a remarkable leap forward. The
                    business directory is located in the logged in part of the
                    Cogito Metaverse.
                  </Typography>
                </Box>
                <Box>
                  <Typography className={classes.boxmidsecondtypo}>
                    Be part of something extraordinary and{" "}
                    <a
                      target="_blank"
                      href="https://app.principalityofcogito.com/signup"
                    >
                      {" "}
                      register
                    </a>{" "}
                    your business with us!
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default BusinessDirectory;
