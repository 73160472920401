import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTheme } from "styled-components";
import { LeftContainer, Login } from "./LeftContainer/LeftContainer";
import SectionTabs from "./Tabs/Sections";
import CollapseMenu from "./LeftContainer/Sections/CollapseMenu";
import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: "35vh",
    borderBottom: "6px solid #359DB6",
    textAlign: "left",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    position: "relative",
  },
  tabs: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1%",
      overflowY: "scroll",
      height: "60%",
    },
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    letterSpacing: 15,
    fontSize: 55,
    padding: "10% 0% 4% 6%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 43,
      letterSpacing: 8,
    },
  },
  content: {
    paddingBottom: "0rem",
    textAlign: "center",
    height: "auto",
  },
  leftSide: {
    boxShadow: "2px 2px 2px 2px #F3F3F3",
    position: "sticky",
    top: "19vh",
    // maxHeight: "81vh",
    height: "81vh",
    overflowY: "scroll",
  },
}));
const HelpSection = () => {
  const theme = useTheme();
  const classes = useStyles();
  const selectedTopic = useParams();
  // const [activeTopic, setActiveTopic] = useState(selectedTopic.topic);
  const [selectedState, setSelectedState] = useState({
    section: LeftContainer[0].section,
    key: LeftContainer[0].content[0].key,
    title: LeftContainer[0].content[0].title,
  });

  const handleSections = (section, key, title) => {
    setSelectedState({ section, key, title });
  };

  useEffect(() => {
    if (selectedTopic?.topic) {
      LeftContainer?.map((section) => {
        section?.content?.map((topic) => {
          if (
            topic.topic?.toLowerCase() == selectedTopic.topic?.toLowerCase()
          ) {
            setSelectedState({
              section: section.section,
              key: topic.key,
              title: topic.title,
            });
          }
        });
      });
    }
  }, [selectedTopic]);

  return (
    <Grid container>
      <Helmet>
        <title>Help Section</title>
        <meta
          name="description"
          content="Stay informed on virtual reality and metaverse finance with Cogito's press kit for media professionals. Get resources on investment & services."
        />
        <link rel="canonical" href="https://cogitometaverse.com/presskit" />
      </Helmet>
      <Grid container direction="column" className={classes.headerContainer}>
        <Grid item container className={classes.header}>
          <Grid item xs={12}>
            <Typography className={classes.headerName}>Help Desk</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className={classes.content}>
        <Grid item xs={4} sm={2}>
          <Box className={classes.leftSide}>
            <CollapseMenu
              callBack={handleSections}
              selectedState={selectedState}
            />
          </Box>
        </Grid>
        <Grid item xs={8} sm={10} className={classes.tabs}>
          <SectionTabs selectedState={selectedState} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HelpSection;
