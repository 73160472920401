import React from "react";

export default function BulletPoint({
  items,
  classNameText,
  classNameBox,
  type = 1,
}) {
  if (type === 1) {
    return (
      <div className={classNameBox}>
        {items.map((item, index) => (
          <ol key={index} style={{ listStyle: "inherit" }}>
            <li key={index} className={classNameText}>
              {item}
            </li>
          </ol>
        ))}
      </div>
    );
  }

  if (type === 2) {
    return (
      <div className={classNameBox}>
        {items.map((item, index) => (
          <ol key={index} style={{ listStyle: "inherit" }}>
            <li key={index} className={classNameText}>
              <span style={{ fontWeight: "normal" }}>{item.head}</span>{" "}
              {item.sub}
            </li>
          </ol>
        ))}
      </div>
    );
  }
}
