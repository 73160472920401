//Login Start
const signIn = [
  {
    title: " Step 1. Download the Cogito Metaverse app on your phone.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Mobile/step1.png`,
  },
  {
    title: " Step 2. Enter your email id or username and password.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Mobile/step2.png`,
  },
  {
    title: " Step 3. Click on ‘Login’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Mobile/step3.png`,
  },
];

const signUp = [
  {
    title: "Step 1. Download the Cogito Metaverse App.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Mobile/step1.png`,
  },
  {
    title: "Step 2. Click on ‘Register Now’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Mobile/step2.png`,
  },
  {
    title: "Step 3. Enter your email id in the given field.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Mobile/step3.png`,
  },
  {
    title: "Step 4. Click on ‘Sign Up’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Mobile/step4.png`,
  },
  {
    title:
      "Step 5. Check your given email id’s inbox, an email with a 6 digit OTP should be there. If you are unable to find it then make sure that you check your spam and junk email folders.",
    media: "",
  },
  {
    title:
      "Step 6. Copy the OTP mentioned in the email you received and enter it in the mentioned field on the app.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Mobile/step6.png`,
  },
  {
    title: "Step 7. Click on ‘Verify OTP’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Mobile/step7.png`,
  },
  {
    title:
      "Step 8. Then enter your name, phone number, date of birth,address and click on the checkbox and agree to the mentioned terms and conditions.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Mobile/step8.png`,
  },
  {
    title: "Step 9. Click on ‘Next’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Mobile/step9.png`,
  },
  {
    title: "Step 10. Create a username and password for yourself. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Mobile/step10.png`,
  },
  {
    title:
      "Step 11. Make sure your password includes minimum 6 characters, at least one uppercase letter, one lowercase letter and a numeric character. ",
    media: "",
  },
  {
    title: "Step 12. Re-enter your password.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Mobile/step12.png`,
  },
  {
    title: "Step 13.  Click on ‘Register’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Mobile/step13.png`,
  },
  {
    title:
      "Your registration is complete. You can use your set username and password to sign in now.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/SignUp/Mobile/step14.png`,
  },
];

const forgotPassword = [
  {
    title: "Step 1. Open the Cogito Metaverse app on your phone.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Mobile/step1.png`,
  },
  {
    title: "Step 2. Click on Forgot Username or Password.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Mobile/step2.png`,
  },
  {
    title:
      "Step 3. Enter the email id you entered at the time of registration.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Mobile/step3.png`,
  },
  {
    title:
      "Step 4. Check your email id’s inbox for an OTP. You can check your junk or spam folder in case you don’t find the OTP email in your primary inbox.",
    media: "",
  },
  {
    title:
      "Step 5. Copy the OTP and enter it in the given space that says ‘OTP’ on the app.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Mobile/step5.png`,
  },
  {
    title: "Step 6. Click on ‘Verify’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Mobile/step6.png`,
  },
  {
    title: "Step 7. Enter a new password.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Mobile/step7.png`,
  },
  {
    title: "Step 8. Re-enter the new password.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Mobile/step8.png`,
  },
  {
    title: "Step 9. Click on ‘Reset’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Mobile/step9.png`,
  },
  {
    title: "Step 10. You can now use your new password to login to the app.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/ForgotPassword/Mobile/step10.png`,
  },
];

const mPin = [
  {
    title:
      "An MPIN is a 6 digit numeric pin that helps you secure your login into the Cogito Metaverse app. You can setup your MPIN once you register yourself on Cogito.",
    media: "",
  },

  {
    title: "Step 1. Download the Cogito Metaverse app on your phone.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Mobile/step1.png`,
  },
  {
    title: "Step 2. Sign in with your username or email id and password.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Mobile/step2.png`,
  },
  {
    title: "Step 3. You will be redirected to setup your MPIN on the app.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/MPIN/step5.png`,
  },
  {
    title: "Step 4. Enter the pin numbers of your choice in the given field.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/MPIN/step6.png`,
  },
  {
    title: "Step 5. Re-enter the same pin to confirm it.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/MPIN/step7.png`,
  },
  {
    title: "Step 6. Click on ‘Submit’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/MPIN/step8.png`,
  },
  {
    title: "Your MPIN is now ready to use whenever you open the app.",
    media: "",
  },
];

const loginSection = {
  0: signIn,
  1: signUp,
  2: forgotPassword,
  3: mPin,
};
//Login section end

{
  /* changes by pk */
}
//adding Using the Website/App section
const Kyc = [
  {
    title: " Step 1. Go to the Cogito Metaverse app on your phone. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Mobile/step1.png`,
  },
  {
    title:
      " Step 2. Enter your registered email id or username and password, and login to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Mobile/step3.png`,
  },
  {
    title:
      " Step 3. Click on the Profile button, that appears next to your name, in the top-left corner of the screen.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/2.png`,
  },
  {
    title:
      " Step 4. Under the Profile section, you will see a ‘KYC Status’ option.",
    media: "",
  },
  {
    title: " Step 5. Click on the ‘KYC Status’ button.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/3.png`,
  },
  {
    title:
      " Step 6. A screen will appear listing the steps to complete your KYC.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/4.png`,
  },
  {
    title:
      " Step 7. Complete your profile in case you have not added all your information.",
    media: "",
  },
  {
    title:
      " Step 8. Then, click on the button to agree to the terms and conditions.",
    media: "",
  },
  {
    title: " Step 9. Click on ‘Start Your KYC’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/5.png`,
  },
  {
    title: " Step 10. Click on ‘Continue’. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/6.png`,
  },
  {
    title: " Step 11. Select your ID issuing country. ",
    // media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/6.png`,
  },
  {
    title:
      " Step 12. Choose the type of document you will use as your ID proof- ID card, Passport, Residence permit, Driver’s license. ",
    // media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/6.png`,
  },
  {
    title:
      " Step 13. Upload a clear and bright photo of your ID document. First click a photo of the front side and then the back side.",
    // media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/6.png`,
  },
  {
    title: " Step 14. Click on ‘Continue’. ",
    // media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/6.png`,
  },
  {
    title: " Step 15. Read the instructions to take a selfie face scan.",
    // media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/6.png`,
  },
  {
    title:
      " Step 16. Your provided data will be under review now and it will be automatically processed.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/7.png`,
  },
  {
    title:
      " Step 17. It can take up to 48 hrs for your KYC status to be updated.",
    media: ``,
  },
  {
    title:
      " Step 18. Once completed, you can view your KYC status under the ‘KYC Status’ option in the profile section of the app.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_complete_your_KYC/mobile/8.png`,
  },
];

const profilePicture = [
  {
    title: " Step 1. Go to the Cogito Metaverse app on your phone. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/mobile/0.png`,
  },
  {
    title:
      " Step 2. Enter your registered email id or username and password, and login to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/mobile/1.png`,
  },
  {
    title:
      " Step 3. Click on the Profile button, that appears next to your name, in the top-left corner of the screen.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/mobile/2.png`,
  },
  {
    title: " Step 4. Under the Profile section, click on the camera icon. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/mobile/3.png`,
  },
  {
    title: " Step 5. Select the ‘camera’ icon option.",
    media: "",
  },
  {
    title: " Step 6. To click an image and upload, click on ‘Camera’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/mobile/4.png`,
  },
  {
    title: " Step 7. To upload an image from your device, click on ‘Gallery’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/mobile/5.png`,
  },
  {
    title:
      " Step 8. Once you have selected an image, it will automatically be uploaded as your profile photo.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_add_a_profile_picture/mobile/6.png`,
  },
];

const members = [
  {
    title: " Step 1. Go to the Cogito Metaverse app on your phone. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/mobile/0.png`,
  },
  {
    title:
      " Step 2. Enter your registered email id or username and password, and login to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/mobile/1.png`,
  },
  {
    title:
      " Step 3. Click on the Profile button, that appears next to your name, in the top-left corner of the screen.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/mobile/2.png`,
  },
  {
    title:
      " Step 4. Under the Profile section, click on the ‘Family Members’ option.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/mobile/3.png`,
  },
  {
    title:
      " Step 5. In the given field, to add a family member enter their registered Cogito username or their email id.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/mobile/4.png`,
  },
  {
    title:
      " Step 6. In case of an email id, enter the email id and click on the arrow that appears next to the field to send an invite to your family member.",
    media: "",
  },
  {
    title:
      " Step 7. In case of a username, enter the registered Cogito username and click on the arrow next to the field to send an invite to your family member.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_invite_your_family_members_to_Cogito/mobile/5.png`,
  },
  {
    title:
      " Step 8. Once your invite is accepted, your family members will be listed under this section.",
    media: "",
  },
];

const userInformation = [
  {
    title: " Step 1. Go to the Cogito Metaverse app on your phone. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/mobile/0.png`,
  },
  {
    title:
      " Step 2. Enter your registered email id or username and password, and login to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/mobile/1.png`,
  },
  {
    title:
      " Step 3. Click on the Profile button, that appears next to your name, in the top-left corner of the screen.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/mobile/2.png`,
  },
  {
    title:
      " Step 4. Under the Profile section, click on the ‘User Information’ option. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/mobile/3.png`,
  },
  {
    title:
      " Step 5. You can view all your registered information like username, full name, phone number, date of birth, email id and address here.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/mobile/4.png`,
  },
  {
    title:
      " Step 6. If you wish to edit or update some details, click on the ‘Modify’. Make the changes and click on ‘Submit’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_can_I_find_my_user_information/mobile/5.png`,
  },
];

const changePassword = [
  {
    title: " Step 1. Go to the Cogito Metaverse app on your phone.  ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/mobile/0.png`,
  },
  {
    title:
      " Step 2. Enter your registered email id or username and password, and login to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/mobile/1.png`,
  },
  {
    title:
      " Step 3. Click on the Profile button, that appears next to your name, in the top-left corner of the screen.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/mobile/2.png`,
  },
  {
    title:
      " Step 4. Under the Profile section, click on the ‘Change Password’ option. ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/mobile/3.png`,
  },
  {
    title: " Step 5. Enter your current password.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/mobile/4.png`,
  },
  {
    title: " Step 6. Then, enter your new password.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/mobile/5.png`,
  },
  {
    title: " Step 7. Re-enter your new password to confirm.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/mobile/6.png`,
  },
  {
    title: " Step 8. Click on ‘Save’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_change_password/mobile/7.png`,
  },
  {
    title: " Step 9. Your password is now changed.",
    media: "",
  },
];

const companyInformation = [
  {
    title: " Step 1. Go to the Cogito Metaverse app on your phone.  ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_company_information/mobile/0.png`,
  },
  {
    title:
      " Step 2. Enter your registered email id or username and password, and login to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_company_information/mobile/1.png`,
  },
  {
    title:
      " Step 3. Click on the second option, ‘Company’, situated at the bottom bar, next to the Home option.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_company_information/mobile/2.png`,
  },
  {
    title:
      " Step 4. This will take you to the company page where you will find all the general information related to the company registered by you in Cogito Metaverse.",
    media: "",
  },
];

const defaultCurrency = [
  {
    title: " Step 1. Go to the Cogito Metaverse app on your phone.  ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/mobile/0.png`,
  },
  {
    title:
      " Step 2. Enter your registered email id or username and password, and login to your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/mobile/1.png`,
  },
  {
    title:
      " Step 3. On the Home screen, click on the option ‘Choose Your Default Currency’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/mobile/2.png`,
  },
  {
    title:
      " Step 4. From the drop down list, choose a country to setup the default currency to your liking.  ",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/mobile/3.png`,
  },
  {
    title: " Step 5. Click on ‘Set’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/How_to_set_your_default_currency/mobile/4.png`,
  },
  {
    title: " Step 6. Your default currency is now saved. ",
    media: "",
  },
  {
    title:
      " Step 7.You can always change your default currency by following the same steps on the app. ",
    // media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Login/Mobile/step1.png`,
  },
];

const qrcode = [
  {
    title: " Step 1. Go to the Cogito Metaverse app on your phone.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code/mobile/0.png`,
  },
  {
    title:
      " Step 2. Enter your username and password to login into your account.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code/mobile/1.png`,
  },
  {
    title:
      " Step 3. Click on the Profile button, that appears next to your name, in the top-left corner of the screen.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code/mobile/2.png`,
  },
  {
    title: " Step 4. Click on the option ‘QR Code’.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code/mobile/3.png`,
  },
  {
    title: " Step 5. You can view your QR Code here.",
    media: `${process.env.REACT_APP_SPACE_KEY}/help-section/Where_is_my_QR_code/mobile/4.png`,
  },
];

const webApp = {
  0: Kyc,
  1: profilePicture,
  2: members,
  3: userInformation,
  4: changePassword,
  5: companyInformation,
  7: defaultCurrency,
  8: qrcode,
};
//end  Using the Website/App section

// Export Mobile Help Data
export const mobileData = {
  Login: loginSection,
  "Using the Website/App": webApp,
};
