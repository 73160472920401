import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Icon,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore, KeyboardArrowRight } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { LeftContainer } from "../LeftContainer";

import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  Menu: {
    color: "#FF7300",
    width: "50%",
    textAlign: "center",
  },
  Title: {
    fontSize: "0.6rem",
    padding: "1%",
    justifyContent: "start",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      padding: "0%",
    },
  },
  selectedTitle: {
    fontSize: "0.6rem",
    color: "#fff",
    padding: "1%",
    justifyContent: "start",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      padding: "0%",
    },
  },

  selectedSubMenuContainer: {
    background: "#FF7300",
    color: "#fff",
  },
}));
const CollapseMenu = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { callBack, selectedState } = props;
  const history = useHistory();
  const [selectedSection, setSelectedSection] = useState();
  const handleSelection = (section, key, title, topic) => {
    callBack(section, key, title);
    history.push(`/help-section/${topic}`);
  };
  useEffect(() => {
    setSelectedSection(selectedState.section);
  }, [selectedState]);
  return (
    <>
      <Grid direction="row" style={{ position: "stick" }}>
        {selectedState &&
          LeftContainer.map((list, index) => {
            return (
              <Accordion
                expanded={selectedSection == list.section}
                onChange={(e, expanded) => {
                  if (expanded) {
                    setSelectedSection(list.section);
                  } else {
                    setSelectedSection();
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore className={classes.Menu} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.Menu}>
                    {list.section}
                  </Typography>
                </AccordionSummary>
                {list.content.map((topic, index) => {
                  return (
                    <AccordionDetails
                      className={
                        selectedState.key == topic.key &&
                        selectedState.section == list.section
                          ? classes.selectedSubMenuContainer
                          : null
                      }
                    >
                      <Button
                        fullWidth
                        className={
                          selectedState.key == topic.key &&
                          selectedState.section == list.section
                            ? classes.selectedTitle
                            : classes.Title
                        }
                        onClick={() => {
                          handleSelection(
                            list.section,
                            topic.key,
                            topic.title,
                            topic.topic,
                          );
                        }}
                        startIcon={<KeyboardArrowRight />}
                      >
                        {topic.title}
                      </Button>
                    </AccordionDetails>
                  );
                })}
              </Accordion>
            );
          })}
      </Grid>
    </>
  );
};

export default CollapseMenu;
