import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import iconImage from "../../../assets/stocks/iconImg.svg";
import box from "../../../assets/stocks/box.png";
// import polygon2 from "../../../assets/stocks/Polygon2.png";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontSize: 22,
    textAlign: "center",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 3,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    textAlign: "center",

    [theme.breakpoints.down(450)]: {
      fontSize: 22,
      letterSpacing: 12,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },
  grid_second: {
    display: "flex",
    flexDirection: "column",
    height: "38rem",
    width: "100%",
    backgroundColor: "#231f20",
    justifyContent: "space-around",
    alignItems: "center",
    color: "#359db6",
    paddingTop: "5rem",
  },
  boxmid: {
    display: "flex",
    flexDirection: "column",
    // textAlign: "center",
    // marginBottom: "5rem",
    [theme.breakpoints.down(700)]: {
      marginTop: "3rem",
    },
  },

  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontWeight: "lighter",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    textAlign: "center",
    margin: "0rem 10rem 2rem 10rem",
    // paddingBottom: "1.5rem",
    [theme.breakpoints.down(2000)]: {
      margin: "0rem 2rem 2rem 2rem",
    },
    [theme.breakpoints.down(420)]: {
      textAlign: "center",
      fontSize: "0.9rem",
      margin: "2%",
    },
  },
  SectionTitle: {
    color: "#FF7300",
    textAlign: "center",
    fontSize: "2rem",
    fontWeight: "bold",
    padding: "4% 2% 2%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.8rem",
      paddingTop: "8%",
    },
  },
  points: {
    // background: `url(${box})`,
    background: "#FFF3D9",
    margin: "1%",
    padding: "3%",
    width: "85%",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  pointsTypo: {
    fontSize: "1rem",
    fontFamily: "open Sans",
    fontWeight: 300,
  },
  cardsGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    // alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  cards: {
    background: "#EDFBFF",
    padding: "2%",
    marginRight: "50px",
    borderRadius: "15px",
    // width: "20vw",
    [theme.breakpoints.down("xs")]: {
      marginTop: "25px",
      marginRight: "0",
      padding: " 4%",
      textAlign: "center",
    },
  },
  textLine: {
    padding: "6% 8% 4%",
    textAlign: "center",
    color: "#359DB6",
    fontSize: "1.4rem",
    fontWeight: 600,
    fontFamily: "Open Sans",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      marginTop: "4%",
    },
  },
  boxmargin: {
    margin: "4%",
  },
}));

const StockMarket = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");
  const [showDescriptionOf, setShowDescriptionOf] = useState("");

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  const data = [
    {
      id: "1",
      text: "Our platform is taking the traditional stock exchange, which has been around for over two centuries, and bringing it into the modern digital age.",
    },
    {
      id: "2",
      text: "By leveraging the best ideas from stock exchanges around the world, the Cogito Metaverse is offering a direct capital investment model for investments that were traditionally unavailable to the small investor.",
    },
    {
      id: "3",
      text: "Not only will the Cogito Metaverse Stock exchange invest in funds and companies, but it will also offer investment opportunities in different types of talent, collectibles like art and much much more.The Cogito Metaverse Stock exchange is set to launch in 2023, providing investors with a fresh and exciting way to invest in the things they believe in.",
    },
  ];

  const reasons = [
    {
      id: "1",
      text: "A people and ideas-centered stock market would prioritize investing in individuals and their potential, rather than simply buying shares in a company.",
    },
    {
      id: "2",
      text: "Investors could have the opportunity to invest in a person's career trajectory, their education, or their entrepreneurial endeavors, creating a more direct connection between investors and the people they are investing in.",
    },
    {
      id: "3",
      text: "It would also offer investment opportunities in ideas and innovations that have the potential to change the world, such as investments in research and development, social and environmental impact initiatives, or projects aimed at solving pressing global issues.",
    },
    {
      id: "4",
      text: "Such a stock market would require a significant shift in the way we think about investing and the economy, including a new way of measuring success and profitability beyond traditional financial metrics.",
    },
  ];
  return (
    <div className="classes.root">
      <Helmet>
        <title>Stock Market</title>
        <meta
          name="description"
          content="Invest in the future of finance with Cogito Metaverse - the virtual reality stock market. Explore investment opportunities and access market analysis in VR."
        />
        <link rel="canonical" href="https://cogitometaverse.com/stock-market" />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              {/* 1st part */}
              <Grid item xs={12} sm={12}>
                {matches ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item container className={classes.header}>
                      <Grid item xs={12}>
                        <Typography className={classes.headerName}>
                          STOCK
                          <br />
                          MARKET
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Link to="/business" style={{ textDecoration: "none" }}>
                          <Typography
                            variant="body1"
                            className={classes.headerbackLink}
                          >
                            Back to Business Page
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Grid item container className={classes.header}>
                      <Grid item xs={12}>
                        <Typography className={classes.headerName}>
                          STOCK MARKET
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Link to="/business" style={{ textDecoration: "none" }}>
                          <Typography
                            variant="body1"
                            className={classes.headerbackLink}
                          >
                            Back to Business Page
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.boxmargin} tyle={{ margin: "4%" }}>
          {/* 1stpart */}

          <Grid container direction="column">
            <Box className={classes.boxmid}>
              <Typography className={classes.boxmidsecondtypo}>
                The Cogito Metaverse Stock Exchange is now live and ready for
                you to start investing! We're revolutionizing the way you invest
                by offering a more tangible approach to buying shares,
                alternative investments, and micro seed funding opportunities.
                Whether you're passionate about startups, art, collectible cars,
                guitars, or any unique asset, our platform has you covered. With
                a familiar look and feel reminiscent of a traditional stock
                market, we combine cutting-edge security measures with a modern
                approach to investing, making it easier than ever to invest in
                what truly excites you.
              </Typography>
            </Box>

            <Grid item container>
              <Grid item xs={12}>
                <Typography className={classes.SectionTitle}>
                  Here’s what you need to know:
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {data.map((content, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      // sm={1}
                      // md={4}
                      // lg={3}
                      key={index}
                      className={classes.points}
                    >
                      {/* <img src={box}> */}
                      <Typography className={classes.pointsTypo}>
                        {content.text}
                      </Typography>
                      {/* </img> */}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.textLine}>
                  We believe that if we had a stock market that invested in
                  people and ideas, it would fundamentally change the way we
                  view investing and the economy as a whole.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                style={{
                  color: "#FF7300",
                  fontSize: "1.3rem",
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  padding: "4% 0% 1%",
                }}
              >
                Here’s Why:
              </Typography>
            </Grid>
            <Grid item className={classes.cardsGrid}>
              {reasons.map((data, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    key={index}
                    className={classes.cards}
                  >
                    <img src={iconImage} alt="icon_image" width={"20%"} />
                    <Grid item xs={12}>
                      <Typography className={classes.pointsTypo}>
                        {data.text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item container style={{ paddingTop: "6%" }}>
            <Grid item xs={12}>
              <Typography className={classes.boxmidsecondtypo}>
                A people and ideas-centered stock market would also have the
                potential to level the playing field, providing opportunities
                for underrepresented individuals and communities who may have
                been excluded from traditional investment opportunities.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.boxmidsecondtypo}>
                At Cogito Metaverse, we believe that a stock market that invests
                in people and ideas represents a major shift in the way we view
                the economy and investing. It prioritizes human potential and
                societal impact over profits and creates a more equitable and
                inclusive system for all. Join us in investing in the future of
                innovation and progress, and be a part of the change.
              </Typography>
            </Grid>
          </Grid>
          {/* </Container> */}
        </Box>
      </Grid>
    </div>
  );
};

export default StockMarket;
