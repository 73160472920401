import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import bg from "../../assets/finance_bg.png";
import Quote from "../../assets/quote-image/quote1.png";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      letterSpacing: 7,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },
  content: { paddingTop: "6rem", paddingBottom: "6rem", textAlign: "center" },
  bodyContent: {
    fontFamily: "Open Sans",
    fontSize: "1.2rem",
    paddingBottom: "2%",
    [theme.breakpoints.down(420)]: {
      textAlign: "justify",
      fontSize: "1rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  orangeBox: {
    // borderWidth: 2,
    // borderColor: "#FF7300",
    borderRadius: "35px",
    marginTop: "3rem",
    border: "2px solid  #FF7300",
    marginBottom: "3rem",
  },
  orangeBoxText: {
    fontFamily: "Open Sans",
    fontSize: "1.3rem",
    fontStyle: "italic",
    // color: "white",
    padding: "2rem",
    [theme.breakpoints.down(420)]: {
      padding: "1rem 1rem 1.2rem 1rem",
    },
  },
  label: {
    position: "absolute",
    top: "662px",
    left: "230px",
    textAlign: "center",
    [theme.breakpoints.down("1000")]: {
      left: "53px",
    },
  },
}));

const Finance = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container>
      <Helmet>
        <title>Finance</title>
        <meta
          name="description"
          content="Explore the future of finance at Cogito Metaverse. Learn about virtual reality and metaverse investment opportunities and financial services."
        />
        <link rel="canonical" href="https://cogitometaverse.com/finance" />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    FINANCE
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    FINANCE
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Container className={classes.content}>
        <Grid item container spacing={5} justifyContent="center">
          <Grid item container xs={11} className={classes.orangeBox}>
            <div className={classes.label}>
              <img src={Quote} style={{ width: "90px", height: "100px" }}></img>
            </div>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <Typography className={classes.orangeBoxText}>
                Finance is not merely about making money. It’s about achieving
                our deep goals and protecting the fruits of our labor. It’s
                about stewardship and, therefore, about achieving the good
                society.”
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Typography
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: "bolder",
                  fontSize: "1.6rem",
                }}
              >
                Robert J. Shiller
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.bodyContent}>
              The Cogito Metaverse believes in the transparent and ethical
              funding of business. The 0% interest loans will provide an
              alternative sustainable funding source for Cogito Metaverse
              businesses and organisations.
            </Typography>
            <Typography className={classes.bodyContent}>
              We believe in investing in not just buildings and big business but
              in people and talent.
            </Typography>
            <Typography className={classes.bodyContent}>
              By doing finance in a whole new way, it opens the world to the
              possibilities of working together in a way that working region by
              region does not achieve.
            </Typography>
            <Typography className={classes.bodyContent}>
              The Cogito Metaverse Partnership Scheme develops the ability to
              strengthen ties between businesses, industry and innovation
              developers. This change of focus from simply a money transaction
              to a focus on partnership and growth enables a potential return on
              investment in areas that have not traditionally been seen as
              positive opportunities for investment.
            </Typography>
            <Typography className={classes.bodyContent}>
              The Cogito Metaverse has a desire to diversify investment
              portfolios into areas of skills, talent, research and innovation
              investments alongside the more traditional business and property
              investments.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        direction="column"
        style={{
          background: `url(${bg})`,
          height: matches ? "20vh" : "80vh",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // marginBottom: matches ? "" : "3%",
          // marginTop: matches ? "3%" : "9%",
        }}
      ></Grid>
    </Grid>
  );
};

export default Finance;
