import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    height: "100vh",
    width: "100vw",
    backgroundColor: "#ccc",
  },
}));

const Login = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainDiv}>
      <Grid container direction="column"></Grid>
    </div>
  );
};

export default Login;
