import * as RiIcons from "react-icons/ri";
export const menuItems = [
 {
  title: "HOME",
  to: "/",
 },
 {
  title: "ABOUT",
  // to: '/about',
  iconClosed: <RiIcons.RiArrowDownSFill />,
  iconOpened: <RiIcons.RiArrowUpSFill />,
  submenu: [
   {
    title: "ABOUT",
    to: "/about",
   },
   {
    title: "E-RESIDENCY",
    to: "/e-residency",
    nestediconClosed: <RiIcons.RiArrowDownSFill />,
    nestediconOpened: <RiIcons.RiArrowUpSFill />,
    submenu: [
     {
      title: "REGISTER",
      to: `${process.env.REACT_APP_URL_SIGNUP}`,
      target: "_blank",
     },
     // {
     // 	title: "THE COG",
     // 	to: "/cog",
     // },
     // {
     // 	title: "ABOUT",
     // 	to: "/about",
     // },
    ],
   },
   {
    title: "INFLUENCERS",
    to: "/influencers",
   },
   {
    title: "AFFIIATE PROGRAM",
    to: "/affiliate-program",
   },
   {
    title: "LEGAL",
    to: "/legal",
   },
  ],
 },
 {
  title: "BANKING",
  // to: '/banking',
  iconClosed: <RiIcons.RiArrowDownSFill />,
  iconOpened: <RiIcons.RiArrowUpSFill />,
  submenu: [
   {
    title: "BANKING",
    to: "/banking",
    nestediconClosed: <RiIcons.RiArrowDownSFill />,
    nestediconOpened: <RiIcons.RiArrowUpSFill />,
    submenu: [
     {
      title: "COG",
      to: "/cog",
     },
     {
      title: "CASH BACK COG",
      to: "/cbc",
     },
     {
      title: "APP/POS",
      to: "/app-pos",
     },
     {
      title: "ESCROW PAYMENTS",
      to: "/escrowPayments",
     },
     {
      title: "UNIVERSAL INCOME",
      to: "/universal-income",
     },
     {
      title: "HOW THE URI WORKS",
      to: "/how-the-uri-works",
     },
     {
      title: "FINANCE",
      to: "/finance",
     },
     {
      title: "FEE/TAXES",
      to: "/fees-taxes",
     },
     {
      title: "SECURITY",
      to: "/security",
     },
     // {
     // title: "INVESMENT",
     // to: "/banking",
     // },
    ],
   },
   {
    title: "BUSINESS",
    to: "/business",
    nestediconClosed: <RiIcons.RiArrowDownSFill />,
    nestediconOpened: <RiIcons.RiArrowUpSFill />,
    submenu: [
     {
      title: "STOCK EXCHANGE",
      to: "/stock-market",
     },
     {
      title: "BUSINESS DIRECTORY",
      to: "/business-directory",
     },
     {
      title: "COMPANY OFFICE",
      to: "/company-office",
     },
     //  {
     //   title: "FINANCE",
     //   to: "/finance",
     //  },
     //  {
     //   title: "FEE/TAXES",
     //   to: "/fees-taxes",
     //  },
    ],
   },
   {
    title: "TRUST",
    to: "/trusts",
   },
  ],
 },
 {
  title: "FAQ",
  to: "/faq",
 },
 {
  title: "REGISTRATION",
  to: "/registration",
 },
 {
  title: "POCKET MONEY",
  to: "/pocketMoney",
 },
 {
  title: "CONTACT",
  // to: '/contact',
  iconClosed: <RiIcons.RiArrowDownSFill />,
  iconOpened: <RiIcons.RiArrowUpSFill />,
  submenu: [
   {
    title: "CONTACT",
    to: "/contact",
   },
   {
    title: "PRESS KIT",
    to: "presskit",
   },
   {
    title: "WORK WITH US",
    to: "/work_with_us",
   },
   {
    title: "INSIGHTS",
    to: "/insights",
   },
  ],
 },
 {
  title: "HELP",
  to: "/help-section",
 },
];
