const personalData = [
  "Pay for goods and services",
  "Earn Cog by selling goods and services with other Cogito Metaverse members",
  "Low-cost money transfers around the globe",
  "A censorship-resistant investment portfolio",
  "Invest in alternative streams of revenue",
  "Learn how to create a secure financial future",
  "Universal income",
];

export default personalData;
