import React from "react";
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import securityData from "./securityData";
import { isMobile } from "react-device-detect";
import HenryBG from "../../assets/security/henry.png";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      letterSpacing: 7,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },
  content: { paddingTop: "6rem", paddingBottom: "6rem", textAlign: "center" },
  bodyContent: {
    fontFamily: "Open Sans",
    fontWeight: "ligter",
    fontSize: "1.2rem",
    paddingBottom: "4%",
    [theme.breakpoints.down(420)]: {
      textAlign: "justify",
      fontSize: "1rem",
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
  },
  title: {
    color: "#FF7300",
    font: "Open Sans",
    fontWeight: "bold",
    fontSize: "2.2rem",
    [theme.breakpoints.down(420)]: {
      fontSize: "2rem",
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
  },
}));

const Security = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  return (
    <Grid container>
      <Helmet>
        <title>Security</title>
        <meta
          name="description"
          content="Stay secure in the virtual reality and metaverse world with Cogito Metaverse. Learn about measures to protect your assets and personal information in VR."
        />
        <link rel="canonical" href="https://cogitometaverse.com/security" />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    SECURITY
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    SECURITY
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        style={{
          background: `url(${HenryBG})`,
          height: matches ? "40vh" : "80vh",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // marginBottom: matches ? "" : "3%",
          marginTop: matches ? "3%" : "9%",
        }}
      >
        <Grid
          item
          // md={9}
          sm={9}
          style={{
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'flex-end',
            paddingLeft: "35%",
            // paddingTop: isMobile ? "10%" : "",
            // marginTop: isMobile ? "" : "15%",
          }}
          // xs={5}
        ></Grid>
        <Grid
          item
          // md={9}
          sm={7}
          style={{
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'flex-end',
            paddingRight: "1%",
            paddingTop: matches ? "10%" : "",
            marginTop: matches ? "" : "17%",
          }}
        >
          <Typography
            style={{
              fontFamily: "Arial",
              fontStyle: "italic",
              color: "white",
              fontWeight: "lighter",
              textAlign: "right",
              fontSize: isMobile ? "1.2rem" : "1.5rem",
            }}
          >
            “No government can guarantee security. It can only tax production,
            distribution and service and gradually crush the power to pay taxes.
            That settles nothing. It only uses up the gains of the past and
            postpones the developments of the future.”
          </Typography>
        </Grid>
      </Grid>

      <Container className={classes.content}>
        <Grid container spacing={6} justifyContent="center">
          {securityData.map((item) => {
            return (
              <Grid item xs={12}>
                <Typography className={classes.title}>{item.title}</Typography>
                {item.data.map((para) => {
                  return (
                    <Typography className={classes.bodyContent}>
                      {para}
                    </Typography>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Grid>
  );
};

export default Security;
