import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
  Container,
  Paper,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import Remittance from "../../assets/CoinDesign/remittance.png";
import CbcCoin from "../../assets/CoinDesign/CogPremium.png";
import CogCoin from "../../assets/CoinDesign/Cog.png";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  coinSection: {
    height: 800,
    width: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: "2rem",
    backgroundColor: "#fff",
    paddingBottom: "4rem",
  },

  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      letterSpacing: 7,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },
  content: { paddingTop: "6rem", paddingBottom: "6rem", textAlign: "center" },
  imageContent: {
    paddingTop: "2.5rem",
    paddingBottom: "15rem",
    textAlign: "center",
  },
  bodyContent: {
    fontFamily: "Open Sans",
    fontSize: "1.2rem",
    // textAlign: "justify",
    [theme.breakpoints.down(420)]: {
      fontSize: "1rem",
      textAlign: "justify",
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
  },
  imageSubs1: {
    // fontFamily: "Roboto",
    // fontWeight:"lighter"
    color: "#FE7300",
    fontSize: "1.2rem",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "600",
    marginTop: "1rem",
  },
  imageSubs2: {
    // fontFamily: "Roboto",
    // fontWeight:"lighter"
    color: "#231F20",
    fontSize: "1.2rem",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "300",
    marginTop: "1.5rem",
  },
  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0rem 8rem 2rem 8rem",
    [theme.breakpoints.down(600)]: {
      padding: "2rem 2rem 2rem 2rem",
    },
  },
  title: {
    color: "#FF7300",
    fontFamily: "Open sans",
    fontSize: "2rem",
    fontWeight: "bold",
    marginTop: "3rem",
  },
  imgbox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    padding: "0 9rem 0 9rem",
    [theme.breakpoints.down(856)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      width: "100%",
      padding: "0 4rem 0 4rem",
    },
  },
}));

const CBC = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  return (
    <Grid container>
      <Helmet>
        <title>CBC</title>
        <meta
          name="description"
          content="Join the world of metaverse finance with CBC, the cryptocurrency at Cogito Metaverse. Invest and trade in a decentralized digital asset for the VR economy."
        />
        <link rel="canonical" href="https://cogitometaverse.com/cbc" />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>CBC</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    Cash-Back Cog
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Container paddingLeft="24px" paddingRight="24px">
        <Grid
          container
          spacing={4}
          style={{
            paddingLeft: "15%",
            paddingRight: "15%",
            paddingTop: "10%",
            textAlign: "justify",
          }}
        >
          <Grid item xs={12}>
            <Typography
              style={{
                lineHeight: "20px",
                marginBottom: "1rem",
                fontSize: "1.3rem",
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "Open Sans",
              }}
            >
              We live to grow your investments, businesses, and the lives of
              ordinary people.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ paddingTop: "2.5%", paddingBottom: "5%" }}
          >
            <Typography
              style={{
                fontSize: "1.1rem",
                fontWeight: "light",
                textAlign: "center",
                fontFamily: "Open Sans",
              }}
            >
              We are proud to introduce one of Cogito Metaverse’s new payment
              method features! Introducing the Cash Back Cog (CBC)! It has one
              hundred percent (100%) backing! Backed by bank guarantee,
              government-issued bonds, and Euro cash. And It is audited
              regularly.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.imageContent}>
        <Grid item container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={3}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/cogPage/cogImage1.png`}
              style={{ width: "50%" }}
              alt="Buying_Selling"
            />
            <Typography variant="body1" className={classes.imageSubs}>
              Day to day buying and selling of goods and services
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/cogPage/cogImage2.png`}
              style={{ width: "50%" }}
              alt="Digital_Currency"
            />
            <Typography variant="body1" className={classes.imageSubs}>
              A digital currency not a Bitcoin or Cryptocurrency
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <img
              src={Remittance}
              style={{ width: "50%" }}
              alt="Remittance_Pic"
            />

            <Typography variant="body1" className={classes.imageSubs}>
              Can be used for remittance. A fast way to get or send back money
              home
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* <Paper
          elevation={10}
        >
		  	<Container className={classes.imageContent}>
         
				<Grid item container spacing={4} justifyContent="center">
				<Grid item xs={12} sm={12}>
            <Grid container direction="column">
               
                      <Typography
                        style={{
                          fontSize: matches ? "1rem" : "2rem",
                          fontFamily: "Arial",
                          fontWeight: 700,
                          fontStyle: "normal",
                          textAlign: "center",
                          color: "#359DB6",
                          paddingBottom: "4%",
                          paddingTop: "4rem",
                          textDecoration: "underline",
                          textUnderlineOffset: "1rem",
                        }}
                      >
                        Our Primary Currency
                      </Typography>
                
                 
            </Grid>
          </Grid>
					<Grid item xs={12} sm={3}>
						<img
						src={CogCoin}
							style={{ width: "50%" }}
							alt="Buying_Selling"
						/>
						<Typography
                            variant="body1" className={classes.imageSubs1}
                            >
                              Cog
                            </Typography>
							<Typography
                              variant="body1" className={classes.imageSubs2}
                            >
                              Cog is assets and cash-backs. You also have the
                              option to buy or sell for cash.
                            </Typography>
					</Grid>
					<Grid item xs={12} sm={3}>
						<img
						src={CbcCoin}
							style={{ width: "50%" }}
							alt="Digital_Currency"
						/>
						<Typography
                              variant="body1" className={classes.imageSubs1}
                            >
                              Cog Premium
                            </Typography>
							<Typography
                             variant="body1" className={classes.imageSubs2}
                            >
                              The Cog Premium is 100% backed by cash, bank
                              guarantees and government bonds, and also,
                              e-residence have the option to buy and sell using
                              Euro.
                            </Typography>
					</Grid>
					</Grid>
					</Container>
        </Paper> */}
    </Grid>
  );
};

export default CBC;
