export const dateToWords = (UTC) => {
  if (UTC) {
    let date_str = UTC.split("T");
    let dateSplit = date_str[0].split("-");
    const months = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthNumber = dateSplit[1].split("");
    let monthDigit = monthNumber[0] == "0" ? monthNumber[1] : dateSplit[1];
    let month = months[monthDigit];
    let day = dateSplit[2];
    let year = dateSplit[0];
    return `${month} ${day}, ${year}`;
  }
};
