const legaldata = [
  {
    title: "What is a haven?",
    content:
      "Cogito is a tax free zone with common-sense laws and minimal fees for establishing and conducting your business. It has no reporting agreements with any other jurisdiction.",
  },
  {
    title: "Who controls Cogito?",
    content:
      "The Constitution of the Principality of Cogito details the legalities around the structure of power and control for Cogito. In simple terms the Principality of Cogito is divided into eight regions which each have an elected board in a democratic process by the Digital Citizens.",
  },
  {
    title: "What is Digital Citizenship?",
    content:
      "Digital citizenship is a commitment to be involved in the society which is Cogito. Becoming a digital citizen gives you the right and ability to register a trust and company and thus access the safe Haven of the Principality of Cogito.",
  },
  {
    title: "Why do I need to register?",
    content:
      "The initial registration gives you the ability to keep abreast of developments in the Principality of Cogito and start earning Cogito Cog from the affiliate program. Once you have completed the KYC and AML process, you will be able to buy and sell goods and services using the POS app on your mobile device.",
  },
];

export default legaldata;
