import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import data from "./data";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/FaqImages/website%20images_FAQ-min.jpg)`,
    backgroundPosition: "center",
    height: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  regssmargin: {
    margin: "19rem 0 19rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontWeight: "bold",
    fontSize: 22,
    textAlign: "center",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 11,
  },
  grid_second: {
    height: "15rem",
    width: "100%",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    marginTop: "9rem",
  },
  pointtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
  },
  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    textAlign: "left",
  },
  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3rem 8rem 2rem 8rem",
    [theme.breakpoints.down(600)]: {
      marginTop: "2rem",
      padding: "3rem 2rem 2rem 2rem",
    },
  },
  boxmidfirsttypo: {
    color: "#359DB6",
    fontSize: "1.8rem",
    fontWeight: 500,
    fontStyle: "normal",
    textAlign: "center",
    // marginTop: "3rem",
    padding: "0 12rem -15rem 12rem",
    // [theme.breakpoints.down(700)]: {
    //   padding: "0 0rem 2rem 0rem",
    // },
  },
  boxmidfirsttypo2: {
    color: "#FF7300",
    fontSize: "1.3rem",
    fontWeight: 600,
    fontStyle: "normal",
    textAlign: "center",
    // marginTop: "3rem",
    padding: "0 12rem 2rem 12rem",
    [theme.breakpoints.down(700)]: {
      padding: "0 0rem 2rem 0rem",
    },
  },
  boxmidfirsttypo3: {
    // color: "#FF7300",
    fontSize: "1.3rem",
    fontWeight: 600,
    fontStyle: "normal",
    textAlign: "center",
    // marginTop: "3rem",
    padding: "0 12rem 2rem 12rem",
    [theme.breakpoints.down(700)]: {
      padding: "0 0rem 2rem 0rem",
    },
  },
  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontWeight: 300,
    fontStyle: "normal",
    textAlign: "center",
    margin: "0rem 6rem 2rem 6rem",
    // [theme.breakpoints.down(2000)]: {
    //   margin: "0rem 8rem 2rem 2rem",
    // },
  },
  mobileBox: {
    color: "#359DB6",
    fontSize: 16,
    fontWeight: 700,
    fontStyle: "normal",
    marginBottom: "2rem",
  },
  webBox: {
    color: "#359DB6",
    fontSize: 18,
    fontWeight: 700,
    fontStyle: "normal",
    marginBottom: "2rem",
  },

  boximgtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  boxDialoge: {
    height: "4.5rem",
    width: "10rem",
    borderRadius: 12,
    fontFamily: "Open Sans ",
    fontWeight: 900,
    color: "#359db6",
    fontSize: "0.9rem",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "row",
    // padding: "0 5% 0 8% ",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto auto auto auto",
    transitionProperty: "height,width,font-size",
    transitionDuration: "0.5s",
    [theme.breakpoints.up(400)]: {
      "&:hover": {
        width: "12rem",
        height: "5rem",
        fontSize: "1.2rem",
      },
    },

    [theme.breakpoints.down(400)]: {
      width: "10rem",
    },
  },

  outerbox: {
    width: "16rem",
    height: "7rem",
  },

  boxDialogetypo: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "1rem",
    fontWeight: 800,
    padding: "1rem 0 1rem 0",
    color: "#359DB6",
    textAlign: "center",
    paddingLeft: 8,
    textDecoration: "none",
  },
  mobBoxDialogetypo: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "0.8",
    fontWeight: 800,
    paddingLeft: "2rem",
    color: "#359DB6",
    textAlign: "left",
    paddingLeft: 8,
    textDecoration: "none",
  },
  title: {
    fontFamily: "Open Sans",
    color: "#359DB6",
    marginTop: "5%",
    textAlign: "center",
    fontWeight: "bold",
    [theme.breakpoints.down(650)]: {
      fontSize: 2,
    },
  },
}));

const Faq = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  return (
    <div className="classes.root">
      <Helmet>
        <title>FAQ</title>
        <meta
          name="description"
          content="Get answers to your virtual reality and metaverse finance and investment questions with the Cogito Metaverse FAQ. Find helpful information and resources."
        />
        <link rel="canonical" href="https://cogitometaverse.com/faq" />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              <Grid item xs={12} sm={12}>
                {matches ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBoxmob}>FAQ</Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBox}>FAQ</Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box style={{ background: "#E2E2E2" }}>
          <Container style={{ marginTop: "6%", marginBottom: "6%" }}>
            {data.map((item, index) => {
              return (
                <Accordion style={{ marginTop: "0.5%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "FF7300" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{
                        color: "#FF7300",
                        fontSize: matches ? "1.4rem" : "1.5rem",
                        fontStyle: "Open Sans",
                        paddingTop: "1%",
                        paddingBottom: "1%",
                        paddingLeft: "2%",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      backgroundColor: "#E2E2E2",
                      border: "none",
                      display: "block",
                    }}
                  >
                    {item.subtitle.map((subtitle, index) => {
                      return (
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              style={{
                                color: "#FF7300",
                                fontFamily: "Open Sans",
                                marginTop: matches ? "15%" : "1%",
                                marginBottom: matches ? "3%" : "1%",
                                fontWeight: "bold",
                                textAlign: "center",
                                fontSize: "1.3rem",
                                paddingBottom: "3%",
                              }}
                            >
                              {subtitle.name}
                            </Typography>
                          </Grid>
                          {subtitle.data.map((data) => {
                            return (
                              <Grid item xs={12}>
                                <Typography
                                  style={{
                                    marginBottom: "1%",
                                    fontFamily: "Open Sans",
                                    textAlign: matches ? "justify" : "center",
                                    fontSize: matches ? "1rem" : "1.2rem",
                                    fontWeight: "lighter",
                                  }}
                                >
                                  {data}
                                </Typography>
                              </Grid>
                            );
                          })}
                          <Grid item container style={{ marginTop: "3%" }}>
                            {subtitle.numpoints
                              ? subtitle.numpoints.map((numpoints, index) => {
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "5%",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: "Open Sans",
                                          textAlign: "left",
                                          fontWeight: "lighter",
                                          fontSize: "1.2rem",
                                          // fontWeight: "lighter",
                                        }}
                                      >
                                        {" "}
                                        <span
                                          style={{
                                            color: "#FF7300",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {numpoints.num}
                                        </span>
                                        {" " + numpoints.points}
                                      </Typography>
                                    </Grid>
                                  );
                                })
                              : null}
                          </Grid>
                          <Grid item container style={{ marginTop: "3%" }}>
                            {subtitle.points
                              ? subtitle.points.map((point) => {
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "5%",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: "Open Sans",
                                          textAlign: "left",
                                          fontSize: "1.2rem",
                                          fontWeight: 100,
                                        }}
                                      >
                                        <span style={{ paddingRight: "2%" }}>
                                          {"\u2022"}{" "}
                                        </span>
                                        {"   "}
                                        {point}
                                      </Typography>
                                    </Grid>
                                  );
                                })
                              : null}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Container>
        </Box>
      </Grid>
    </div>
  );
};

export default Faq;
