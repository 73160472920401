import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Container,
  Link,
} from "@material-ui/core";
import ThemeButton from "../../Component/common/button";
// import KCarousel from "./Carousel";
import Company from "../../assets/business/company.png";
import Business from "../../assets/business/business-directory.png";
import Stock from "../../assets/business/stock.png";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/hero.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  regssmargin: {
    margin: "19rem 0 19rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontWeight: "bold",
    fontSize: 22,
    textAlign: "center",
    padding: " 1rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },

  regBox2: {
    fontSize: 34,
    fontFamily: "Arial Black",
    fontWeight: 900,
    color: "#fff",
    textAlign: "center",
    alignItems: "center",
    padding: " 0rem 4rem 5rem 4rem",
  },
  regBox3: {
    fontSize: 22,
    fontFamily: "Open Sans",
    fontWeight: 200,
    color: "#fff",
    textAlign: "center",
    alignItems: "center",
    padding: " 0rem 4rem 2rem 4rem",
  },

  typo2: {
    fontSize: "1.3em",
    color: "#ff7300",
    [theme.breakpoints.down(440)]: {
      fontSize: "1em",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "0.8em",
    },
  },

  vector: {
    margin: "1rem 0 1rem 0",
  },

  img1: {
    height: "80px",
    [theme.breakpoints.down(440)]: {
      height: "60px",
    },
    [theme.breakpoints.down(350)]: {
      height: "40px",
    },
  },

  vector1: {
    margin: "0 0 1rem 0",
  },
  vector2: {
    margin: "3rem 0 0 0",
  },

  img2: {
    height: "30px",
    [theme.breakpoints.down(440)]: {
      height: "20px",
    },
    [theme.breakpoints.down(350)]: {
      height: "15px",
    },
  },
  img3: {
    height: "30px",
    marginBottom: 12,
    [theme.breakpoints.down(440)]: {
      height: "25px",
    },
    [theme.breakpoints.down(350)]: {
      height: "20px",
    },
    [theme.breakpoints.down(290)]: {
      height: "20px",
    },
  },

  mobile: {
    marginTop: "5rem",
    position: "absolute",
    height: "630px",
    [theme.breakpoints.down(440)]: {
      height: "400px",
    },
    [theme.breakpoints.down(350)]: {
      height: "300px",
    },
    [theme.breakpoints.down(290)]: {
      height: "200px",
    },
  },
  midBoximg: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem  0rem 1rem 7rem",
  },
  midBoxtypohead: {
    text: "left",
    fontSize: "1.5rem",
    fontFamily: "Open Sans",
    fontWeight: 800,
    padding: " 1rem 0 2rem 0rem",
  },
  midBoxtyposub: {
    text: "left",
    fontSize: "1.3rem",
    fontFamily: "Open Sans",
    fontWeight: 300,
    padding: " 0rem 0 3rem 0rem",
  },
  last_banner: {
    height: "15rem",
    width: "100%",
    backgroundColor: "#FF7300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    marginTop: "1rem",
  },
  lastboxTypo: {
    fontfamily: "Open Sans",
    fontSize: "1.5rem",
    fontWeight: 900,
    fontStyle: "normal",
    textAlign: "center",
  },
  grid_second: {
    display: "flex",
    flexDirection: "column",
    height: "8rem",
    width: "100%",
    backgroundColor: "#231f20",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    marginTop: "9rem",
  },

  mobile1: {
    height: "450px",
    marginTop: "5rem",
    [theme.breakpoints.down(440)]: {
      height: "350px",
    },
    [theme.breakpoints.down(350)]: {
      height: "250px",
    },
    [theme.breakpoints.down(290)]: {
      height: "150px",
    },
  },

  grid_second1: {
    position: "absolute",
    right: "0rem",
    top: "63rem",
    height: "15rem",
    width: "100vw",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    [theme.breakpoints.down(440)]: {
      top: "54rem",
    },
    [theme.breakpoints.down(350)]: {
      top: "45.5rem",
    },
    [theme.breakpoints.down(290)]: {
      top: "39rem",
    },
  },

  grid_second1_typo: {
    fontSize: "1.8rem",
    fontWeight: 500,
    textAlign: "center",
    fontStyle: "normal",
    [theme.breakpoints.down(440)]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.down(290)]: {
      fontSize: "1rem",
    },
  },

  mainBx: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "5rem",
  },
  pointtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down(450)]: {
      margin: "0 1.5rem 0 1.5rem",
    },
  },
  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    textAlign: "left",
    marginLeft: "0.8rem",
    [theme.breakpoints.down(420)]: {
      fontSize: "1rem",
    },
  },
  boxmid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "3rem",
    // marginBottom: "3rem",
    [theme.breakpoints.down(700)]: {
      marginTop: "3rem",
    },
  },
  boxmid2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "3rem",
    backgroundColor: "#ccc",
    paddingTop: 85,
    [theme.breakpoints.down(700)]: {
      marginTop: "3rem",
    },
  },
  mobileBox: {
    color: "#359DB6",
    fontFamily: "Open Sans",
    fontSize: "1.4rem",
    fontWeight: "bold",
    fontStyle: "normal",
    marginBottom: "2rem",
    [theme.breakpoints.down(420)]: {
      fontSize: "1.2rem",
    },
  },
  webBox: {
    color: "#359DB6",
    fontSize: 20,
    fontWeight: 700,
    fontStyle: "normal",
    marginBottom: "2rem",
  },

  imgbox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down(856)]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  rowbox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: " 0 6rem 0 6rem",
  },
  boximgtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  boxmidfirsttypo: {
    color: "#359DB6",
    fontSize: "2.5rem",
    fontWeight: 500,
    fontStyle: "normal",
    textAlign: "center",
    marginTop: "3rem",
    marginBottom: "3rem",
    padding: "0 12rem -15rem 12rem",
    [theme.breakpoints.down(700)]: {
      padding: "0 0rem 2rem 0rem",
    },
  },

  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: 200,
    fontStyle: "normal",
    textAlign: "center",
    margin: "0rem 10rem 2rem 10rem",
    [theme.breakpoints.down(2000)]: {
      margin: "0rem 1.2rem 1.2rem 1.2rem",
      fontSize: "1rem",
      textAlign: "justify",
    },
  },
  boxend: {
    display: "flex",
    alignItems: "center",
    padding: "3rem",
    backgroundColor: "#231f20",
  },

  boxend1typo: {
    color: "#FFFFFF",
    fontSize: "1.2rem",
    fontWeight: "400",
    padding: "4rem 12rem 3rem 12rem",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },

  boxend2typo: {
    fontSize: "1.2rem",
    fontWeight: "400",
    padding: "0 12rem 5rem 12rem",
    color: "#359db6",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },
  btnbox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  mobboxDialoge: {
    height: "6.5rem",
    width: "6.5rem",
    borderRadius: 30,
    color: "#fff",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
  boxDialoge: {
    height: "13rem",
    width: "13rem",
    borderRadius: 30,
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#fff",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    boxShadow: "5px 5px 5px 5px #85827d",
    transitionProperty: "width,height",
    transitionDuration: "0.5s",
    "&:hover": {
      height: "15rem",
      width: "15rem",
      zIndex: 10,
      boxShadow: "none",
    },
  },

  boxDialogeLinks: {
    height: "4.5rem",
    width: "12.5rem",
    borderRadius: 8,
    fontFamily: "Open Sans ",
    fontWeight: 900,
    color: "#359db6",
    fontSize: "0.9rem",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "row",
    // padding: "0 5% 0 8% ",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto auto auto auto",
    transitionProperty: "background",
    transitionDuration: "0.5s",

    [theme.breakpoints.down(450)]: {
      width: "10rem",
      height: "4rem",
    },
  },

  boxDialogetypo: {
    fontFamily: "Open Sans",
    fontSize: "0.9rem",
    fontWeight: 600,
    textAlign: "left",
    color: "#359DB6",
    paddingTop: 4,
    paddingLeft: 4,
    textDecoration: "none",
  },
}));

const BusinessPage = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");
  const [showDescriptionOf, setShowDescriptionOf] = useState("");

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);
  const redirectToCompany = () => {
    window.location.pathname = "/company-office";
  };
  return (
    <div className="classes.root">
      <Helmet>
        <title>Business</title>
        <meta
          name="description"
          content="Take your virtual reality and metaverse business to the next level with Cogito Metaverse. Learn about financial services and tools for VR businesses."
        />
        <link rel="canonical" href="https://cogitometaverse.com/business" />
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              {/* 1st part */}
              <Grid item xs={12} sm={12}>
                {matches ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBoxmob}>BUSINESS</Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBox}>BUSINESS</Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box style={{ marginTop: "6%", marginBottom: "6%" }}>
          <Grid item xs={12} sm={12}>
            {matches ? (
              <Box direction="column">
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "2rem 1rem 0rem 1rem",
                    justifyContent: "space-around",
                  }}
                >
                  <a
                    href="/stock-market"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Box className={classes.boxDialogeLinks}>
                      <img
                        src={Stock}
                        alt="stock"
                        height={40}
                        style={{
                          paddingRight: "0.8rem",
                        }}
                      />
                      <Typography className={classes.boxDialogetypo}>
                        Stock
                        <br /> Exchange
                      </Typography>
                    </Box>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                    }}
                    href="/business-directory"
                  >
                    <Box className={classes.boxDialogeLinks}>
                      <img
                        src={Business}
                        alt="business_directory"
                        height={35}
                        style={{
                          paddingRight: "0.8rem",
                        }}
                      />
                      <Typography className={classes.boxDialogetypo}>
                        Business <br /> Directory
                      </Typography>
                    </Box>
                  </a>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "2rem 1rem 0rem 1rem",
                    justifyContent: "space-around",
                  }}
                >
                  <a
                    style={{
                      textDecoration: "none",
                    }}
                    href="/company-office"
                  >
                    <Box className={classes.boxDialogeLinks}>
                      <img
                        src={Company}
                        alt="comapny"
                        height={35}
                        style={{
                          paddingRight: "0.8rem",
                          // paddingLeft: '0.8rem',
                        }}
                      />
                      <Typography className={classes.boxDialogetypo}>
                        Company <br />
                        office
                      </Typography>
                    </Box>
                  </a>

                  <a
                    style={{
                      textDecoration: "none",
                    }}
                    href="/finance"
                  >
                    <Box className={classes.boxDialogeLinks}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/bankingimages/finance.png`}
                        alt="economyicon"
                        height={35}
                        style={{
                          paddingRight: "0.8rem",
                        }}
                      />
                      <Typography className={classes.boxDialogetypo}>
                        Finance
                      </Typography>
                    </Box>
                  </a>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "2rem 0rem 0rem 0rem",
                    justifyContent: "space-around",
                  }}
                >
                  <a
                    style={{
                      textDecoration: "none",
                    }}
                    href="/fees-taxes"
                  >
                    <Box className={classes.boxDialogeLinks}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/bankingimages/fees.png`}
                        alt="businessicon"
                        height={28}
                        style={{
                          paddingRight: "0.8rem",
                        }}
                      />
                      <Typography className={classes.boxDialogetypo}>
                        Fees/Taxes
                      </Typography>
                    </Box>
                  </a>
                </Box>
              </Box>
            ) : (
              <Container>
                <Box direction="column">
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",

                      alignItems: "center",
                      padding: "2rem 4rem 2rem 4rem",
                      justifyContent: "space-around",
                      // width: '80%'
                    }}
                  >
                    <Box
                      style={{
                        width: "14rem",
                        height: "5rem",
                      }}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                        }}
                        href="/stock-market"
                      >
                        <Box className={classes.outerBox}>
                          <Box className={classes.boxDialogeLinks}>
                            <img
                              src={Stock}
                              alt="stock"
                              height={40}
                              style={{
                                paddingRight: "0.8rem",
                              }}
                            />
                            {/* <Typography className={classes.boxDialogetypo}> */}
                            Stock Exchange
                            {/* </Typography> */}
                          </Box>
                        </Box>
                      </a>
                    </Box>
                    <Box
                      style={{
                        width: "14rem",
                        height: "5rem",
                      }}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                        }}
                        href="/business-directory"
                      >
                        <Box className={classes.outerBox}>
                          <Box className={classes.boxDialogeLinks}>
                            <img
                              src={Business}
                              alt="business_directory"
                              height={38}
                              style={{
                                paddingRight: "0.8rem",
                              }}
                            />
                            {/* <Typography className={classes.boxDialogetypo}> */}
                            Business Directory
                            {/* </Typography> */}
                          </Box>
                        </Box>
                      </a>
                    </Box>
                    <Box
                      style={{
                        width: "14rem",
                        height: "5rem",
                      }}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                        }}
                        href="/company-office"
                      >
                        <Box className={classes.outerBox}>
                          <Box className={classes.boxDialogeLinks}>
                            <img
                              src={Company}
                              alt="comapny"
                              height={38}
                              style={{
                                paddingRight: " 0.8rem",
                              }}
                            />
                            {/* <Typography className={classes.boxDialogetypo}> */}
                            Company office
                            {/* </Typography> */}
                          </Box>
                        </Box>
                      </a>
                    </Box>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "2rem 14rem 2rem 14rem",
                      justifyContent: "space-around",
                      // width: '80%'
                    }}
                  >
                    <Box
                      style={{
                        width: "14rem",
                        height: "5rem",
                      }}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                        }}
                        href="/finance"
                      >
                        <Box className={classes.outerBox}>
                          <Box className={classes.boxDialogeLinks}>
                            <img
                              src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/bankingimages/finance.png`}
                              alt="economyicon"
                              height={38}
                              style={{
                                paddingRight: "0.8rem",
                              }}
                            />
                            {/* <Typography className={classes.boxDialogetypo}> */}
                            Finance
                            {/* </Typography> */}
                          </Box>
                        </Box>
                      </a>
                    </Box>
                    <Box
                      style={{
                        width: "14rem",
                        height: "5rem",
                      }}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                        }}
                        href="/fees-taxes"
                      >
                        <Box className={classes.outerBox}>
                          <Box className={classes.boxDialogeLinks}>
                            <img
                              src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/bankingimages/fees.png`}
                              alt="businessicon"
                              height={28}
                              style={{
                                paddingRight: "0.8rem",
                              }}
                            />
                            {/* <Typography className={classes.boxDialogetypo}> */}
                            Fees/Taxes
                            {/* </Typography> */}
                          </Box>
                        </Box>
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Container>
            )}
          </Grid>
          {/* 2ndpart */}

          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box className={classes.boxmid}>
                <Box direction="column" className={classes.pointtext}>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                      height={32}
                      style={{
                        paddingRight: "1.8rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Companies Register
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                      height={32}
                      style={{ paddingRight: "1.8rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Sell goods and services and earn Cog
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                      height={32}
                      style={{ paddingRight: "1.8rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Global network of members
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                      height={32}
                      style={{ paddingRight: "1.8rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Innovative investment opportunities
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                      height={32}
                      style={{ paddingRight: "1.8rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Business Directory
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                      height={32}
                      style={{ paddingRight: "1.8rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Stock Market
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* 3rdpart */}
          <Grid item xs={12} sm={12}>
            <Container>
              <Grid container direction="column">
                {matches ? (
                  <Box className={classes.boxmid}>
                    <Box>
                      <Typography className={classes.boxmidsecondtypo}>
                        Businesses that are registered with the Cogito Metaverse
                        have the ability to Immediately start earning Cog, both
                        from purchasing or selling goods and services and
                        investing. By becoming part of the Cogito Metaverse, you
                        open up your business to a whole new market of customers
                        and in turn gain a competitive advantage. Businesses
                        will also be promoted to the rest of the metaverse
                        through the business directory.
                      </Typography>
                      <Typography
                        className={classes.boxmidsecondtypo}
                        style={{ paddingTop: "1rem" }}
                      >
                        Please note that your earnings in the Cogito Metaverse
                        would most likely be taxable in your country of
                        residence, however businesses that are registered in the
                        Metaverse will have access to the Cogito stock exchange
                        in the near future. We do not give advice on tax but
                        there are a growing number of tax consultants joining
                        the Cogito Metaverse globally.
                      </Typography>
                      <Typography
                        className={classes.boxmidsecondtypo}
                        style={{ paddingTop: "1rem" }}
                      >
                        Members of the Cogito Metaverse will have access to
                        interest free credit facilities, be able to settle debt
                        using cog and have the ability to open up a new network
                        of customers. Business customers will be able to not
                        only offer goods and services for Cog but to restructure
                        their finances to free up their cash reserves.
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "2rem 0 2rem 0rem",
                      }}
                    >
                      <ThemeButton
                        title="Companies Office"
                        size={16}
                        onClick={redirectToCompany}
                        width={200}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box className={classes.boxmid}>
                    <Box>
                      <Typography className={classes.boxmidsecondtypo}>
                        Businesses that are registered with the Cogito Metaverse
                        have the ability to Immediately start earning Cog, both
                        from purchasing or selling goods and services and
                        investing. By becoming part of the Cogito Metaverse, you
                        open up your business to a whole new market of customers
                        and in turn gain a competitive advantage. Businesses
                        will also be promoted to the rest of the metaverse
                        through the business directory.
                      </Typography>
                      <Typography className={classes.boxmidsecondtypo}>
                        Please note that your earnings in the Cogito Metaverse
                        would most likely be taxable in your country of
                        residence, however businesses that are registered in the
                        Metaverse will have access to the Cogito stock exchange
                        in the near future. We do not give advice on tax but
                        there are a growing number of tax consultants joining
                        the Cogito Metaverse globally.
                      </Typography>
                      <Typography className={classes.boxmidsecondtypo}>
                        Members of the Cogito Metaverse will have access to
                        interest free credit facilities, be able to settle debt
                        using cog and have the ability to open up a new network
                        of customers. Business customers will be able to not
                        only offer goods and services for Cog but to restructure
                        their finances to free up their cash reserves.
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "2rem 0 2rem 0rem",
                      }}
                    >
                      <ThemeButton
                        title="Companies Office"
                        size={16}
                        onClick={redirectToCompany}
                        width={200}
                      />
                    </Box>
                  </Box>
                )}
              </Grid>
            </Container>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default BusinessPage;
