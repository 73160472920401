export const LeftContainer = [
  {
    section: "Login",
    content: [
      {
        title: "How to sign in the Cogito Metaverse?",
        key: 0,
        topic: "signIn",
        detail: ["Login", "0", "How to sign in the Cogito Metaverse?"],
      },
      {
        title: "How to sign up to Cogito Metaverse?",
        key: 1,
        topic: "signUp",
        detail: ["Login", "1", "How to sign up to Cogito Metaverse?"],
      },
      {
        title: "What to do when you forget your password?",
        key: 2,
        topic: "forgetPassword",
        detail: ["Login", "2", "What to do when you forget your password?"],
      },
      {
        title: "What is an MPIN?",
        key: 3,
        topic: "MPIN",
        detail: ["Login", "3", "What is an MPIN?"],
      },
    ],
  },
  {
    section: "Escrow",
    content: [
      {
        title:
          "How to make an Escrow Payment on Cogito- initiation to payment transfer? ",
        key: 0,
        topic: "escrowPayment",
        detail: [
          "Escrow",
          "0",
          "How to make an Escrow Payment on Cogito- initiation to payment transfer?",
        ],
      },
      {
        title: "How to initiate an Escrow Payment on Cogito?",
        key: 1,
        topic: "initiateEscrow",
      },
      {
        title: "How to cancel an Escrow Payment request I have sent?",
        key: 2,
        topic: "CancelEscrowPayment",
      },
      {
        title:
          "How to cancel or deny an Escrow Payment request I have received?",
        key: 3,
        topic: "denyEscrowPayment",
      },
      {
        title: "Where can I find all my completed Escrow Payments on Cogito? ",
        key: 4,
        topic: "completedEscrowPayments",
      },
      {
        title: "Where can I find all my Escrow Payment requests on Cogito?",
        key: 5,
        topic: "EscrowRequests",
      },
    ],
  },
  {
    section: "Using the Website/App",
    content: [
      {
        title: "How to complete your KYC?",
        key: 0,
        topic: "Kyc",
        detail: ["Using the Website/App", "0", "How to complete your KYC?"],
      },
      {
        title: "How to add a profile picture?",
        key: 1,
        topic: "profilePicture",
        detail: ["Using the Website/App", "1", "How to add a profile picture?"],
      },
      {
        title: "How to invite your family members to Cogito?",
        key: 2,
        topic: "members",
        detail: [
          "Using the Website/App",
          "2",
          "How to invite your family members to Cogito?",
        ],
      },
      {
        title: "Where can I find my user information?",
        key: 3,
        topic: "userInformation",
        detail: [
          "Using the Website/App",
          "3",
          "Where can I find my user information?",
        ],
      },
      {
        title: "How do I change password?",
        key: 4,
        topic: "changePassword",
        detail: ["Using the Website/App", "4", "How do I change password?"],
      },
      {
        title: "Where is my company information?",
        key: 5,
        topic: "companyInformation",
        detail: [
          "Using the Website/App",
          "5",
          "Where is my company information?",
        ],
      },
      {
        title: "Where is my QR code Poster?",
        key: 6,
        topic: "qrposter",
        detail: ["Using the Website/App", "6", "Where is my QR code Poster?"],
      },
      {
        title: "How to set your default currency?",
        key: 7,
        topic: "defaultCurrency",
        detail: [
          "Using the Website/App",
          "7",
          "How to set your default currency?",
        ],
      },
      {
        title: "Where is my QR code?",
        key: 8,
        topic: "qrcode",
        detail: ["Using the Website/App", "8", "Where is my QR code?"],
      },
    ],
  },
];
