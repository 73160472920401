import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import feeTaxesData from "./feeTaxesData";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      letterSpacing: 7,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
  },
  content: {
    paddingTop: "6rem",
    paddingBottom: "6rem",
    textAlign: "center",
    [theme.breakpoints.down(420)]: {
      textAlign: "justify",
    },
  },
  bodyContent: {
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    fontSize: "1.2rem",
    [theme.breakpoints.down(420)]: {
      fontSize: "1rem",
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
  },
  title: {
    color: "#359DB6",
    font: "Open Sans",
    fontWeight: "bold",
    [theme.breakpoints.down(420)]: {
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
  },
  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontWeight: "lighter",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    textAlign: "center",
    margin: "2rem 10rem 2rem 10rem",
    [theme.breakpoints.down(2000)]: {
      margin: "1rem 0.5rem 1rem 0.5rem",
      // letterSpacing: 1
      fontSize: "1rem",
      textAlign: "justify",
    },
  },
}));

const FeesAndTaxes = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  return (
    <Grid container>
      <Helmet>
        <title>Fees&Taxes</title>
        <meta
          name="description"
          content="Discover the latest fees and taxes on VR & metaverse products & services at Cogito Metaverse. Get insights into the costs of VR & metaverse finance."
        />
        <link rel="canonical" href="https://cogitometaverse.com/fees-taxes" />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}> TAX</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>TAX</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Container className={classes.content}>
        <Grid container spacing={6} justifyContent="center">
          {feeTaxesData.map((item) => {
            return (
              <Grid item xs={12}>
                <Typography
                  className={classes.title}
                  style={{ fontSize: matches ? "1.8rem" : "3rem" }}
                >
                  {item.title}
                </Typography>
                <img
                  src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/shadow.jpg`}
                  alt="shadow"
                  width={"100%"}
                />
                <Typography className={classes.bodyContent}>
                  {item.data}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12}>
            <Typography
              className={classes.title}
              style={{ fontSize: matches ? "1.8rem" : "3rem" }}
            >
              Advantages of a Flat Tax System:
            </Typography>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/shadow.jpg`}
              alt="shadow"
              width={"100%"}
            />
            <Box>
              <Typography className={classes.boxmidsecondtypo}>
                <span style={{ fontWeight: "normal" }}> Simplicity:</span> A
                flat tax system offers simplicity and ease of administration
                since it typically involves a single tax rate applicable to all
                participants. This reduces complexity, eliminates the need for
                complicated tax brackets, and simplifies tax filing and
                compliance procedures.
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.boxmidsecondtypo}>
                <span style={{ fontWeight: "normal" }}> Transparency:</span>{" "}
                With a flat tax, the tax rate is clear and transparent to all
                participants. There are no varying tax rates based on income
                levels or financial activities, making it easier for individuals
                and businesses to understand their tax obligations.
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.boxmidsecondtypo}>
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  Incentives for Compliance:{" "}
                </span>
                A low and uniform tax rate, can discourage tax evasion by
                providing fewer incentives for individuals or corporations to
                engage in tax avoidance schemes. If the tax rate is reasonable
                and widely perceived as fair, participants may be more inclined
                to comply with their tax obligations.
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.boxmidsecondtypo}>
                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  Economic Efficiency:{" "}
                </span>
                Supporters of flat tax systems argue that they promote economic
                growth and efficiency by reducing tax distortions and creating a
                more level playing field. With a flat tax, there are fewer tax
                incentives that can influence economic decisions, potentially
                leading to improved resource allocation and productivity.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Typography className={classes.boxmidsecondtypo}>
            Regarding the viability for large corporations to evade taxation in
            the Cogito Metaverse, it's important to consider the specific
            regulations and enforcement mechanisms implemented within the
            virtual realm. While a low tax rate might disincentivize tax
            evasion, the effectiveness of taxation enforcement in preventing
            evasion by big corporations would depend on the robustness of the
            governance and regulatory frameworks in place. The specific benefits
            and fairness of a flat tax system in the Cogito Metaverse depends on
            its structure, governance, and the broader context of the virtual
            realm's operations.
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.boxmidsecondtypo}>
            The Cogito tax system is simply a transaction tax. If you buy or
            sell goods or services, if you move value you pay a tax. Most taxes
            are under 5%.
          </Typography>
        </Box>
      </Container>
    </Grid>
  );
};

export default FeesAndTaxes;
