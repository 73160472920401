import React from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/common/footer/footer";
import Home from "../Homepage/homePage";
import Aboutus from "../Aboutpage/aboutPage";
import Banking from "../Bankingpage/bankingPage";
import Faq from "../FAQpage/faqPage";
import Registration from "../Registrationpage/registrationPage";
import Contact from "../Contactpage/contactPage";
import Login from "../Loginpage/loginPage";
import MemberCitizen from "../Member_Citizen/member_citizen";
import Cog from "../Cog/cog";
import PersonalBanking from "../PersonalBanking/personalBanking";
import AppPos from "../AppPos/appPos";
import UniversalIncome from "../UniversalIncome/universalIncome";
import Finance from "../Finance/finance";
import FeesAndTaxes from "../FeesTaxes/feesTaxes";
import Security from "../Security/security";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Influencer_page from "../InfluencersPage/influencer_page";
import LegalPage from "../LegalPage/legalpage";
import AffiliatePage from "../AffiliateProgramPage/affiliatepage";
import BusinessPage from "../BusinessPage/businessPage";
import StockMarket from "../BusinessPage/sub_business/stock_market";
import BusinessDirectory from "../BusinessPage/sub_business/business_directory";
import CompanyOffice from "../BusinessPage/sub_business/company_office";
import TrustsPage from "../TrustPage/trustPage";
import PressKit from "../PressKit/pressKit";
import Workus from "../Workus/workus";
import Insight from "../InsightPage/insight";
import CBC from "../CBC/cbc";
import Newsletter from "../Newsletter/Newsletter";
import PageNotFound from "../404Page/404Page";
import PocketMoney from "../PocketMoney/pocketMoney";
import EscrowPage from "../EscrowPage/EscrowPage";
import HelpSection from "../HelpSection/HelpSection";
import HowItWorks from "../HowItWorks/howItswork";

const Mainpage = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/ref/:ref" component={Home} />
        <Route exact path="/about" component={Aboutus} />
        <Route exact path="/banking" component={Banking} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/how-the-uri-works" component={HowItWorks} />
        <Route exact path="/registration" component={Registration} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/e-residency" component={MemberCitizen} />
        <Route exact path="/influencers" component={Influencer_page} />
        <Route exact path="/legal" component={LegalPage} />
        <Route exact path="/cog" component={Cog} />
        <Route exact path="/personal-banking" component={PersonalBanking} />
        <Route exact path="/app-pos" component={AppPos} />
        <Route exact path="/universal-income" component={UniversalIncome} />
        <Route exact path="/finance" component={Finance} />
        <Route exact path="/fees-taxes" component={FeesAndTaxes} />
        <Route exact path="/security" component={Security} />
        <Route exact path="/affiliate-program" component={AffiliatePage} />
        <Route exact path="/business" component={BusinessPage} />
        <Route exact path="/stock-market" component={StockMarket} />
        <Route exact path="/business-directory" component={BusinessDirectory} />
        <Route exact path="/company-office" component={CompanyOffice} />
        <Route exact path="/trusts" component={TrustsPage} />
        <Route exact path="/presskit" component={PressKit} />
        <Route exact path="/work_with_us" component={Workus} />
        <Route exact path="/insights" component={Insight} />
        <Route exact path="/insight/:id" component={Insight} />
        <Route exact path="/insight/newsletter/:id" component={Newsletter} />
        <Route exact path="/cbc" component={CBC} />
        <Route exact path="/pageNotFound" component={PageNotFound} />
        <Route exact path="/pocketMoney" component={PocketMoney} />
        <Route exact path="/escrowPayments" component={EscrowPage} />
        <Route exact path="/help-section" component={HelpSection} />
        <Route exact path="/pageNotFound" component={PageNotFound} />
        <Route exact path="/pocketMoney" component={PocketMoney} />
        <Route exact path="/escrowPayments" component={EscrowPage} />
        <Route exact path="/help-section/:topic" component={HelpSection} />

        <Redirect to="/pageNotFound" />
      </Switch>
      <Footer />
    </Router>
  );
};

export default Mainpage;
