const feeTaxesData = [
  {
    title: "Tax Liability",
    data: "Please note that tax laws vary by country, and the information provided here is not intended as specific tax advice. The following suggestions are general in nature and may be applicable to most individuals in various jurisdictions. However, it is advisable to seek independent tax advice or make your own inform  nationality.    ",
  },
  {
    title: "General Tax Considerations",
    data: "If you engage in any form of financial trading or investment, depending on your nationality and place of residence, you may be subject to taxation on the profits you generate. It's important to note that 'profit' in this context doesn't necessarily refer to cash in your bank account in the currency in which you pay taxes. It often includes unrealized gains reflected on paper.",
  },
  {
    title: "Cogito Legal Structure",
    data: "The legal structure offered by the Principality of Cogito aims to provide you with the flexibility to determine when and if you need to fulfil your tax obligations in your home country. The recommended structure for traders or investors typically involves the use of a discretionary trust and a Cogito trading company.    ",
  },
  {
    title: "Why does Cogito have a flat tax structure?    ",
    data: "The concept of a flat tax system in the Cogito Metaverse, suggests that all participants are subject to the same tax rate, regardless of their income or financial activities within the virtual realm. This approach is often touted as a fair and straightforward taxation model. However, it's important to note that the viability and fairness of any tax system, including a flat tax, can be subject to different perspectives and considerations.",
  },
];

export default feeTaxesData;
