import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
  makeStyles,
  Button,
  Container,
} from "@material-ui/core";
import { getWithExpiry } from "../../assets/utility";
import ThemeButton from "../../Component/common/button";
import registrationStep1 from "../../assets/registration/registrationStep1.png";
import registrationStep2 from "../../assets/registration/registrationStep2.png";
import registrationStep3 from "../../assets/registration/registrationStep3.png";
import registrationStep4 from "../../assets/registration/registrationStep4.png";
import ChangePageTitle from "../../Component/Header/ChangePageTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid_first: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/Mask%20Group%20%281%29-min.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "200%",
      top: "50%",
    },
  },

  typo1: {
    [theme.breakpoints.down(350)]: {
      fontSize: "0.8em",
    },
  },

  regssmargin: {
    margin: "19rem 0 19rem 0",
  },
  regBoxmob: {
    color: "#FF7300",
    fontFamily: "Arial ",
    fontWeight: "bold",
    fontSize: "1.2rem",
    textAlign: "center",
    padding: " 0rem 2rem 1rem 2rem",
    letterSpacing: 7,
  },
  regBox: {
    color: "#FF7300",
    fontSize: 65,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
    alignItems: "center",
    letterSpacing: 15,
  },
  typo2: {
    fontSize: "1.3em",
    color: "#ff7300",
    [theme.breakpoints.down(440)]: {
      fontSize: "1em",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "0.8em",
    },
  },

  vector: {
    margin: "1rem 0 1rem 0",
  },

  img1: {
    height: "80px",
    [theme.breakpoints.down(440)]: {
      height: "60px",
    },
    [theme.breakpoints.down(350)]: {
      height: "40px",
    },
  },

  vector1: {
    margin: "0 0 1rem 0",
  },
  vector2: {
    margin: "3rem 0 0 0",
  },

  img2: {
    height: "30px",
    [theme.breakpoints.down(440)]: {
      height: "20px",
    },
    [theme.breakpoints.down(350)]: {
      height: "15px",
    },
  },
  img3: {
    height: "30px",
    marginBottom: 12,
    [theme.breakpoints.down(440)]: {
      height: "25px",
    },
    [theme.breakpoints.down(350)]: {
      height: "20px",
    },
    [theme.breakpoints.down(290)]: {
      height: "20px",
    },
  },

  mobile: {
    marginTop: "5rem",
    position: "absolute",
    height: "630px",
    [theme.breakpoints.down(440)]: {
      height: "400px",
    },
    [theme.breakpoints.down(350)]: {
      height: "300px",
    },
    [theme.breakpoints.down(290)]: {
      height: "200px",
    },
  },

  grid_second: {
    height: "15rem",
    width: "100%",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    marginTop: "9rem",
    [theme.breakpoints.down(400)]: {
      marginTop: "0",
      padding: "4%",
    },
  },

  mobile1: {
    height: "450px",
    marginTop: "5rem",
    [theme.breakpoints.down(440)]: {
      height: "350px",
    },
    [theme.breakpoints.down(350)]: {
      height: "250px",
    },
    [theme.breakpoints.down(290)]: {
      height: "150px",
    },
  },

  grid_second1: {
    position: "absolute",
    right: "0rem",
    top: "63rem",
    height: "15rem",
    width: "100vw",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    [theme.breakpoints.down(440)]: {
      top: "54rem",
    },
    [theme.breakpoints.down(350)]: {
      top: "45.5rem",
    },
    [theme.breakpoints.down(290)]: {
      top: "39rem",
    },
  },

  grid_second1_typo: {
    fontSize: "1.8rem",
    fontWeight: 500,
    textAlign: "center",
    fontStyle: "normal",
    [theme.breakpoints.down(440)]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.down(290)]: {
      fontSize: "1rem",
    },
  },

  mainBx: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "5rem",
  },
  pointtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    // padding: '0 0rem 0 10rem'
  },
  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    textAlign: "left",
  },
  boxmid: {
    display: "flex",

    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",
    // marginBottom: "5rem",
    [theme.breakpoints.down(700)]: {
      marginTop: "3rem",
    },
  },

  mobileBox: {
    color: "#359DB6",
    fontSize: 18,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    marginBottom: "2rem",
  },
  webBox: {
    color: "#359DB6",
    fontFamily: "Open Sans",
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: "2rem",
  },

  imgbox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    padding: "0 8rem 4rem 8rem",
    [theme.breakpoints.down(856)]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  boximgtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  boxmidfirsttypo: {
    color: "#FF7300",
    fontSize: "1.8rem",
    fontWeight: 500,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    textAlign: "center",
    // marginTop: "3rem",
    padding: "0 12rem -15rem 12rem",
    [theme.breakpoints.down(700)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },

  boxmidsecondtypo: {
    color: "#231F20",
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    fontStyle: "normal",
    textAlign: "center",
    margin: "0rem 10rem 2rem 10rem",
    [theme.breakpoints.down(2000)]: {
      margin: "0rem 2rem 2rem 2rem",
      textAlign: "justify",
      fontSize: "1rem",
    },
  },
  boxend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3rem",
    backgroundColor: "#231f20",
  },

  boxend1typo: {
    color: "#FFFFFF",
    fontSize: "1.2rem",
    fontWeight: "400",
    padding: "4rem 12rem 3rem 12rem",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },

  boxend2typo: {
    fontSize: "1.2rem",
    fontWeight: "400",
    padding: "0 12rem 5rem 12rem",
    color: "#359db6",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },
  btnbox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down(856)]: {
      flexDirection: "row",
      padding: 4,
    },
  },
  title: {
    fontFamily: "Open Sans",
    color: "#359DB6",
    marginTop: "6rem",
    fontWeight: "800",
    textAlign: "center",
    fontSize: "2.5rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "3rem",
    },
  },
  dot: {
    height: 13,
    width: 13,
    backgroundColor: "#FF7300",
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: 5,
    [theme.breakpoints.down(650)]: {
      height: 10,
      width: 10,
      marginLeft: 2,
    },
  },
}));

const Registration = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");
  const [step, setStep] = useState(1);

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const listenScrollEvent = (event) => {
    if (window.scrollY < 1) {
      setHeader("header");
    } else if (window.scrollY > 1) {
      setHeader("header2");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [header]);

  const handleClickSingUp = () => {
    const storedRef = getWithExpiry("ref") || null;

    if (storedRef) {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      window.open(signupLink, "_blank");
    } else {
      let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      window.open(signupLink, "_blank");
    }
  };

  return (
    <div className="classes.root">
      <ChangePageTitle pageTitle="Registration" />
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.grid_first}>
              <Grid item xs={12} sm={12}>
                {matches ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBoxmob}>REGISTRATION</Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <Grid item className={classes.regssmargin}>
                      <Box className={classes.regBox}>REGISTRATION</Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box style={{ marginTop: "6%", marginBottom: "6%" }}>
          <Grid item xs={12} sm={12}>
            {matches ? (
              <Grid container direction="column">
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        padding: " 0 4% 0% 4%",
                      }}
                    >
                      The Cogito Metaverse, backed by the Principality of
                      Cogito, offers a robust financial infrastructure. By
                      registering, you gain access to digital currencies, secure
                      transactions, and innovative financial services within the
                      metaverse. This allows for seamless in-world transactions,
                      virtual commerce, and real-world economic opportunities
                      tied to the metaverse's economy.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        padding: " 0 4% 0% 4%",
                      }}
                    >
                      The registration process includes complying with the KYC
                      and AML process. KYC stands for 'Know Your Customer' and
                      AML stands for 'Anti-Money Laundering'. These processes
                      are part of your registration to verify your
                      identification and provide security for both you and
                      Cogito. Your details are kept completely confidential
                      within the Principality of Cogito. We will not share these
                      with any entities, government of otherwise outside PoC.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      className={classes.title}
                      style={{
                        marginLeft: "1rem",
                        marginRight: "1rem",
                        fontSize: "1.5rem",
                      }}
                    >
                      Registering with the metaverse gives Citizens the ability
                      to:
                    </Typography>
                  </Box>
                  <Box>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/shadow.jpg`}
                      alt="shadow"
                      width={"100%"}
                      style={{ marginTop: "1rem" }}
                    />
                  </Box>
                  <Box direction="column" className={classes.pointtext}>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{
                          paddingRight: "2rem",
                          paddingLeft: matches ? "2.3rem" : "null",
                        }}
                      />

                      <Typography className={classes.mobileBox}>
                        Open their bank accounts
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{
                          paddingRight: "2rem",
                          paddingLeft: matches ? "2.3rem" : "null",
                        }}
                      />
                      <Typography className={classes.mobileBox}>
                        Buy goods and accept cog as currency
                        <br />
                        as currency
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{
                          paddingRight: "2rem",
                          paddingLeft: matches ? "2.3rem" : "null",
                        }}
                      />
                      <Typography className={classes.mobileBox}>
                        Register a company
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{
                          paddingRight: "2rem",
                          paddingLeft: matches ? "2.3rem" : "null",
                        }}
                      />
                      <Typography className={classes.mobileBox}>
                        Register a Trust
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{
                          paddingRight: "2rem",
                          paddingLeft: matches ? "2.3rem" : "null",
                        }}
                      />
                      <Typography className={classes.mobileBox}>
                        Transact with other citizens
                        <br />
                        within the Cogito Metaverse
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{
                          paddingRight: "2rem",
                          paddingLeft: matches ? "2.3rem" : "null",
                        }}
                      />
                      <Typography
                        className={classes.mobileBox}
                        style={{ paddingRight: matches ? "2rem" : "null" }}
                      >
                        Download the point of sale app
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{
                          paddingRight: "2rem",
                          paddingLeft: matches ? "2.3rem" : "null",
                        }}
                      />
                      <Typography
                        className={classes.mobileBox}
                        style={{ paddingRight: matches ? "2rem" : "null" }}
                      >
                        Earn a universal income
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{
                          paddingRight: "2rem",
                          paddingLeft: matches ? "2.3rem" : "null",
                        }}
                      />
                      <Typography
                        className={classes.mobileBox}
                        style={{ paddingRight: matches ? "2rem" : "null" }}
                      >
                        Trade in the stock exchange
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{
                          paddingRight: "2rem",
                          paddingLeft: matches ? "2.3rem" : "null",
                        }}
                      />
                      <Typography
                        className={classes.mobileBox}
                        style={{ paddingRight: matches ? "2rem" : "null" }}
                      >
                        Invest in the future
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      padding: "2rem 0 4rem 0",
                    }}
                  >
                    <ThemeButton
                      // background='#359DB6'
                      title="REGISTER NOW"
                      size={14}
                      height={40}
                      radius={16}
                      onClick={handleClickSingUp}
                    />
                  </Box>
                  <Box>
                    <Box>
                      <Typography className={classes.boxmidsecondtypo}>
                        This gives you the ability to begin to experience the
                        benefits of the Cogito Metaverse. As it gains momentum,
                        there will be additional benefits, however in the
                        meantime, it is possible to earn Cog and develop the
                        ability for efficient tax compliance. By registering a
                        trust you can gain security and privacy for your assets.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.boxmidsecondtypo}>
                        As the Cogito Metaverse grows, members will be able to
                        access the full benefits, including:
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.imgbox}>
                    <Box direction="column" className={classes.boximgtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/globe.png`}
                        alt="grow_investment"
                        height={80}
                        style={{ marginBottom: "2rem" }}
                      />
                      <Typography
                        style={{
                          marginBottom: "2rem",
                          fontFamily: "Open Sans",
                          textAlign: "center",
                        }}
                      >
                        Earn a universal income
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boximgtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/stock.png`}
                        alt="bag"
                        height={80}
                        style={{ marginBottom: "2rem" }}
                      />
                      <Typography
                        style={{
                          marginBottom: "2rem",
                          fontFamily: "Open Sans",
                          textAlign: "center",
                        }}
                      >
                        Trade in the stock exchange
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boximgtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/invest.png`}
                        alt="hands"
                        height={80}
                        style={{ marginBottom: "2rem" }}
                      />
                      <Typography
                        style={{
                          marginBottom: "2rem",
                          fontFamily: "Open Sans",
                          textAlign: "center",
                        }}
                      >
                        Invest in the future
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid container direction="column">
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        letterSpacing: 1,
                        padding: " 2rem 10rem 2rem 10rem",
                      }}
                    >
                      The Cogito Metaverse, backed by the Principality of
                      Cogito, offers a robust financial infrastructure. By
                      registering, you gain access to digital currencies, secure
                      transactions, and innovative financial services within the
                      metaverse. This allows for seamless in-world transactions,
                      virtual commerce, and real-world economic opportunities
                      tied to the metaverse's economy.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        letterSpacing: 1,
                        padding: " 2rem 10rem 2rem 10rem",
                      }}
                    >
                      The registration process includes complying with the KYC
                      and AML process. KYC stands for 'Know Your Customer' and
                      AML stands for 'Anti-Money Laundering'. These processes
                      are part of your registration to verify your
                      identification and provide security for both you and
                      Cogito. Your details are kept completely confidential
                      within the Principality of Cogito. We will not share these
                      with any entities, government of otherwise outside PoC.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      className={classes.title}
                      style={{ fontSize: "3rem" }}
                    >
                      Registering with the metaverse gives <br /> citizens the
                      ability to:
                    </Typography>
                  </Box>

                  <Box>
                    <img
                      src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/shadow.jpg`}
                      alt="shadow"
                      width={"100%"}
                      style={{ marginTop: "2rem" }}
                    />
                  </Box>

                  <Box direction="column" className={classes.pointtext}>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{
                          paddingRight: "2rem",
                        }}
                      />
                      <Typography className={classes.webBox}>
                        Open their bank accounts
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{ paddingRight: "2rem" }}
                      />
                      <Typography className={classes.webBox}>
                        Buy goods and accept cog as currency
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{ paddingRight: "2rem" }}
                      />
                      <Typography className={classes.webBox}>
                        Register a company
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{ paddingRight: "2rem" }}
                      />
                      <Typography className={classes.webBox}>
                        Register a Trust
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{ paddingRight: "2rem" }}
                      />
                      <Typography className={classes.webBox}>
                        Transact with other citizens within the Cogito Metaverse
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{ paddingRight: "2rem" }}
                      />
                      <Typography className={classes.webBox}>
                        Download the point of sale app
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{ paddingRight: "2rem" }}
                      />
                      <Typography className={classes.webBox}>
                        Earn a universal income
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{ paddingRight: "2rem" }}
                      />
                      <Typography className={classes.webBox}>
                        Trade in the stock exchange
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boxpointtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/tick.png`}
                        alt="tick"
                        height={35}
                        style={{ paddingRight: "2rem" }}
                      />
                      <Typography className={classes.webBox}>
                        Invest in the future
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      padding: "3rem 0 5rem 0",
                    }}
                  >
                    <ThemeButton
                      // background='#359DB6'
                      title="REGISTER NOW"
                      size={14}
                      width={200}
                      radius={16}
                      onClick={handleClickSingUp}
                    />
                  </Box>

                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        letterSpacing: 1,
                        padding: " 2rem 10rem 2rem 10rem",
                      }}
                    >
                      In addition to the financial infrastructure, the metaverse
                      is the way of the future. The metaverse transcends
                      geographical boundaries, enabling individuals from
                      different parts of the world to collaborate and work
                      together in real time. Through virtual workspaces, shared
                      projects, and collaborative tools, you can connect with
                      professionals, entrepreneurs, and creative minds across
                      the globe. This facilitates global teamwork, knowledge
                      exchange, and the potential for groundbreaking
                      collaborations.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        letterSpacing: 1,
                        padding: " 2rem 10rem 2rem 10rem",
                      }}
                    >
                      One unique advantage of the Cogito Metaverse is the
                      ability to work in a space outside of traditional global
                      governments and restrictions. While still subject to the
                      rules and policies set by the Principality of Cogito, the
                      metaverse offers an environment that operates
                      independently from the regulatory frameworks of individual
                      countries. This can provide a certain level of autonomy,
                      flexibility, and innovative experimentation in various
                      aspects, including governance models, virtual economies,
                      and virtual societies.
                    </Typography>
                  </Box>
                  {/* <Box className={classes.imgbox}>
                    <Box direction="column" className={classes.boximgtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/globe.png`}
                        alt="grow_investment"
                        height={80}
                        style={{ marginBottom: "2rem" }}
                      />
                      <Typography
                        style={{
                          marginBottom: "2rem",
                          fontSize: "1.2rem",
                          fontFamily: "Open Sans",
                          textAlign: "center",
                        }}
                      >
                        Earn a universal income
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boximgtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/stock.png`}
                        alt="bag"
                        height={80}
                        style={{ marginBottom: "2rem" }}
                      />
                      <Typography
                        style={{
                          marginBottom: "2rem",
                          fontSize: "1.2rem",
                          fontFamily: "Open Sans",
                          textAlign: "center",
                        }}
                      >
                        Trade in the stock exchange
                      </Typography>
                    </Box>
                    <Box direction="column" className={classes.boximgtext}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/invest.png`}
                        alt="hands"
                        height={80}
                        style={{ marginBottom: "2rem" }}
                      />
                      <Typography
                        style={{
                          marginBottom: "2rem",
                          fontSize: "1.2rem",
                          fontFamily: "Open Sans",
                          textAlign: "center",
                        }}
                      >
                        Invest in the future
                      </Typography>
                    </Box>
                  </Box> */}
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            direction="column"
            style={{
              background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/contents/registration-poster.png)`,
              height: matches ? "40vh" : "80vh",
              backgroundSize: "100%",
              // backgroundPosition: "",
              backgroundRepeat: "initial",
              marginBottom: matches ? "" : "5%",
            }}
          />
          {/* <Grid
            container
            style={{
              width: "99vw",
              height: matches ? 720 : 650,
              backgroundColor: "black",
            }}
          >
            <Grid
              item
              container
              xs={12}
              sm={6}
              style={{ textAlign: matches ? "center" : "left" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  marginTop: matches ? "5%" : "auto",
                  marginBottom: "auto",
                  marginLeft: matches ? "" : "20%",
                }}
              >
                <Container>
                  <Typography
                    style={{
                      fontFamily: "Open Sans",
                      color: "white",
                      fontSize: matches ? "2rem" : "2.5rem",
                    }}
                  >
                    {step === 1
                      ? "Step 1"
                      : step === 2
                      ? "Step 2"
                      : step === 3
                      ? "Step 3"
                      : step === 4
                      ? "Step 4"
                      : null}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Open Sans",
                      color: "#359DB6",
                      fontSize: matches ? "2.1rem" : "3.5rem",
                      fontWeight: matches ? "600" : "950",
                    }}
                  >
                    {step === 1
                      ? "Verify Email"
                      : step === 2
                      ? "Create a profile"
                      : step === 3
                      ? "Agree to terms"
                      : step === 4
                      ? "Username & Password"
                      : null}
                  </Typography>
                  {step === 4 ? null : (
                    <Button
                      style={{
                        backgroundColor: "#FF7300",
                        color: "white",
                        width: "40%",
                        marginTop: matches ? "6%" : "15%",
                        fontWeight: matches ? "" : "1000",
                        fontSize: matches ? "" : "1.78rem",
                        borderRadius: matches ? "" : "1rem",
                      }}
                      onClick={() => setStep(step + 1)}
                    >
                      NEXT STEP
                    </Button>
                  )}
                  <br />
                  {step > 1 ? (
                    <Button
                      style={{
                        backgroundColor: "transperant",
                        color: "#FF7300",
                        width: "30%",
                        marginTop: "2%",
                      }}
                      onClick={() => setStep(step - 1)}
                    >
                      {"<<< BACK"}
                    </Button>
                  ) : null}
                </Container>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              style={{
                textAlign: "center",
                alignItems: "center",
                marginTop: "6%",
                marginBottom: "auto",
              }}
            >
              <Grid item xs={12}>
                <img
                  width={matches ? "52%" : "35%"}
                  alt="step"
                  src={
                    step === 1
                      ? registrationStep1
                      : step === 2
                      ? registrationStep2
                      : step === 3
                      ? registrationStep3
                      : step === 4
                      ? registrationStep4
                      : null
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingTop: matches ? "2px" : "22px" }}
              >
                <div
                  className={classes.dot}
                  style={{ opacity: step !== 1 ? 0.5 : null }}
                ></div>
                <div
                  className={classes.dot}
                  style={{ opacity: step !== 2 ? 0.5 : null }}
                ></div>
                <div
                  className={classes.dot}
                  style={{ opacity: step !== 3 ? 0.5 : null }}
                ></div>
                <div
                  className={classes.dot}
                  style={{ opacity: step !== 4 ? 0.5 : null }}
                ></div>
              </Grid>
            </Grid>
          </Grid> */}

          {/* <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              {matches ? (
                <Box className={classes.boxmid}>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      The registration process includes complying with KYC and
                      AML process. KYC stands for 'Know Your Customer' and AML
                      stands for 'Anti-Money Laundering'. It is the processes
                      are part of your registration to verify your
                      identification and provides security for both you and
                      Cogito. Your details are kept completely confidential.
                    </Typography>

                    <Typography className={classes.boxmidsecondtypo}>
                      The registration process includes complying with KYC and
                      AML process. KYC stands for 'Know Your Customer' and AML
                      stands for 'Anti-Money Laundering'. It is the processes
                      are part of your registration to verify your
                      identification and provides security for both you and
                      Cogito. Your details are kept completely confidential.See
                      our privacy statement{" "}
                    </Typography>
                  </Container>
                </Box>
              ) : (
                <Box className={classes.boxmid}>
                  <Container>
                    <Typography className={classes.boxmidsecondtypo}>
                      The registration process includes complying with KYC and
                      AML process. KYC stands for 'Know Your Customer' and AML
                      stands for 'Anti-Money Laundering'. It is the processes
                      are part of your registration to verify your
                      identification and provides security for both you and
                      Cogito. Your details are kept completely confidential.
                    </Typography>

                    <Typography className={classes.boxmidsecondtypo}>
                      The registration process includes complying with KYC and
                      AML process. KYC stands for 'Know Your Customer' and AML
                      stands for 'Anti-Money Laundering'. It is the processes
                      are part of your registration to verify your
                      identification and provides security for both you and
                      Cogito. Your details are kept completely confidential.See
                      our privacy statement{" "}
                    </Typography>
                  </Container>
                </Box>
              )}
              <Box className={classes.btnbox}>
                <Box style={{}}>
                  <ThemeButton
                    // background='#359DB6'
                    title="  REGISTER"
                    size={14}
                    width={140}
                    margin={matches ? "0rem 1rem 1rem 0" : "0 2rem 2rem 0"}
                    radius={16}
                    onClick={handleClickSingUp}
                  />
                </Box>
                <Box style={{}}>
                  <Link href="/about" style={{ textDecoration: "none" }}>
                    <ThemeButton
                      // background='#359DB6'
                      title="LEARN MORE"
                      size={14}
                      width={140}
                      margin={matches ? "1rem 0 2rem 0rem" : "0 2rem 2rem 0"}
                      radius={16}
                    />
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid> */}
          <Grid item xs={12} sm={12}>
            {matches ? (
              <Grid container direction="column">
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        padding: " 0 4% 0% 4%",
                      }}
                    >
                      The Cogito Metaverse places a strong emphasis on user
                      privacy and security. As a registered user, you can enjoy
                      advanced privacy features, secure data storage, and
                      encrypted communications within the metaverse. This
                      ensures that your personal information and virtual
                      activities are protected, fostering a safe and trusted
                      environment for users to engage and interact.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        padding: " 0 4% 0% 4%",
                      }}
                    >
                      The metaverse promotes remote work and flexible working
                      arrangements. By registering with the Cogito Metaverse,
                      you can potentially explore remote job opportunities,
                      participate in virtual workspaces, and collaborate with
                      colleagues from anywhere in the world. This flexibility
                      allows for a better work-life balance, eliminates
                      geographical constraints, and opens up new possibilities
                      for global career opportunities.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        padding: " 0 4% 0% 4%",
                      }}
                    >
                      The Cogito Metaverse serves as a hub for innovation and
                      technological advancements. By registering, you become
                      part of an ecosystem that encourages experimentation,
                      research, and the development of cutting-edge
                      technologies. This environment fosters creativity,
                      entrepreneurialism, and the potential to shape the future
                      of the metaverse and its associated technologies.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        padding: " 0 4% 0% 4%",
                      }}
                    >
                      It's important to note that while the advantages mentioned
                      highlight the potential benefits of the Cogito Metaverse,
                      the actual implementation, features, and policies may be
                      subject to change based on the evolving nature of the
                      platform and the Principality of Cogito's decisions.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid container direction="column">
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        letterSpacing: 1,
                        padding: " 2rem 10rem 2rem 10rem",
                      }}
                    >
                      The Cogito Metaverse places a strong emphasis on user
                      privacy and security. As a registered user, you can enjoy
                      advanced privacy features, secure data storage, and
                      encrypted communications within the metaverse. This
                      ensures that your personal information and virtual
                      activities are protected, fostering a safe and trusted
                      environment for users to engage and interact.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        letterSpacing: 1,
                        padding: " 2rem 10rem 2rem 10rem",
                      }}
                    >
                      The metaverse promotes remote work and flexible working
                      arrangements. By registering with the Cogito Metaverse,
                      you can potentially explore remote job opportunities,
                      participate in virtual workspaces, and collaborate with
                      colleagues from anywhere in the world. This flexibility
                      allows for a better work-life balance, eliminates
                      geographical constraints, and opens up new possibilities
                      for global career opportunities.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        letterSpacing: 1,
                        padding: " 2rem 10rem 2rem 10rem",
                      }}
                    >
                      The Cogito Metaverse serves as a hub for innovation and
                      technological advancements. By registering, you become
                      part of an ecosystem that encourages experimentation,
                      research, and the development of cutting-edge
                      technologies. This environment fosters creativity,
                      entrepreneurialism, and the potential to shape the future
                      of the metaverse and its associated technologies.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Open Sans",
                        fontWeight: "lighter",
                        fontStyle: "normal",
                        textAlign: "center",
                        letterSpacing: 1,
                        padding: " 2rem 10rem 2rem 10rem",
                      }}
                    >
                      It's important to note that while the advantages mentioned
                      highlight the potential benefits of the Cogito Metaverse,
                      the actual implementation, features, and policies may be
                      subject to change based on the evolving nature of the
                      platform and the Principality of Cogito's decisions.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default Registration;
