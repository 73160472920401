import React from "react";
import "./styles.css";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { dateToWords } from "../../utility";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  bodyText: {
    lineHeight: "24px",
    height: "calc(3*24px)",
    overflow: "hidden",
    position: "relative",
    fontFamily: "Open Sans",
    fontWeight: 100,
    color: "#231F20",
    marginBottom: "1rem",
    "&::after": {
      content: `""`,
      position: "absolute",
      bottom: "0",
      right: "0",
      height: "21px",
      width: "75%",
    },
  },
  BlogCard: {
    boxShadow: "0px 4.23895px 12.7169px 4.23895px rgba(0, 0, 0, 0.25)",
    borderRadius: "38.1506px",
    margin: "4%",
    height: "50vh",
    [theme.breakpoints.down("xs")]: {
      height: "48vh",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "80%",
    },
  },
}));

const BlogList = ({ blogs, location }) => {
  const classes = useStyles();
  let blogsList =
    location === "Insights" ? blogs.slice(1, blogs.length) : blogs;
  return (
    <Grid item container spacing={4} style={{}}>
      {blogsList?.map((blog, i) =>
        i != 0 ? (
          <Grid
            item
            key={i}
            xs={12}
            md={3}
            sm={3}
            className={classes.BlogCard}
            style={{}}
          >
            <img
              className="blogItem-cover"
              src={`${
                process.env.REACT_APP_ENV === "prod"
                  ? process.env.REACT_APP_SPACE_KEY_PROD
                  : process.env.REACT_APP_SPACE_KEY
              }/${blog?.pic}`}
              alt="cover"
              width="100%"
            />
            <div style={{ padding: "1rem" }}>
              <h3
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                  color: "#231F20",
                  lineHeight: "24px",
                  overflow: "clip",
                  height: "calc(3*24px)",
                }}
              >
                {blog?.title}
              </h3>
              <p
                className={classes.bodyText}
                dangerouslySetInnerHTML={{ __html: blog?.body }}
              ></p>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <Link
                    className="blogItem-link"
                    id={blog?.id}
                    to={`/insight/${blog?.id}`}
                    style={{ cursor: "pointer" }}
                  >
                    Read More
                  </Link>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <div className="blogItem-author">
                    <p>{dateToWords(blog?.created_at)}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        ) : (
          <Grid
            item
            key={i}
            xs={12}
            md={3}
            sm={3}
            className={classes.BlogCard}
            style={{}}
          >
            <img
              className="blogItem-cover"
              src={`${
                process.env.REACT_APP_ENV === "prod"
                  ? process.env.REACT_APP_SPACE_KEY_PROD
                  : process.env.REACT_APP_SPACE_KEY
              }/${blog?.pic}`}
              alt="cover"
              width="100%"
            />
            <div style={{ padding: "1rem" }}>
              <h3
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                  color: "#231F20",
                  lineHeight: "24px",
                  overflow: "clip",
                  height: "calc(3*24px)",
                }}
              >
                {blog?.title}
              </h3>
              <p
                className={classes.bodyText}
                dangerouslySetInnerHTML={{ __html: blog?.body }}
              ></p>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <Link
                    className="blogItem-link"
                    id={blog?.id}
                    to={`/insight/${blog?.id}`}
                    style={{ cursor: "pointer" }}
                  >
                    Read More
                  </Link>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <div className="blogItem-author">
                    <p>{dateToWords(blog?.created_at)}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        ),
      )}
    </Grid>
  );
};

export default BlogList;
