import React from "react";
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      letterSpacing: 7,
    },
  },
  headerbackLink: {
    color: "#359DB6",
    paddingBottom: "2%",
    fontFamily: "Open Sans",
  },
  content: { paddingTop: "6rem", paddingBottom: "6rem", textAlign: "center" },
  title: {
    fontFamily: "Open Sans",
    fontSize: "1.5rem",
    color: "#359DB6",
    fontWeight: "bold",
  },
  bodyContent: {
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    fontSize: "1.2rem",
    textAlign: "left",
    paddingTop: "3%",
    [theme.breakpoints.down(420)]: {
      textAlign: "justify",
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
  },
  orangeBox: {
    // borderWidth: 2,
    // borderColor: "#FF7300",
    borderRadius: "35px",
    marginTop: "3rem",
    border: "2px solid  #FF7300",
    // backgroundImage: "url(/assets/quote-image/quote.png)",
    // width: "40px",
    // height: "32px",
  },
  orangeBoxText: {
    fontFamily: "Open Sans",
    fontSize: "1.3rem",
    fontStyle: "italic",
    // color: "white",
    padding: "2rem",
    [theme.breakpoints.down(420)]: {
      fontSize: "1.1rem",
    },
  },
  container: {
    position: "relative",
    borderRadius: "35px",
    marginTop: "3rem",
    border: "2px solid  #FF7300",
    width: "88%",
    height: "150%",
  },

  label: {
    position: "absolute",
    top: "-62px",
    left: "53px",
    textAlign: "center",
  },
}));

const HowItWorks = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  return (
    <Grid container>
      <Helmet>
        <title>Universal-income</title>
        <meta
          name="description"
          content="Join the future of finance with universal income at Cogito Metaverse. Learn about financial services in the virtual reality and metaverse economy."
        />
        <link
          rel="canonical"
          href=" https://cogitometaverse.com/how-its-works "
        />
      </Helmet>
      <Grid item container className={classes.header}>
        <Grid item xs={12}>
          {matches ? (
            <Grid container direction="column" alignItems="center">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    How the URI works
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid item container className={classes.header}>
                <Grid item xs={12}>
                  <Typography className={classes.headerName}>
                    How the URI works
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/banking" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body1"
                      className={classes.headerbackLink}
                    >
                      Back to Banking Page
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Container className={classes.content}>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12}>
            <Typography className={classes.bodyContent}>
              The principal of the URI is to gather people together to create
              strong networks in a local area. By doing this, we believe that we
              can integrate everyday life with the digital infrastructure to
              enrich the lives of people around the globe.
            </Typography>
            <Typography className={classes.bodyContent}>
              The <span style={{ fontWeight: "normal" }}>first</span> level
              involves gathering your key local people together. Ideally you
              want a group of between six and nine individuals who are like
              minded and want to work together to develop a passive income. In
              <span style={{ fontWeight: "normal" }}> stage one </span> you need
              to agree on a strategy, time frames and roles within the team and
              onboard all members through the registration process. There are
              also 10 learning modules to complete which can be done as
              individuals or as a group. Once there are six people who have
              completed orientation, you can stage up to stage 2.
            </Typography>
            <Typography className={classes.bodyContent}>
              <span style={{ fontWeight: "normal" }}> Stage 2 </span>is where
              the team engages in business participation - gathering a team of
              business professionals who want to be able to refer business to
              each other and support each other.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        direction="column"
        style={{
          background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/contents/uri-works-poster.png)`,
          height: matches ? "40vh" : "80vh",
          backgroundSize: "100%",
          // backgroundPosition: "",
          backgroundRepeat: "initial",
        }}
      ></Grid>
      <Container className={classes.content}>
        <Grid justifyContent="center">
          <Grid item xs={12}>
            <Typography className={classes.bodyContent}>
              What we found from the small test of the market here was it is
              hard to get small to medium businesses on board if there is
              nowhere to spend their Cog. Developing a network of businesses to
              accept Cog will strengthen the ability of the group to grow and
              move to the next stage. <br />
              Once a few businesses are on board others will be a lot easier to
              follow. Business signups will be in different phases, depending on
              what your BEHAG is. If for example, the team is focusing on the
              pocket money app, the first phase will be businesses who provide
              entertainment and things that kids will be keen on.
            </Typography>
            <Typography className={classes.bodyContent}>
              The <span style={{ fontWeight: "normal" }}>second phase</span>{" "}
              will be businesses who support these first businesses, these
              businesses will be happy to follow. In a similar way to stage 1,
              there are 10 learning modules to be completed and some tasks. As
              you complete the stages, your URI will increase. Cogito will
              provide tools to assist in building the business network
            </Typography>
            <Typography className={classes.bodyContent}>
              <span style={{ fontWeight: "normal" }}>Stage three </span>of the
              process focuses on engaging with families by utilising the pocket
              money app. In this stage, your team sets a goal to have 35
              families trial the app (5 families per team player). During this
              stage, the team builds a business cohort, establishes a support
              network, advertises for families, conducts meetings to explain the
              concept, onboards the participating families, and finally runs the
              pocket money pilot. This stage is crucial as it enables the team
              to involve and interact with families, gather valuable feedback,
              and test the effectiveness of the pocket money app in real-world
              scenarios, ultimately contributing to its development and success
            </Typography>
            <Typography className={classes.bodyContent}>
              <span style={{ fontWeight: "normal" }}>Level 4</span>
              <br />
              <br />
              Emulation of level 1 in a new city
              <br />
              <br />
              <span style={{ fontWeight: "normal" }}>Level 5</span>
              <br />
              <br />
              Emulation of level 4, in a new country
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default HowItWorks;
