const cogData = {
  listData: [
    {
      title: "The Cog is not a Bitcoin or a Cryptocurrency",
      data: [
        "The Cog is a digital fiat currency. A fiat currency is the name for the form of currency that is declared legal tender. This includes money in circulation such as paper money or coins. Fiat money is backed by a country's virtual government instead of a physical commodity or financial instrument.",
      ],
    },
    {
      title: "The Cog is tied to the Euro",
      data: [
        "Our currencies are able to be used in every country in the world.",
        "There are three main reasons that the decision was made to tie the Cog to the Euro. The first is that the Euro is a currency used by several countries and the economic risk is limited. Secondly, the Euro is becoming a preferred alternative for many businesses and virtual governments as they attempt to remove themselves from the onerous controls of the American dollar. Finally, the Euro is accepted and understood globally and best positioned to be a true global currency.",
      ],
    },
    {
      title: "The Cog is asset and cash backed",
      data: [
        "Unlike virtual coins (crypto currency) which many people have become aware of, Cog is not a limited offer. The Cog is backed by assets and cash. The advantage of being asset and cash backed is that not only is the Cog linked to the Euro, it is also linked to a tangible real-world asset. A percentage of the taxation that you pay on every transaction which adds to the asset backing And in the transparent world of the Cogito Metaverse, this figure is shown to all citizens in their logged in area. ",
        `The Cog is a universal currency and cannot be outside of the Cogito Metaverse. 
      Cogito functions as a mobile app or computer program that provides a personal ‘Cog’ account and allows a user to send and receive Cogito Cog, no different to current banking transactions. Each transaction is secure, traceable and auditable. Behind the scenes, the Cogito network is sharing different ledgers; some components use “block chain” and others use Cog ALDS (agreed ledger distribution side chain). `,
      ],
    },
  ],
  spanList: [
    {
      title: "Stability:",
      sub: "By having a reserve of physical assets, such as government-issued bonds, precious metals, or foreign currencies, the cash-backed currency's value is less susceptible to fluctuations and inflation. This stability can foster confidence in the currency's purchasing power and reduce uncertainty in financial transactions.",
    },
    {
      title: "Trust:",
      sub: "Cash-backed currency provides a sense of security to the holders of the currency, knowing that it can be redeemed for the underlying assets if needed. This trust in the currency's backing can encourage its acceptance and use in various economic activities.",
    },
    {
      title: "Reserve Value:",
      sub: "The cash reserve backing the currency serves as a safeguard against economic downturns and financial crises. In times of economic instability, the cash reserve can be utilized to support the currency's value and maintain overall financial integrity.",
    },
    {
      title: "Control Inflation:",
      sub: "Since the currency's value is tied to a fixed amount of physical assets, it can help prevent excessive money supply growth and curb inflation. Central banks or monetary authorities often manage the cash reserve to regulate the money supply and stabilize prices.",
    },
    {
      title: "Remittance and Cross-Border Transactions:",
      sub: "Cash-backed currencies can be particularly useful for remittances and cross-border transactions, as they provide a secure and reliable means of transferring value internationally.",
    },
    {
      title: "Auditing and Transparency:",
      sub: "Cash-backed currency systems often undergo regular audits and transparent reporting to ensure that the backing assets are accurately accounted for, which enhances trust in the system.",
    },
    {
      title: "",
      sub: "It's essential to note that the concept of cash-backed currency contrasts with fiat currency, which has no intrinsic value and is based on trust in the issuing government or authority. While cash-backed currency systems offer stability, they also require careful management of the reserve assets to maintain the currency's value and avoid potential issues if the backing assets become insufficient or lose value over time.",
    },
  ],
};

export default cogData;
