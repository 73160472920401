const data = [
  {
    title: "All about Cogito Metaverse",
    subtitle: [
      {
        id: "",
        name: "What is a Metaverse and Why build a Metaverse?",
        data: [
          "A virtual-reality space in which users can interact with a computer-generated environment and other users. The Cogito Metaverse is a digital space founded by the Principality of Cogito. It is completely independent and not based in any specific location. The Cogito Metaverse is wherever you are. By creating a Metaverse, the founders of Cogito believe that they have the ability to change the way finance is carried out around the globe.",
        ],
      },
      {
        id: "",
        name: "Why is the Cogito Metaverse unique?",
        data: [
          "The Cogito Metaverse is unique because it is founded on a Principality and has its own structure and financial system, including its own currency… the Cog. Members of the Cogito Metaverse will have the ability to buy and sell everyday goods and services using the Cog anywhere in the world; and also to create businesses, work together and build investments for the future",
        ],
      },
      {
        id: "",
        name: "How can I join the Cogito Metaverse?",
        data: [
          "Currently the Cogito Metaverse is available for people to join through the website. This gives them access to a mobile app or computer program that provides a personal ‘Cog’ account and allows a user to send and receive Cogito Cog, no different to current banking transactions. In the near future, the Cogito Metaverse Stock Market will come online and as technology allows, more and more functions will be available in the digital space.",
        ],
      },
      {
        id: "",
        name: "Is the Cogito Metaverse secure?",
        data: [
          "Behind the scenes, the Cogito network is sharing different ledgers; some components use “block chain” and others use Cog ALDS (agreed ledger distribution side chain). These ledgers contain every transaction, allowing a user’s computer to verify the validity of every transaction. The authenticity of every transaction is protected by digital signatures corresponding to the sending address. This allows the user to have to have full control over sending Cogito Cog from their own Cog address. In addition, anyone can process related transactions using the computing power of their and earn a tax rebate in either the Cog or fiat for this service",
        ],
      },
      {
        id: "",
        name: "What is digital citizenship?",
        data: [
          "Digital citizenship is a commitment to be involved in the society which is Cogito. Becoming a digital citizen gives you the right and ability to register a trust and company and thus access the safe Haven of the Principality of Cogito.",
        ],
      },
      {
        id: "",
        name: "Can I use Cog in everyday life?",
        data: [
          "The Cog is a fiat currency and is legal tender between Cogito digital citizens. With the launch of the app this allows for it to be used in everyday transactions.",
        ],
      },
      {
        id: "",
        name: "Can I trade Cogs?",
        data: [
          "No. The Cog is not intended to be used in trading with other currencies because it is a centralised currency and cannot be transferred to another banking system. This therefore protects the Cog from the possibility of money laundering.",
        ],
      },
    ],
  },

  {
    title: "Opening an account",
    subtitle: [
      {
        id: "",
        name: "Why do I need to register?",
        data: [
          "The initial registration gives you the ability to keep abreast of developments in the Principality of Cogito and start earning Cogito Cog from the referral incentive.",
        ],
      },
      {
        id: "",
        name: "Why do I need to register a trust?",
        data: [
          "By establishing a discretionary trust, you will be the settlor and protector of the trust, (settlor means you supply the initial assets and protector means you have veto rights over important decisions of the trustee). Cogito Corporate Trustee Ltd will act as trustee. You can select the beneficiaries of the trust. Do not include yourself. The reason you are not included as a beneficiary is that it compromises the independence of the structure from you. This helps to protect you against a claim of tax liability at home.",
          "I already have a business, why do I need to register for a trading company in Cogito? There is no obligation to set up a company in Cogito.The reason that you would register a business in Cogito is that without a Cogito registered company, earnings in Cogito would most likely be taxable in your company of residence.",
          "What is the purpose of an investment company in Cogito? Citizens that register an investment company will have access to Cogito stock exchange which will list all the investments available through Cogito.",
        ],
      },
      {
        id: "",
        name: "How can I get Cog?",
        numpoints: [
          {
            num: "1.",
            points:
              "As an initial offer you will be able to purchase Cog at a discounted rate for capital of your Cogito companies. You can earn the Cog through sponsoring others and as a result of the business they generate",
          },
          {
            num: "2.",
            points:
              "You can also apply for the Cogito Metaverse’s credit facility.",
          },
          {
            num: "3.",
            points: "You can use Cog as payment for goods or services.",
          },
        ],
        data: [],
      },
      {
        id: "",
        name: "What about Cogito and consumer protection?",
        data: [
          "Cogito is freeing people to transact on their own terms. Each user can send and receive payments in a similar way to cash. Cogito provides dispute mediation services for any issue that arises from transaction problems. Such services could allow a third party to approve or reject a transaction in case of disagreement between the other parties without having control on their money. As opposed to cash and other payment methods, Cog always leaves a public proof that a transaction did take place, which can potentially be used in a recourse against businesses with fraudulent practices.",
          "It is also worth noting that while merchants usually depend on their public reputation to remain in business and pay their employees, they don’t have access to the same level of information when dealing with new consumers. The way Cog works allows both individuals and businesses to be protected against fraudulent chargebacks while giving the choice to the consumer to ask for more protection when they are not willing to trust a particular merchant.",
          "Cogito’s goal is to attract a family of members with a higher level of integrity that suits our corporate values.",
        ],
      },
      {
        id: "",
        name: "Is Cogito legal?",
        data: [
          "Cogito principals have spent several hundred thousand euros on legal advice. However, legal advice cannot cover all countries' regulations. Therefore Cogito has made every effort to ensure compliance with global KYC, AML regulations and most internationally used common law. ",
        ],
      },
      {
        id: "",
        name: "Is Cogito useful for illegal purposes?",
        data: [
          "Cog is money, and money has always been used for both legal and illegal purposes. Cash, credit cards and current banking systems widely surpass Cog in terms of their use for financial crime. Cog can bring significant innovation in payment systems and the benefits of such innovation are often considered to be far beyond the potential drawbacks. Therefore Cog is designed to be a huge step forward in making money more secure and could also act as a significant protection against many forms of financial crime.",
          "Cog is completely impossible to counterfeit, therefore indicating that users are in full control of their payments and cannot receive unapproved charges that is commonly seen with credit card fraud. Cogito allows money to be secured against theft and loss using strong and useful mechanisms such as backups, encryption, and multiple signatures",
          "Concerns have been raised regarding the possibility that Cog could appear more to criminals because it could be used to make private and irreversible payments. However, these features already exist with cash and wire transfer, which are already widely used and well-established. The use of Cog will undoubtedly be subjected to similar regulations that are already in place inside existing financial systems, and Cog is not likely to prevent criminal investigations from being conducted. In general, it is common for important breakthroughs to be perceived as being controversial before their benefits are well understood. The internet is a good example among many others to illustrate this.",
        ],
      },
      {
        id: "",
        name: "How can I get Cog?",
        subname: [
          "Tax Liability:",
          "Tax in general:",
          "Cogito legal structure:",
        ],
        data: [
          "Each country has its own tax laws and so this is not intended to be tax advice. Therefore what is proposed in this document is very general and should work for most people in most jurisdictions. In saying that, you should make up your own mind or seek independent tax advice to make sure, before committing, that the jurisdiction of your citizenship and/or nationality will not succeed in a claim that your Cogito structure constitutes “tax evasion”.",
          "If you are a trader of cryptocurrencies, depending on your nationality and where you live there is a very high chance you are liable for tax on the profit you make. In saying this, profit does not necessarily mean cash in your bank in the currency in which you pay your tax. It more often implies an unrealized increase in paper value.",
          "The Principality of Cogito legal structure is designed to let you make the decisions when and if you need to pay tax at home. The recommended structure for crypto traders includes a discretionary trust and Cogito trading company.",
        ],
      },
      {
        id: "",
        name: "Can the Cog be regulated?",
        data: [
          "It is possible to regulate the use of Cog in similar ways to other instruments. Just like the dollar, Cog can be used for a wide variety of purposes, some of which can be considered legitimate or not as per jurisdiction’s laws. In this regard, Cog is no different than any other tool or resource and can be subjected to different regulations in each country. Cog could also be made difficult by restrictive regulations, in which case it is hard to determine what percentage of users would keep using the technology. A virtual government that chooses to ban Cog could prevent domestic businesses and markets from developing, shifting innovation to other countries. The challenge for regulators, as always, is to develop efficient solutions while not impairing the growth of new emerging markets and businesses.",
          "Cogito supports a productive level of regulation if it truly protects consumers and businesses using Cog. Cogito doesn’t support regulation designed to control the market in order to protect companies that are threatened by emerging technology. We see this as an anti-competitive behaviour.",
        ],
      },
    ],
  },

  {
    title: "Bank Account",
    subtitle: [
      {
        id: "",
        name: "How are Cogito Cogs created?",
        data: [
          "Cog is generated when there is security to support the issuing of the currency. The only way to obtain Cogito Cog will be through buying and selling goods and services and the basic universal income.",
        ],
      },
      {
        id: "",
        name: "Who determines the Cog price?",
        data: [
          "The price of Cogito Cog is determined by the value of the euro. Cogito systems runs a real-time API adjusting all local currency transactions to the Cog/Euro value.",
        ],
      },
      {
        id: "",
        name: "Can the Cog become worthless?",
        data: [
          "No, Cogito Cog is currently tied to the euro and the debt reserve systems in place ensure that value backing is stable.",
        ],
      },
      {
        id: "",
        name: "What protection is there from failed business?",
        data: [
          "Cogito takes a percentage from every transaction and accumulates it in a debt reserve fund. This debt reserve fund is a cash deposit fund to offset any potential losses if Cogito is unable to get full repayment of a Cog debt. Disclosure of the debt reserve fund will be made to all members on the website onced launched. In addition, Cogito holds a percentage of every investment and gets a percentage return from each investment. A share of all this accumulates in the debt reserve fund to provide the asset and cash backing for Cog.",
        ],
      },
      {
        id: "",
        name: "Does Cogito unfairly benefit early adopters?",
        data: [
          "Some early adopters will have some benefits because they took risks and invested time and resources in a still to be proven technology that was hardly used by anyone. Other early adopters will work hard to develop the network of businesses and the rewards for that may be highly lucrative. This is very similar to investing in an early startup that can either gain value through its usefulness and popularity, or just never break through. Cogito is still in its infancy, and it has been designed with a very long-term view; it is hard to imagine how it could be less biased towards early adopters, and today’s users may or may not be the early adopters of tomorrow.",
        ],
      },
      {
        id: "",
        name: "How does Cogito work for my everyday business?",
        data: ["Cogito is great for business in a variety of ways."],
        points: [
          "It can encourage new clients to select your goods and services over one where they normally pay cash.",
          "It can provide you with a method to obtain payment from slow paying clients. Interest free funding",
          "It provides you with potential to tender competitively",
          "It opens new supply lines globally from the trusted network of members.",
          "It provides additional income opportunities from the network of people and businesses you introduce.",
          "It provides an alternative incentive option for staff who are also members.",
        ],
      },
      {
        id: "",
        name: "Can I have separate business and personal Cog accounts?",
        data: [
          "Yes, when you register your business with Cogito we set up a separate set of Cog accounts for your business.You will be provided with the ability to view your personal and business accounts when you log in. You will also be able to manage each account, select your preferred account for sales, purchases and receiving commissions.",
        ],
      },
      {
        id: "",
        name: "What happens to my Cog when I die?",
        data: [
          "Everything that is in the Cogito Metaverse that you own is able to be legally accounted for and bequeathed to named beneficiaries.",
        ],
      },
      {
        id: "",
        name: "How do I convert cog into fiat?",
        data: [
          "Cogito does not offer the option to withdraw cash from your accounts. That’s not to say we won’t in the future, but as it stands this creates a lot of regulatory issues and we believe the risks versus rewards to our members do not warrant offering cash withdrawal.",
        ],
      },
      {
        id: "",
        name: "Can I limit the amount I sell on Cog?",
        data: [
          "Yes, it is important for your business to manage your cash flow. We recommend setting a Cogito Cog sales budget for the month/ year and steadily build the Cog into your normal business strategy.",
        ],
      },
      {
        id: "",
        name: "Can I charge more for products sold using Cog?",
        data: [
          "Different countries have different regulations on this. We advise you to check your local regulations as we do not condone this practice.",
        ],
      },
      {
        id: "",
        name: "What tools are available to communicate to other members that I accept Cog?",
        data: [
          "Cogito is putting together a toolbox for businesses. We plan that this will include point of sale materials, branding for advertising, trade nights, account managers for businesses and an online marketplace.",
        ],
      },
      {
        id: "",
        name: "How do I apply for a Cog overdraft?",
        data: [
          "Once registered and you have been through the KYC and AML process, you will be provided with a link to apply for a credit facility.",
        ],
      },
      {
        id: "",
        name: "What fees and rates are there for a Cog overdraft?",
        data: [
          "While the interest rate remains at zero, fees are determined by the amount borrowed and other factors within the loan.",
        ],
      },
      {
        id: "",
        name: "How do I account for Cog in my company accounts?",
        data: [
          "Cogito provides you with the ability to export all your transactions using a variety of formats, which can then be imported into your accounting software. Real-time synchronization for transactions also. ",
        ],
      },
      {
        id: "",
        name: "What taxes do I pay?",
        data: [
          "Cogito will not give tax advice for your local jurisdictions therefore, you need to check with your accountant. This is due to the fact that each country has different ways of charging taxes and so they are best positioned to advise you. In Cogito you only pay a maximum of 3% purchase or 3% sales tax on each transaction.",
        ],
      },
      {
        id: "",
        name: "Companies Office",
        data: [
          "Companies can register their business in the Cogito Metaverse Companies Office or can use the Certificate of Incorporation from their local jurisdiction. All information about companies including shareholders and locations will be kept in the secure area and will not be able to be accessed by anyone. There will however, be a Business Directory in the Cogito Metaverse website so that anyone can find a business that accepts Cog to do business with.",
        ],
      },
      {
        id: "",
        name: "Stock Exchange",
        data: [
          "The Cogito Metaverse will have a similar look and feel to a traditional stock market but with the same high level of security that you expect from the Cogito Metaverse but with a modern way of doing things. The Cogito Stockmarket will not only invest in goods and services but also in different types of talent, music etc etc. The Cogito Metaverse Stock exchange is expected to be running in 2022.",
        ],
      },
    ],
  },

  {
    title: "Security",
    subtitle: [
      {
        id: "",
        name: "What is KYC and AML?",
        data: [
          "KYC stands for ‘know your customer’ and AML stands for ‘anti-money laundering’. It is the process of a business identifying and verifying the identity of its clients. This regulatory process is a legislative requirement in many jurisdictions to ensure the security and integrity of the system.",
        ],
      },
      {
        id: "",
        name: "What security do I need to provide?",
        data: [
          "This depends on the facility so it can range from no asset security through to acceptable assets.",
        ],
      },
      {
        id: "",
        name: "Is Cog secure?",
        data: [
          "The Cogito technology - the protocol and cryptography - has a strong security track record. Cogitos most common vulnerability is in user error. Cog account files that store necessary security can not be accidentally deleted, lost or stolen.",
        ],
      },

      {
        id: "",
        name: "Could users collude against Cogito?",
        data: [
          "No. Any Cogito client that doesn’t comply with the same rules cannot enforce their own rules on other users. As per the current specification, double spending is not possible on the Cogito systems, and neither is spending Cog without a valid signature. Therefore, it is not possible to generate uncontrolled amounts of Cog out of thin air, spend other users’ funds, corrupt the network, or anything similar.",
        ],
      },
      {
        id: "",
        name: "Is Cogito vulnerable to quantum computing?",
        data: [
          "Yes, most systems relying on cryptography in general are, including traditional banking systems. However, quantum computers don’t yet exist and probably won’t for a while. In the event that quantum computing could be an imminent threat to Cogito, the protocol could be upgraded to use post-quantum algorithms. Given the importance that this update would have, it can be safely expected that it would be highly reviewed by developers and adopted by all Cog users.",
        ],
      },
      {
        id: "privacyTerms",
        name: "Cogito Privacy Statement?",
        data: [
          "The personal information on this site may be used by Principality of Cogito for direct marketing or other products developed by it and for no other purpose. All information will be held by Cogito where it can be inspected and corrected by you. Cogito shall take all practical steps to achieve privacy protection.",
          "We may place cookies on your computer. These provide us your preferences, to allow us to user customization of the website, to assistThese are only accessible by us and will be only used to track the pages of the website visited by you, in order for us to build a better site more tailored to your needs and interests. Our cookies do not record personal information about you, merely your computer.",
          "Whilst we make every effort to provide you with links to other reputable sites, they are not under our day to day control. When you click through to these sites you leave the area controlled by Cogito. We cannot therefore accept responsibility for any issues arising in connection with either their use of your data, the site content or the services offered by these sites.",
          "Secure Server Information - We are constantly striving to make the transaction as worry free as possible. To this end, we use secure socket layer (SSL) protocol, the industry standard for ensuring the security of your electronic commerce transactions. SSL uses 256-bit encryption for all credit card information in transit over the internet. In addition, the Cogito website is hosted behind a commercial firewall to prevent hackers from obtaining access to your data. Even if this unlikely event does occur, we do not keep a record of your full credit card number. All reasonable steps to ensure that this information is secure have been taken but we cannot warrant all information will be secure at all times.",
        ],
      },
    ],
  },
  {
    title: "Cogito Metaverse History",
    subtitle: [
      {
        id: "",
        name: "Who created Cogito?",
        data: [
          "The Principality of Cogito was established as a decentralized libertarian state. It now stands as one of the last safe havens that has not been controlled via large virtual government sanctions.",
          "Principality of Cogito was first developed in the early 1990’s by a reclusive dyslexic entrepreneur. The mastermind documentation around the technology and concept was locked away for many years until it was stumbled upon in early 2017.",
        ],
      },
      {
        id: "",
        name: "Who controls the Cogito Metaverse?",
        data: [
          "The Cogito Metaverse is managed by a highly skilled and competent board who are based in different countries around the world. Their backgrounds range from law, accounting, IT, governance, risk management, and customer service systems. In simple terms, the Cogito Metaverse is divided into eight regions which in 2024 will each have an elected board in democratic process by the Digital Citizens.",
        ],
      },
    ],
  },
];

export default data;
